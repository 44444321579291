import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  Cell,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  LineChart,
  Area,
  Line,
} from "recharts";
import { PieChart, Pie } from "recharts";
import axios from "axios";

const ChartDashboard = ({ sheetId, globalUserId,setSelectedLayoutOption }) => {
  const [dropdownColumns, setDropdownColumns] = useState([]);
  const [selectedBarColumn, setSelectedBarColumn] = useState(null);
  const [selectedPieColumn, setSelectedPieColumn] = useState(null);
  const [barChartData, setBarChartData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [selectedLineColumn, setSelectedLineColumn] = useState(null);
  const [selectedAreaColumn, setSelectedAreaColumn] = useState(null);
  const [lineChartData, setLineChartData] = useState([]);
  const [areaChartData, setAreaChartData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch dropdown columns
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_ROOT}/hotwash/kanban-columns-group/${sheetId}/`)
      .then((response) => {
        const dropdowns = response.data.data;
        setDropdownColumns(dropdowns);

        // Optionally set default selections for both dropdowns
        if (dropdowns.length > 0) {
          setSelectedBarColumn(dropdowns[0]);
          setSelectedPieColumn(dropdowns[0]);
          setSelectedLineColumn(dropdowns[0])
          setSelectedAreaColumn(dropdowns[0])
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching dropdown columns:", error);
        setLoading(false);
      });
  }, [sheetId]);

  // Fetch Bar Chart data
  useEffect(() => {
    if (!selectedBarColumn) return;

    setLoading(true);
    const endpoint =
      selectedBarColumn.column_type === "dropdown"
        ? `${process.env.REACT_APP_API_ROOT}/hotwash/status-tasks/${selectedBarColumn.id}/`
        : selectedBarColumn.name === "Priority"
        ? `${process.env.REACT_APP_API_ROOT}/hotwash/status-tasks/${selectedBarColumn.id}/`
        : `${process.env.REACT_APP_API_ROOT}/hotwash/assignee-tasks/${selectedBarColumn.id}/`;

    axios
      .get(endpoint)
      .then((response) => {
        const data = response.data.data?.map((item) => ({
          name: item?.status_text || item.user,
          taskCount: item.tasks ? item.tasks.length : 0,
          color: item.status_color || "#8884d8",
        }));
        setBarChartData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Bar Chart data:", error);
        setLoading(false);
      });
  }, [selectedBarColumn]);

  // Fetch Pie Chart data
  useEffect(() => {
    if (!selectedPieColumn) return;

    setLoading(true);
    const endpoint =
      selectedPieColumn.column_type === "dropdown"
        ? `${process.env.REACT_APP_API_ROOT}/hotwash/status-tasks/${selectedPieColumn.id}/`
        : selectedPieColumn.name === "Priority"
        ? `${process.env.REACT_APP_API_ROOT}/hotwash/status-tasks/${selectedPieColumn.id}/`
        : `${process.env.REACT_APP_API_ROOT}/hotwash/assignee-tasks/${selectedPieColumn.id}/`;

    axios
      .get(endpoint)
      .then((response) => {
        const data = response.data.data?.map((item) => ({
          name: item?.status_text || item.user,
          value: item.tasks ? item.tasks.length : 0,
          color: item.status_color || "#8884d8",
        }));
        setPieChartData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Pie Chart data:", error);
        setLoading(false);
      });
  }, [selectedPieColumn]);
  useEffect(() => {
    if (!selectedLineColumn) return;

    setLoading(true);
    const endpoint =
    selectedLineColumn.column_type === "dropdown"
        ? `${process.env.REACT_APP_API_ROOT}/hotwash/status-tasks/${selectedLineColumn.id}/`
        : selectedLineColumn.name === "Priority"
        ? `${process.env.REACT_APP_API_ROOT}/hotwash/status-tasks/${selectedLineColumn.id}/`
        : `${process.env.REACT_APP_API_ROOT}/hotwash/assignee-tasks/${selectedLineColumn.id}/`;

    axios
      .get(endpoint)
      .then((response) => {
        const data = response.data.data?.map((item) => ({
          name: item?.status_text || item.user,
          taskCount: item.tasks ? item.tasks.length : 0,
          color: item.status_color || "#8884d8",
        }));
        setLineChartData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Bar Chart data:", error);
        setLoading(false);
      });
  }, [selectedLineColumn]);
  useEffect(() => {
    if (!selectedAreaColumn) return;

    setLoading(true);
    const endpoint =
    selectedAreaColumn.column_type === "dropdown"
        ? `${process.env.REACT_APP_API_ROOT}/hotwash/status-tasks/${selectedAreaColumn.id}/`
        : selectedAreaColumn.name === "Priority"
        ? `${process.env.REACT_APP_API_ROOT}/hotwash/status-tasks/${selectedAreaColumn.id}/`
        : `${process.env.REACT_APP_API_ROOT}/hotwash/assignee-tasks/${selectedAreaColumn.id}/`;

    axios
      .get(endpoint)
      .then((response) => {
        const data = response.data.data?.map((item) => ({
          name: item?.status_text || item.user,
          taskCount: item.tasks ? item.tasks.length : 0,
          color: item.status_color || "#8884d8",
        }));
        setAreaChartData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Bar Chart data:", error);
        setLoading(false);
      });
  }, [selectedAreaColumn]);
  const handleExtraTextClick = () => {
    // Function to switch layout to table
    setSelectedLayoutOption("table");
  };
  
  return (
    <div className="chart-dashboard">
      <div className="chart-controls mx-4">
        <h3 className="chart-title">Chart Dashboard</h3>

      

    
      </div>

      {loading ? (
        <div className="loader">
          <div className="spinner"></div>
        </div>
      ) : barChartData.length > 0 || pieChartData.length>0 || areaChartData.length>0 ||lineChartData.length>0 ?  (
        <div className="charts-container">
          <div className="chart-card">
          <div className="dropdown-container">
          <label className="chart-label">Bar Chart: </label>
          <select
            className="chart-dropdown mx-2"
            value={JSON.stringify(selectedBarColumn)}
            onChange={(e) => setSelectedBarColumn(JSON.parse(e.target.value))}
          >
            <option value="">Select</option>
            {dropdownColumns?.map((col) => (
              <option key={col.id} value={JSON.stringify(col)}>
                {col?.name}
              </option>
            ))}
          </select>
        </div>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={barChartData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="taskCount" fill="#8884d8">
                  {barChartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className="chart-card">
          <div className="dropdown-container">
          <label className="chart-label">Pie Chart: </label>
          <select
            className="chart-dropdown mx-2"
            value={JSON.stringify(selectedPieColumn)}
            onChange={(e) => setSelectedPieColumn(JSON.parse(e.target.value))}
          >
            <option value="">Select</option>
            {dropdownColumns?.map((col) => (
              <option key={col.id} value={JSON.stringify(col)}>
                {col?.name}
              </option>
            ))}
          </select>
        </div>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={pieChartData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#8884d8"
                  label
                >
                  {pieChartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="chart-card">
          <div className="dropdown-container">
          <label className="chart-label">Line Chart: </label>
          <select
            className="chart-dropdown mx-2"
            value={JSON.stringify(selectedLineColumn)}
            onChange={(e) => setSelectedLineColumn(JSON.parse(e.target.value))}
          >
            <option value="">Select</option>
            {dropdownColumns?.map((col) => (
              <option key={col.id} value={JSON.stringify(col)}>
                {col?.name}
              </option>
            ))}
          </select>
        </div>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={lineChartData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="taskCount"
                  stroke="#82ca9d"
                  animationDuration={1500}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>

          {/* Area Chart */}
          <div className="chart-card">
          <div className="dropdown-container">
          <label className="chart-label">Area Chart: </label>
          <select
            className="chart-dropdown mx-2"
            value={JSON.stringify(selectedAreaColumn)}
            onChange={(e) => setSelectedAreaColumn(JSON.parse(e.target.value))}
          >
            <option value="">Select</option>
            {dropdownColumns?.map((col) => (
              <option key={col.id} value={JSON.stringify(col)}>
                {col?.name}
              </option>
            ))}
          </select>
        </div>
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart
                data={areaChartData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="taskCount"
                  stroke="#ffc658"
                  fill="#ffc658"
                  animationDuration={1500}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      ):(
        <div className="no-data-container">
        <img
          alt="No Data"
          className="no-data-image"
          src="https://cdn.monday.com/images/overview/overview-sections/board-view/chart-overview-section-empty-state-icon.svg"
        />
        <p className="no-data-text">No results were found</p>
        <p className="extra-text">
  Your sheet must have dropdown or Assignee columns Data to generate Charts. 
  <span 
    className="clickable-text" 
    onClick={handleExtraTextClick}
  >
    Click to review.
  </span>
</p>

      </div>
      )}
    </div>
  );
};

export default ChartDashboard;
