import React, { useState } from "react";
import { Modal, Button, Tab, Nav } from "react-bootstrap";

const FeatureUpdateModal = ({ onClose }) => {
  const [show, setShow] = useState(true); 
  const [activeKey, setActiveKey] = useState("tab-one");

  const handleClose = () => {
    setShow(false); 
    if (onClose) onClose(); 
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="modal-xl"
      centered
      backdrop={true} 
      style={{
        width: "98vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        zIndex: 10000,
        backgroundColor: "#91989eb0",
      }}
    >
      <Modal.Header closeButton>
      <Modal.Title>Exciting New Features Just for You!</Modal.Title>      </Modal.Header>

      <Modal.Body>
        <Tab.Container activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
          <Tab.Content>
            <Tab.Pane eventKey="tab-one">
              <center>
                <h1 className="text-success" style={{ fontSize: "xx-large", textDecoration: "underline" }}>
                Workspace List - Stay Organized
                </h1>
                <img
                  src={`${process.env.REACT_APP_TOS_PATH}/assets/svg/new/Add-ss-2.gif`}
                  alt="Dashboard"
                  style={{ height: "500px", width: "65%" }}
                />
                <hr />
                <p
                  className="text-dark"
                  style={{ fontSize: "x-large", fontWeight: "inherit", fontFamily: "cursive" }}
                >
                  With the new workspace list, you can easily manage your projects and teams. Group your tasks,
                  assign team members, and track progress all in one place. Stay organized and focused on your goals.
                </p>
              </center>
              <div style={{ textAlign: "right" }}>
                <Button
                  className="btn btn-primary"
                  style={{ fontSize: "large", fontWeight: "bold" }}
                  onClick={() => setActiveKey("tab-two")}
                >
                  Next
                </Button>
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="tab-two">
              <center>
                <h1 className="text-success" style={{ fontSize: "xx-large", textDecoration: "underline" }}>
                Sheets - Streamlined Data Management
                </h1>
                <img
                                  src={`${process.env.REACT_APP_TOS_PATH}/assets/svg/new/scrol-sheet-1-2-c.gif`}

                  alt="Export"
                  style={{ height: "500px", width: "65%" }}
                />
                <hr />
                <p
                  className="text-dark"
                  style={{ fontSize: "x-large", fontWeight: "inherit", fontFamily: "cursive" }}
                >
                 Manage your data efficiently with the new sheet functionality. Easily input, track, and analyze data
                  in a flexible sheet format. You can quickly update and modify the information, making teamwork smoother.
               
                </p>
              </center>
              <div style={{ margin: "20px" }}>
                <Button
                  className="btn btn-primary"
                  style={{ fontSize: "large", fontWeight: "bold", float: "left" }}
                  onClick={() => setActiveKey("tab-one")}
                >
                  Previous
                </Button>
                {/* Conditionally render the "Next" button only if there are more tabs */}
                {activeKey !== "tab-two" && (
                  <Button
                    className="btn btn-primary"
                    style={{ fontSize: "large", fontWeight: "bold", float: "right" }}
                    onClick={() => setActiveKey("tab-three")}
                  >
                    Next
                  </Button>
                )}
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
};

export default FeatureUpdateModal;
