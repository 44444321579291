// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Workspace Page */
.workspace-details-page {
    background-color: #f9f9f9;
    padding: 40px 20px;
    min-height: 100vh;
    border-radius:20px;
    margin-left:20px;
  }
  
  .workspace-details-page {
    background-color: #f8fafc;
    min-height: 100vh;
    padding: 20px;
  }
  
  .table-container {
    background: white;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    color: #1f2937;
    font-size: 28px;
    margin-bottom: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/styles/WorkspaceDetails.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,iBAAiB;IACjB,aAAa;EACf;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,0CAA0C;EAC5C;;EAEA;IACE,cAAc;IACd,eAAe;IACf,mBAAmB;EACrB","sourcesContent":["/* Workspace Page */\n.workspace-details-page {\n    background-color: #f9f9f9;\n    padding: 40px 20px;\n    min-height: 100vh;\n    border-radius:20px;\n    margin-left:20px;\n  }\n  \n  .workspace-details-page {\n    background-color: #f8fafc;\n    min-height: 100vh;\n    padding: 20px;\n  }\n  \n  .table-container {\n    background: white;\n    border-radius: 8px;\n    padding: 15px;\n    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\n  }\n  \n  h1 {\n    color: #1f2937;\n    font-size: 28px;\n    margin-bottom: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
