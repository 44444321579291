import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Offcanvas, Button } from 'react-bootstrap';
import axios from 'axios';
import '../../assets/styles/Header.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaTags } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Header = ({ user }) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [activeTab, setActiveTab] = useState('All');
  const [notifications, setNotifications] = useState([]);
  const [hoveredNotification, setHoveredNotification] = useState(null);
  const userMenuRef = useRef(null);
  const[profileMenu,setProfileMenu]=useState(false)
  const navigate = useNavigate();

  const UserId=parseInt(user?.userid)
  // console.log("userhhhhhhh",user?.userid)
  // Handle hover function to update the state with the hovered notification's ID
  const handleHover = (notificationId) => {
    setHoveredNotification(notificationId);
  };
      /*eslint-disable*/
  useEffect(() => {
    fetchNotifications();
  }, [UserId]);
      /*eslint-enable*/
  console.log("profileMenu",profileMenu)
  const staticPath = `${process.env.REACT_APP_TOS_PATH}/includes/Pages/upload/`;
  const imageUrl = `${staticPath}${user?.file_name}`;

  const fetchNotifications = async () => {
    if (user?.userid) {
      console.log("Fetching notifications for user:", user.userid);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/notifications/${user.userid}`);
        setNotifications(response.data.notifications);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    }
  };
  
    /*eslint-disable*/
  useEffect(() => {
    fetchNotifications();
  }, []);
      /*eslint-enable*/
const handleProfile=()=>{
  setProfileMenu(true)
}
useEffect(() => {

  function handleClickOutside(event) {
    if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
     setProfileMenu(false)
        }
      
  }

  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, [userMenuRef]);
  const handleNotificationClick = () => {
    setShowDrawer(true);
  };

  const handleClose = () => {
    setShowDrawer(false);
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  const handleSignOut = (event) => {
    event.preventDefault(); 
    localStorage.removeItem('isFeatureUpdateShown'); 

    window.location.href = process.env.REACT_APP_LOGOUT_PATH;
  };
  
  const handleReadToggle = async (notification) => {
    try {
      const newStatus = !notification.is_read;
      await axios.patch(`${process.env.REACT_APP_API_ROOT}/hotwash/notification/${notification.id}/`, { is_read: newStatus });
      setNotifications((prevNotifications) =>
        prevNotifications.map((n) =>
          n.id === notification.id ? { ...n, is_read: newStatus } : n
        )
      );
    } catch (error) {
      console.error('Error updating notification status:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/notification-delete/${id}/`);
      setNotifications((prevNotifications) => prevNotifications.filter((n) => n.id !== id));
    } catch (error) {
      console.error('Error deleting notification:', error);
    }
  };
  const handleTagsHubClick = () => {
    navigate('/manage-tags'); // Redirects to the Manage Tags route
  };
  const renderUserAvatar = (notification) => {
    const userInitial = notification.user?.name?.charAt(0).toUpperCase() || 'U';
    return (
      <div className="user-avatars mb-4">
        {user?.file_name ? (
          <img src={`${staticPath}${notification.user.file_name}`} alt="User Avatar" className="avatar-image" />
        ) : (
          <div className="avatar-initial">{userInitial}</div>
        )} 
      </div>
    );
  };
    /*eslint-disable*/
  const renderNotificationItem = (notification) => {
    const truncatedTitle =
      notification.title.length > 200 ? `${notification.title.substring(0, 200)}...` : notification.title;
    const formattedDate = new Date(notification.created_at).toLocaleString();

    return (
      <div  style={{
        backgroundColor: notification.is_read ? '#f9f9f9' : '#e8f4f8', // Example colors
      }}className="notification-item" key={notification.id} onMouseEnter={() => handleHover(notification.id)} onMouseLeave={() => handleHover(null)}>
        {renderUserAvatar(notification)}
        <div className="notification-content">
          <div className="notification-title">{truncatedTitle}</div>
          <div className="notification-meta">
            <p className='py-2'>
            <span>{formattedDate}</span> | <span>{notification.cell.sheet_name}</span> | <span
  className='notification-type-container'
  style={{
    backgroundColor: notification.notificatio_type === 'general' ? 'green' : 'transparent'
  }}
>
  {notification.notificatio_type === 'general' ? 'assigned' : notification.notificatio_type}
</span>


            </p>
            {hoveredNotification === notification.id && (
         <div className="notification-actions">
         <i
           className={`bi ${notification.is_read ? 'bi-check-circle-fill' : 'bi-circle'} notification-read-icon`}
           onClick={() => handleReadToggle(notification)}
           title={notification.is_read ? 'Mark as unread' : 'Mark as read'}

         ></i>
         <i className="bi bi-trash notification-delete-icon" title='Delete' onClick={() => handleDelete(notification.id)}></i>
       </div>
       
        )}
          </div>
          
        </div>
        
      </div>
    );
  };
      /*eslint-enable*/

  const totalNotifications = notifications.length;
  const unreadCount = notifications.filter(n => !n.is_read).length;
  return (
    <Navbar className="headerbar px-2">
      <div className="header-left">
        {/* Logo and title (if any) */}
      </div>

      <div className="header-right">
        
        <Nav className="">
        <div className="notification-container pt-1 mx-2" onClick={handleTagsHubClick}>
      <FaTags
        style={{ cursor: 'pointer', fontSize: '1.8rem', color: 'gray' }}
        title="Tags Hub"
      />
    </div>
          <div className="notification-container pt-1 mx-2" onClick={handleNotificationClick}>
            <img
              src={`${process.env.REACT_APP_TOS_PATH}/assets/svg/menuicon/notification_2.png`}
              alt="Notification Icon"
              className="bell-img"
              style={{ cursor: 'pointer' }}
            />
          </div>

          <div className="avatar-container"  onClick={handleProfile}>
            <img
              src={imageUrl}
              alt="Avatar"
              className="avatar-img"
            />
          </div>
        </Nav>
        {profileMenu && (
  <div className="Prfile-menu" ref={userMenuRef}>
    <div className="user-details">
      <img src={imageUrl} alt="User" className="user-avatar" />
      <div className="user-infos">
        <div className="user-role" >{user?.name}</div>
        <div className="user-email">{user?.email||''}
        </div>
      </div>
    </div>
    <hr />
    <button className="signout mx-2" onClick={() => console.log('Navigate to My Profile')}>My Profile</button>
  <button className="signout mx-2" onClick={() => console.log('Navigate to Organization Chart')}>Organization Chart</button>
  <button className="signout mx-2" onClick={() => console.log('Navigate to Checklist')}>Checklist</button>
  <hr />
  <button className="signout mx-2" onClick={handleSignOut}>Sign out</button>
    {/* <a className="signout mx-2" href="">My Profile</a>
    <a className="signout mx-2" href="">Organization Chart</a>
    <a className="signout mx-2" href="">Checklist</a>
    <hr />
    <a className="signout mx-2"  href="#"
  onClick={handleSignOut}>Sign out</a> */}
  </div>
)}

      </div>
     

      {/* Notification Drawer */}
      <Offcanvas show={showDrawer} onHide={handleClose} placement="end" className="notification-drawer">
        <Offcanvas.Header>
          <Offcanvas.Title>Notifications</Offcanvas.Title>
          <Button variant="link" className="close-btn" onClick={handleClose}>
            <i className="bi bi-x-lg"></i>
          </Button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="notification-tabs ">
            <div className="d-flex justify-content-between">
              <div
                className={`tab-btn ${activeTab === 'All' ? 'active' : ''}`}
                onClick={() => handleTabClick('All')}
              >
                All <span className="tab-count">({totalNotifications})</span>
              </div>
              <div
                className={`tab-btn ${activeTab === 'Unread' ? 'active' : ''}`}
                onClick={() => handleTabClick('Unread')}
              >
                Unread <span className="tab-count">({unreadCount})</span>
              </div>
              <div
                className={`tab-btn ${activeTab === 'I Was Mentioned' ? 'active' : ''}`}
                onClick={() => handleTabClick('I Was Mentioned')}
              >
                I Was Mentioned
              </div>
              <div
                className={`tab-btn ${activeTab === 'Assigned to Me' ? 'active' : ''}`}
                onClick={() => handleTabClick('Assigned to Me')}
              >
                Assigned to Me
              </div>
            </div>
          </div>
          <div className="notification-search-filter">
            <div className="search-container">
              <i className="bi bi-search"></i>
              <input type="text" className="form-control" placeholder="Search notifications..." />
            </div>
            <div className="filter-container">
              <img
                src={imageUrl}
                alt="User Avatar"
                className="filter-avatar"
              />
              <span className="filter-label">Filter by person</span>
            </div>
          </div>
          <div className="notification-items">
            {notifications
              .filter((notification) => activeTab === 'All' || (activeTab === 'Unread' && !notification.is_read))
              .map((notification) => renderNotificationItem(notification))}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </Navbar>
  );
}

export default Header;
