import React, { useState, useEffect, useRef } from "react";
import { AiOutlineEnvironment } from "react-icons/ai";
import { IoCloseSharp } from "react-icons/io5";

const LocationHandler = ({ ID, apiUrl, fetchData, cell_data }) => {
  const [location, setLocation] = useState(cell_data?.location || "");
  const [editing, setEditing] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 }); // Store position
  const [mapVisible, setMapVisible] = useState(false);  // Control map visibility
  const [coordinates, setCoordinates] = useState(cell_data?.coordinates || null); // Initialize coordinates from cell_data
  const [exactLocation, setExactLocation] = useState(""); // Store exact location (address)
  const wrapperRef = useRef(null);

  const fetchSuggestions = async (query) => {
    if (!query) {
      setSuggestions([]);
      return;
    }
  
    setLoading(true);
    try {
      const response = await fetch(
        `https://autocomplete.search.hereapi.com/v1/autocomplete?q=${encodeURIComponent(query)}&apiKey=GVg7kX1RhFXLJ6msQNn2LuETmeoxl8uxGbWsGlNZbIU`
      );
      const data = await response.json();
  
      const formattedSuggestions = data.items?.map((item) => {
        const city = item.address?.city || "";
        const state = item.address?.state || "";
        const country = item.address?.countryName || "";
        const displayName = `${city}, ${state}, ${country}`
          .replace(/, , /g, ", ")
          .trim(", ");
        return { displayName, locationName: item.title }; // Added location name
      }) || [];
  
      setSuggestions(formattedSuggestions.filter((s) => s.displayName.trim()));
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const fetchExactLocation = async (locationName) => {
    if (!exactLocation) return; // Ensure we have a location name
  
    try {
      const response = await fetch(
        `https://geocode.search.hereapi.com/v1/geocode?q=${encodeURIComponent(locationName)}&apiKey=GVg7kX1RhFXLJ6msQNn2LuETmeoxl8uxGbWsGlNZbIU`
      );
      const data = await response.json();
      const firstItem = data.items?.[0];
  
      if (firstItem) {
        const lat = firstItem.position?.lat;
        const lon = firstItem.position?.lng;
        setCoordinates({ lat, lon });  // Set coordinates
      } else {
        console.error("No coordinates found for this location");
      }
    } catch (error) {
      console.error("Error fetching exact location:", error);
    }
  };
  
  

  const handleSave = async (selectedLocation, locationName) => {
    try {
      // Wait for the coordinates to be fetched before proceeding
      await fetchExactLocation(locationName); // This will update the coordinates state
      if (!coordinates) {
        console.log("Coordinates not available yet.");
        return; // Exit if coordinates are not available
      }
      
      // Now that coordinates are available, proceed to send the request
      const payload = { cell_data: { location: selectedLocation, coordinates } };
      await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/cell/${ID}/`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      
      // Call the fetchData after the save operation
      fetchData();
      
      // Exit editing mode after the save
      setEditing(false);
    } catch (error) {
      console.error("Error saving location:", error);
    }
  };
  
 
  const HandleLocation = async (item) => {
    try {
      // Step 1: Set the location value
      console.log("Selected location:", item?.locationName);
      setLocation(item.displayName);
      setExactLocation(item.locationName);
  
      // Step 2: Fetch exact location coordinates based on the selected location
      const response = await fetch(
        `https://geocode.search.hereapi.com/v1/geocode?q=${encodeURIComponent(item.locationName)}&apiKey=GVg7kX1RhFXLJ6msQNn2LuETmeoxl8uxGbWsGlNZbIU`
      );
      const data = await response.json();
      const firstItem = data.items?.[0];
  
      if (firstItem) {
        const lat = firstItem.position?.lat;
        const lon = firstItem.position?.lng;
  
        // Step 3: Set the coordinates state
        setCoordinates({ lat, lon });
  
        // Step 4: Once the coordinates are fetched, update the cell
        const payload = { cell_data: { location: item.displayName, coordinates: { lat, lon } } };
        await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/cell/${ID}/`, {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        });
  
        // After updating the cell, refresh the data
        fetchData();
      } else {
        console.error("No coordinates found for this location");
      }
    } catch (error) {
      console.error("Error handling location:", error);
    }
  };
  
  
  const handleRemoveLocation = async () => {
    try {
      const payload = { cell_data: { location: "", coordinates: null } }; // Remove location and coordinates
      await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/cell/${ID}/`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      setLocation("");
      setCoordinates(null);
      fetchData();
    } catch (error) {
      console.error("Error removing location:", error);
    }
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setEditing(false);
      setMapVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (coordinates) {
      fetchExactLocation(coordinates.lat, coordinates.lon); // Fetch exact location when coordinates change
    }
  }, [coordinates]);

  const handleMapIconClick = () => {
    if (!coordinates) {
      console.log("No coordinates available for this location.");
      return; // Do nothing if coordinates are missing
    }
    setMapVisible(true);  // Show map if coordinates are available
  };
  const handleShowPopup = (event) => {
    const rect = event.target.getBoundingClientRect();
    const scrollX = window.scrollX || 0;
    const scrollY = window.scrollY || 0;

    setPopupPosition({
      top: rect.bottom + scrollY + 5, // Position 5px below the triggering element
      left: rect.left + scrollX, // Align with the left edge of the triggering element
    });
    setEditing(true);
  };
  return (
    <div ref={wrapperRef} style={{ position: "relative", minWidth: "200px" ,}}>
      {!editing && !location && (
        <div
        onClick={(e) => handleShowPopup(e)}
        style={{
            minHeight: '50px',
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AiOutlineEnvironment size={20} color="#007bff" />
        </div>
      )}

      {!editing && location && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            minHeight: '50px',
            justifyContent: "center",
          }}
          className="px-2"
        >
             <AiOutlineEnvironment
              size={20}
              color="green"
              style={{ cursor: "pointer" }}
              onClick={handleMapIconClick}  // Trigger map visibility on click
            /> {' '}
          <span
            style={{
            //   color: "#007bff",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
            title={location}
          >
            {location}
          </span>
          <div className="open-button-containers">

          <div style={{ display: "flex", gap: "10px" }}>
           
            <IoCloseSharp
              size={18}
              color="red"
              style={{ cursor: "pointer" }}
              onClick={handleRemoveLocation}
            />
          </div>
          </div>

        </div>
      )}

      {editing && (
        <div>
          <input
            type="text"
            value={location}
            onChange={(e) => {
              setLocation(e.target.value);
              fetchSuggestions(e.target.value);
            }}
            placeholder="Enter location"
            style={{ width: "100%",            minHeight: '45px',
            }}
          />
          {loading && <div>Loading...</div>}
          {suggestions.length > 0 && (
  <div
    style={{
      position: "fixed",
            top: `${popupPosition.top}px`,
            left: `${popupPosition.left-170}px`,
    //   top: "100%",
    // left: "-20px",
      width: "300px", 
      backgroundColor: "#fff",
      border: "1px solid #ddd",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      zIndex: 1000,
      padding: "10px", // Add padding for a clean layout
    }}
  >
    {/* Heading */}
    <div
      style={{
        marginBottom: "10px",
        fontWeight: "bold",
        fontSize: "16px",
        color: "#333",
        textAlign: "left",
        borderBottom: "1px solid #ddd",
        paddingBottom: "5px",
      }}
    >
      Location
    </div>

    {/* Suggestions */}
    <ul
      style={{
        listStyle: "none",
        margin: 0,
        padding: 0,
        maxHeight: "150px",
        overflowY: "auto",
      }}
      role="listbox"
      aria-live="polite"
    >
      {suggestions.map((item, index) => (
        <li
          key={index}
          onClick={() => HandleLocation(item)} // Pass a function reference here
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px",
            cursor: "pointer",
            // borderBottom: "1px solid #ddd",
            transition: "background-color 0.3s ease",
            borderRadius: "4px",
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = "#f9f9f9";
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "transparent";
          }}
        >
          {/* Icon */}
          <AiOutlineEnvironment
            size={18}
            color="#007bff"
            style={{ marginRight: "10px" }}
          />

          {/* Location Text */}
          <span style={{  color: "#333", fontSize: "14px" }}>
            {item.displayName}
          </span>
        </li>
      ))}
    </ul>
  </div>
)}

        </div>
      )}

      {mapVisible && coordinates && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 2000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => setMapVisible(false)}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              position: "relative",
              width: "80%",
              height: "80%",
              backgroundColor: "#fff",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <iframe
              src={`https://www.google.com/maps?q=${coordinates.lat},${coordinates.lon}&z=15&output=embed`}
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen
            ></iframe>
            <IoCloseSharp
              size={30}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
              }}
              onClick={() => setMapVisible(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationHandler;
