import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { FaEdit, FaTrashAlt, FaLock, FaUnlock, FaEye, FaEyeSlash } from 'react-icons/fa'; // Importing icons from react-icons
import '../../assets/styles/WorkspaceDetails.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { toast } from 'react-toastify';
import { Nav, Modal, Button, Form, InputGroup, Row, Col, Container, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import { deleteSheet } from '../../features/workspaces/workspaceActions';
import ConfirmationPopup from '../common/ConfirmationPopup';
import { useDispatch } from 'react-redux';
import { AiOutlineArrowLeft } from 'react-icons/ai';

const WorkspaceDetails = ({ user,setDeleteId }) => {
  const { workspaceId } = useParams();
  const [sheets, setSheets] = useState([]);
  const [workspaceName, setWorkspaceName] = useState('');
  const [sheetName, setSheetName] = useState('');
  const [privacyType, setPrivacyType] = useState('public');
  const [isSheetPopupVisible, setIsSheetPopupVisible] = useState(false);
  const [isUpdateSheetPopupVisible, setIsUpdateSheetPopupVisible] = useState(false);
  const [currentSheet, setCurrentSheet] = useState(null);
  const [managementType, setManagementType] = useState('budgets');
  const [customManagementType, setCustomManagementType] = useState('');
  const [deletingSheetId, setDeletingSheetId] = useState(null);
  const [showSheetConfirmation, setShowSheetConfirmation] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // Function to handle back navigation
  const handleBackClick = () => {
    navigate("/"); // Navigate to the home page
  };


  function getCookieValue(name) {
    const matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([.$?*|{}()[]\\\/+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }
  
  const ssoToken = getCookieValue('sso_token');


  const globalUserId = user?.userid ||ssoToken

  useEffect(() => {
    console.log("kjhgfdfghjkl",globalUserId)
   

    fetchSheets();
  }, [workspaceId]);
  const fetchSheets = async () => {
    if ( workspaceId && globalUserId) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT}/hotwash/workspace-sheets/${globalUserId}/${workspaceId}/`
      );
      const data = await response.json();
      setSheets(data.sheets);
      setWorkspaceName(data?.workspace?.workspace_name);
    } catch (error) {
      console.error('Error fetching sheets:', error);
    }
  }
  };
  const handleRowClick = (row, e) => {
    // Check if the clicked target is part of the actions column (i.e., icon)
    if (e.target.closest(".action-icon")) {
      return; // Don't navigate if actions icon was clicked
    }
    const url = `/frame/${workspaceId}/${row.name}/${row?.id}`;
    navigate(url);
  };
  const columns = [
    {
      name: '#',
      selector: (_, index) => index + 1,
      width: '50px',
    },
    {
      name: 'Sheet Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Privacy Type',
      selector: (row) => row.privacy_type,
      cell: (row) => (
        <span>
          {row.privacy_type == 'Private'|| row.privacy_type == 'private' ? (
            <FaLock style={{ color: 'red', marginRight: '5px' }} />
          ) : (
            <FaUnlock style={{ color: 'green', marginRight: '5px' }} />
          )}
          {row.privacy_type}
        </span>
      ),
      sortable: true,
    },
    {
      name: 'Access Type',
      selector: (row) => row.access_type,
      cell: (row) => (
        <span>
          {row?.is_admin && <span className="badge bg-warning">Admin</span>}
          { !row?.is_admin && row.can_edit && <span className="badge bg-info">Subscriber(Edit acess)</span>}
          { !row?.is_admin && !row.can_edit && <span className="badge bg-secondary">Subscriber(Viewer)</span>}
        </span>
      ),
    },
    
    {
      name: 'Actions',
      cell: (row) => (
        <div className="d-flex justify-content-start">
          {/* Edit Action */}
          {(row?.is_admin || row?.is_owner) ? (
            <FaEdit
              className="action-icon"
              onClick={() => handleEditClick(row)}
              style={{
                cursor: 'pointer',
                marginRight: '10px',
                color: 'black',
              }}
            />
          ) : (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>You need admin or owner privileges to edit</Tooltip>}
            >
              <span>
                <FaEdit
                  className="action-icon"
                  style={{
                    cursor: 'not-allowed',
                    marginRight: '10px',
                    color: 'grey',
                  }}
                />
              </span>
            </OverlayTrigger>
          )}
    
          {/* Delete Action */}
          {row?.is_owner ? (
            <FaTrashAlt
              className="action-icon"
              onClick={() => handleDeleteSheet(row.id)}
              style={{
                cursor: 'pointer',
                color: 'red',
              }}
            />
          ) : (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>You need to be the owner to delete</Tooltip>}
            >
              <span>
                <FaTrashAlt
                  className="action-icon"
                  style={{
                    cursor: 'not-allowed',
                    color: 'grey',
                  }}
                />
              </span>
            </OverlayTrigger>
          )}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
    
  ];
  const handleEditClick = (sheet) => {
    console.log("sheeeeeet",sheet)
    setCurrentSheet(sheet);
    setSheetName(sheet.name);
    setPrivacyType(sheet.privacy_type);
    setManagementType(sheet.managementType);
    setIsUpdateSheetPopupVisible(true);
  };
  const handleUpdateSheetSubmit = () => {
    const payload = {
      name: sheetName,
      privacy_type: privacyType,
      workspace_id:currentSheet.workspace_id,
      user:user?.userid,
      // managementType: managementType
    };

    axios.put(`${process.env.REACT_APP_API_ROOT}/hotwash/sheets/${currentSheet.id}/`, payload)
      .then(response => {
        setSheets(sheets.map(sheet => sheet?.id === currentSheet?.id ? response.data : sheet));
        setIsUpdateSheetPopupVisible(false);
        fetchSheets();

        toast.success('Sheet Updated Successfully')
         setSheetName('');
      setCustomManagementType('')
      setManagementType('')
      })
      .catch(error => {
        console.error('There was an error updating the sheet!', error);
      });
  };
  const handleCloseModalUpdateSheet = () => {
    setIsUpdateSheetPopupVisible(false);  // Hide the modal
    setSheetName('');                     // Reset sheet name
    setCustomManagementType('');          // Reset custom management type
    setManagementType(''); 
  };
  // const handleEdit = (sheet) => {
  //   console.log(`Edit clicked for ${sheet.name}`);
  // };

  const handleDelete = (id) => {
    console.log(`Delete clicked for ID ${id}`);
  };
  

 
  

  
  const deleteSheetSubmit = async (id) => {
    try {
      await dispatch(deleteSheet(id));
      // setsidebartrue(!sidebartrue)
     
      toast.success('Sheet Deleted Successfully')
      fetchSheets()
      // window.location.reload()
      console.log(`Frame with ID ${id} deleted successfully.`);
    } catch (error) {
      console.error(`Error deleting frame with ID ${id}:`, error);
    }
  };
  const handleDeleteSheet = (id) => {
    setDeletingSheetId(id); // Store the frame ID to delete
    setShowSheetConfirmation(true); // Show confirmation dialog
    console.log("travelling")
  };
  

  const handleConfirmSheetDelete = () => {
    deleteSheetSubmit(deletingSheetId); // Call delete function with stored frame ID
    setShowSheetConfirmation(false); // Close confirmation dialog
    console.log("travelling")
    setDeleteId(deletingSheetId);
  };
  

  const handleCancelSheetDelete = () => {
    setShowSheetConfirmation(false);
  };
  
  const customStyles = {
    header: {
      style: {
        fontSize: '22px',
        fontWeight: 'bold',
        color: '#4B5563',
        textAlign: 'center',
        margin: '10px 0',
      },
    },
    headCells: {
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#111827',
        backgroundColor: '#F3F4F6',
        borderBottom: '2px solid #E5E7EB',
      },
    },
    cells: {
      style: {
        fontSize: '14px',
        padding: '10px',
      },
    },
    rows: {
      style: {
        fontSize: '14px',
        color: '#374151',
        backgroundColor: '#FFFFFF',
        '&:nth-of-type(odd)': {
          backgroundColor: '#F9FAFB',
        },
        '&:hover': {
          backgroundColor: '#E5E7EB',
        },
      },
    },
  };

  return (
    <div className="workspace-details-page">
      <div className="p-4">
      <div className="d-flex justify-content-between align-items-center p-3">
      <h1 className="text-left">{workspaceName}</h1>
      <button
        className="btn btn-outline-primary d-flex align-items-center" // Improved button style
        style={{
          fontSize: "1.2rem", // Slightly smaller font size for better look
          cursor: "pointer",
          padding: "6px 12px", // Adding some padding to make the button more clickable
          borderRadius: "5px", // Rounded corners
        }}
        onClick={handleBackClick}
      >
        <AiOutlineArrowLeft style={{ marginRight: "8px", fontSize: "1.4rem" }} /> {/* Added spacing between icon and text */}
        Back
      </button>
    </div>        <div className="table-container">
          <DataTable
            columns={columns}
            data={sheets}
            customStyles={customStyles}
            highlightOnHover
            pagination
            noDataComponent="No sheets available"
            onRowClicked={(row, e) => handleRowClick(row, e)} // Attach onRowClick to navigate on row click

          />
        </div>
        <Modal show={isUpdateSheetPopupVisible} onHide={handleCloseModalUpdateSheet} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Sheet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="sheetName">
              <Form.Label>Sheet Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter sheet name"
                value={sheetName}
                onChange={(e) => setSheetName(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="privacyType" className="mb-3">
              <Form.Label>Privacy Type</Form.Label>
              <div className="d-flex">
                <Form.Check
                  type="radio"
                  label="Public"
                  name="privacyType"
                  value="public"
                  checked={privacyType === 'public'}
                  onChange={() => setPrivacyType('public')}
                  className="me-3"
                />
                <Form.Check
                  type="radio"
                  label="Private"
                  name="privacyType"
                  value="private"
                  checked={privacyType === 'private'}
                  onChange={() => setPrivacyType('private')}
                  className="me-3"
                />
                <Form.Check
                  type="radio"
                  label="Sharable"
                  name="privacyType"
                  value="sharable"
                  checked={privacyType === 'sharable'}
                  onChange={() => setPrivacyType('sharable')}
                />
              </div>
            </Form.Group>

            {/* <Form.Group controlId="managementType">
              <Form.Label>Select what you're Managing in this Sheet</Form.Label>
              <div className="d-flex flex-wrap">
                <div className="d-flex mb-3">
                  <Form.Check
                    type="radio"
                    label="Budgets"
                    name="managementType"
                    value="budgets"
                    checked={managementType === 'budgets'}
                    onChange={() => setManagementType('budgets')}
                    className="me-3"
                  />
                  <Form.Check
                    type="radio"
                    label="Employees"
                    name="managementType"
                    value="employees"
                    checked={managementType === 'employees'}
                    onChange={() => setManagementType('employees')}
                    className="me-3"
                  />
                  <Form.Check
                    type="radio"
                    label="Projects"
                    name="managementType"
                    value="projects"
                    checked={managementType === 'projects'}
                    onChange={() => setManagementType('projects')}
                    className="me-3"
                  />
                </div>
                <div className="d-flex mb-3">
                  <Form.Check
                    type="radio"
                    label="Clients"
                    name="managementType"
                    value="clients"
                    checked={managementType === 'clients'}
                    onChange={() => setManagementType('clients')}
                    className="me-3"
                  />
                  <Form.Check
                    type="radio"
                    label="Tasks"
                    name="managementType"
                    value="tasks"
                    checked={managementType === 'tasks'}
                    onChange={() => setManagementType('tasks')}
                    className="me-3"
                  />
                  <div className="custom-radio-option d-flex align-items-center">
                    <Form.Check
                      type="radio"
                      label={<input type="text" className="form-control custom-radio-input" placeholder="Custom" />}
                      name="managementType"
                      value="custom"
                      checked={managementType === 'custom'}
                      onChange={() => setManagementType('custom')}
                      className="me-3"
                    />
                  </div>
                </div>
              </div>
            </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsUpdateSheetPopupVisible(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateSheetSubmit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <ConfirmationPopup
        isOpen={showSheetConfirmation}
        onClose={handleCancelSheetDelete}
        onConfirm={handleConfirmSheetDelete}
        isLoading={false} // You can set this to true when deleting
      />
      </div>
    </div>
  );
};

export default WorkspaceDetails;
