import React, { useState, useEffect, useRef } from "react";
import { AiOutlinePlus, AiOutlineClose, AiOutlineDelete } from "react-icons/ai";
import { Modal, Button } from "react-bootstrap";

const LibraryHandler = ({ ID, apiUrl, fetchData, cell_data,userId }) => {
  const [items, setItems] = useState(cell_data?.items || []); // Selected items
  const [libraryList, setLibraryList] = useState([]); // Full library list
  const [filteredLibraryList, setFilteredLibraryList] = useState([]); // Filtered list for search
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 }); // Store position
  const [searchTerm, setSearchTerm] = useState(""); // Search term
  const [showMenu, setShowMenu] = useState(false); // Show menu below cell
  const [showModal, setShowModal] = useState(false); // Show modal with all libraries
  const menuRef = useRef(null); // Reference for the menu position
  const menuButtonRef = useRef(null); // Reference for the menu button

  // Fetch libraries from API based on search term
  useEffect(() => {
    const fetchLibraries = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_ROOT}/sch/search-user-data/?user_id=${userId}&query=${searchTerm}`);
        const data = await response.json();
        setLibraryList(data);
        setFilteredLibraryList(data); // Initially show the fetched libraries
      } catch (error) {
        console.error("Error fetching libraries:", error);
      }
    };

    if (searchTerm) {
      fetchLibraries();
    } else {
      setLibraryList([]);
      setFilteredLibraryList([]);
    }
  }, [searchTerm]);

  // Update filtered library list when search term changes
  useEffect(() => {
    const filtered = libraryList.filter((item) =>
      item.filename.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredLibraryList(filtered);
  }, [searchTerm, libraryList]);

  // Close menu if clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current && !menuRef.current.contains(event.target) &&
        !menuButtonRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Add selected library item to cell data
  const addItemToCell = (item) => {
    if (!items.some((selected) => selected.id === item.id)) {
      const newItems = [...items, item];
      setItems(newItems);
      updateCellData(newItems);
    }
    setShowMenu(false); // Close the menu after selecting an item
  };

  // Remove item from cell data
  const removeItemFromCell = (itemId) => {
    const updatedItems = items.filter((item) => item.id !== itemId);
    setItems(updatedItems);
    updateCellData(updatedItems);
  };

  // Update the cell data (send data to API)
  const updateCellData = async (newItems) => {
    try {
      const payload = { cell_data: { items: newItems } };
      await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/cell/${ID}/`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      fetchData(); // Refresh parent data
    } catch (error) {
      console.error("Error updating cell data:", error);
    }
  };

  // Show menu under the cell when clicked
  // const handleCellClick = () => {
  //   setShowMenu((prev) => !prev);
  // };
  const handleShowPopup = (event) => {
    const rect = event.target.getBoundingClientRect();
    const scrollX = window.scrollX || 0;
    const scrollY = window.scrollY || 0;

    setPopupPosition({
      top: rect.bottom + scrollY + 5, // Position 5px below the triggering element
      left: rect.left + scrollX, // Align with the left edge of the triggering element
    });
    setShowMenu((prev) => !prev);
};
  // Open modal with all selected library items
  const handleModalOpen = () => {
    setShowModal(true);
  };

  // Handle hover to show the plus icon
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = () => setIsHovered(true);
  const handleLeave = () => setIsHovered(false);
  const mapTypeToImage = (item) => {
    const type=item.type;
    console.log("item",item);
    switch (type.toLowerCase()) {
      case 'folder':
        return "/default-folder.png"; // Replace with actual path
      case 'pdf':
        return "/pdf_4.png"; // Update with correct path
      case 'doc':
      case 'docx':
        return "/ms-word3.png";
      case 'jpg':
      case 'jpeg':
        return "/jpeg1.png";
      case 'png':
        return "/png1.png";
      case 'gif':
        return "/gif-icon.png";
      case 'html':
        return "/html-icon.png";
      case 'xlsx':
        return "/xlsx3.png";
      case 'sql':
        return "/sql1.png";
      case 'online':
        return "/link3.png";
      case 'offline':
        return "/link3.png";
      case 'image':
        return "/imageicon.png";
      case 'js':
        return "/js-icon.png";
      case 'css':
        return "/css-icon.png";
        case 'book':
       return item?.bookimage ;
      default:
        return "/default.png"; // Default image
    }
  };
  const mapTypeToNavigation = (type, id, filename, link, bookUrl) => {
    // Define base URLs for local and live environments
    const localBaseURL = 'http://localhost/latest/TOS/canvas/';
    const liveBaseURL = 'https://msarii.com/TOS/canvas/';

    // Define the path for files
    const filePath = 'includes/Pages/files/';

    // Determine whether to use local or live base URL
    const isLocal = window.location.hostname === 'localhost';
    const baseURL = isLocal ? localBaseURL : liveBaseURL;

    // Define supported file types for which query parameters should be omitted
    const fileTypesWithoutQueryParams = ['png', 'jpg', 'jpeg', 'doc', 'docx', 'html', 'pdf', 'sql', 'css', 'js', 'mp4', 'mp3', 'xlsx', 'xlx'];

    // Extract the file extension from the filename
    const fileExtension = filename.split('.').pop().toLowerCase();

    // If the type is 'book', use the bookUrl for redirection
    if (type.toLowerCase() === 'book' && bookUrl) {
        window.open(bookUrl, '_blank'); // Open the bookUrl in a new tab
        return; // Exit the function after redirection
    }

    // Generate the full file URL based on type (if not 'online' or 'offline') and file extension
    let fullURL = type !== 'online' && type !== 'offline' ? baseURL + filename : link;

    // Log the URL construction for debugging
    console.log(`Opening pageType: ${getPageType(type)}, extraId: ${id || ''}, URL: ${fullURL}`);

    // Set the localStorage values in the parent window (for use in the new window)
    localStorage.setItem('extraId', id || '');
    localStorage.setItem('pageType', getPageType(type));

    console.log(localStorage.getItem('pageType'), localStorage.getItem('extraId'));

    // Check if the file type should be loaded without query parameters
    if (fileTypesWithoutQueryParams.includes(fileExtension)) {
        // If the file type matches, use the base file path and open the file URL directly
        const fileURL = `${isLocal ? 'http://localhost/latest/TOS/' : 'https://msarii.com/TOS/'}${filePath}${filename}`;
        window.open(fileURL, '_blank');
    } else {
        // Otherwise, handle it as a page with query parameters
        let pageURL = '';
        switch (type.toLowerCase()) {
            case 'library':
                pageURL = baseURL + "libraries.php";
                break;
            case 'shelf':
                pageURL = baseURL + "shelves.php";
                break;
            case 'shops':
                pageURL = baseURL + "shops.php";
                break;
            case 'folder':
                pageURL = baseURL + "folders.php";
                break;
            case 'briefcase':
                pageURL = baseURL + "briefcases.php";
                break;
            default:
                pageURL = fullURL; // If none of the predefined types match, use the `fullURL`
                break;
        }

        // Create a URL object from the page URL
        let targetURL = new URL(pageURL);

        // Add query parameters for pageType and extraId
        targetURL.searchParams.set('pageType', getPageType(type)); // Set pageType query param
        targetURL.searchParams.set('extraId', id || ''); // Set extraId query param

        // Log the final target URL with query parameters for debugging
        console.log('Target URL with query params:', targetURL.toString());

        // Open the corresponding page in a new window
        let newWindow = window.open(targetURL.toString(), '_blank');

        // Ensure the new window is fully loaded before sending the message
        if (newWindow) {
            newWindow.onload = function () {
                console.log('New window loaded, sending message');
                // Send the data to the new window using postMessage
                newWindow.postMessage(
                    { pageType: getPageType(type), extraId: id || '' },
                    isLocal ? 'http://localhost' : 'https://msarii.com' // Use correct target origin
                );
            };
        }
    }
};

// Helper function to return the appropriate page type
const getPageType = (type) => {
    switch (type.toLowerCase()) {
        case 'library': return 'libraries';
        case 'shelf': return 'shelves';
        case 'shops': return 'shops';
        case 'folder': return 'folders';
        case 'briefcase': return 'briefcases';
        default: return 'unknown';
    }
};





    
  return (
    <div style={{ position: "relative" }} onMouseEnter={handleHover} onMouseLeave={handleLeave}>
      {/* Display selected items with + count if more than 3 */}
      {items.length > 0 ? (
        <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", alignItems: "center", justifyContent: "center" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px", flexWrap: "wrap" }}>
            <div           onClick={(e) => handleShowPopup(e)}
 style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", minHeight: "50px" }} ref={menuButtonRef}>
              {isHovered && <AiOutlinePlus size={20} color="#007bff" />}
            </div>
            {items.slice(0, 1).map((item) => (
  <div
    onClick={() => mapTypeToNavigation(item.type, item.id, item.filename, item.link, item?.bookurl)} // Wrap the call in a function
    key={item.id}
    className="badge badge-primary"
    style={{
      display: "flex",
      alignItems: "center",
      padding: "5px 10px",
      backgroundColor: "#007bff",
      color: "#fff",
      borderRadius: "12px"
    }}
  >
    <img
      src={mapTypeToImage(item)}
      alt={item.type}
      style={{ width: '30px', height: '30px', marginRight: '10px' }}
    />
    {item.filename}
    <AiOutlineClose
      size={15}
      style={{ marginLeft: "8px", cursor: "pointer" }}
      onClick={(e) => {
        e.stopPropagation(); // Prevent the parent div's onClick from being triggered
        removeItemFromCell(item.id); // Remove the item
      }}
    />
  </div>
))}

            {items.length > 1 && (
              <div className="badge badge-secondary" style={{ padding: "5px 10px", backgroundColor: "#6c757d", color: "#fff", borderRadius: "12px", cursor: "pointer" }} onClick={handleModalOpen}>
                +{items.length - 1}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div  onClick={(e) => handleShowPopup(e)} style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", minHeight: "50px" }} ref={menuButtonRef}>
          {isHovered && <AiOutlinePlus size={20} color="#007bff" />}
        </div>
      )}

      {/* Menu below the cell */}
      {showMenu && (
        <div
          ref={menuRef}
          className="menu"
          style={{
            position: "fixed",
            top: `${popupPosition.top}px`,
            left: `${popupPosition.left-150}px`,
            zIndex: 1000,
            backgroundColor: "#fff",
            padding: "10px",
            boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
            width: "250px",
            maxWidth: "500px",
            maxHeight: "300px",
            overflowY: "auto",
            borderRadius: "5px",
          }}
        >
          <input
            type="text"
            placeholder="Search libraries..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ width: "100%", marginBottom: "10px", padding: "8px" }}
          />
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {filteredLibraryList.map((item) => (
              <li
                key={item.id}
                style={{ marginBottom: "10px", display: "flex", justifyContent: "space-between", cursor: "pointer" }}
                onClick={() => addItemToCell(item)}
              >
                <span>{item.filename}</span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Modal for viewing all selected library items */}
      {showModal && (
  <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Selected Libraries</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
        {items.map((item) => (
          <div
          onClick={() => mapTypeToNavigation(item.type, item.id, item.filename, item.link,item?.bookurl)}
          
            key={item.id}
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#e9ecef", // Lighter background for badges
              padding: "5px 10px", // Reduced padding
              borderRadius: "15px", // Slightly smaller rounded corners
              boxShadow: "0 1px 2px rgba(0,0,0,0.1)", // Subtle shadow
              fontSize: "14px", // Smaller font size
              justifyContent: "space-between",
            cursor:'pointer',
              minWidth: "120px", // Ensure each item is compact but readable
            }}
          >
            <img
        src={mapTypeToImage(item)} // Use the mapTypeToImage function to get the image URL
        alt={item.type}
        style={{ width: "30px", height: "30px", marginRight: "10px", }} // Style for the image
      />
            {/* Library item filename */}
            <span style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontWeight: "500", color: "#333" }}>
              {item.filename}
            </span>

            {/* Crossmark icon */}
            <AiOutlineClose
              size={16} // Smaller icon size
              style={{
                cursor: "pointer",
                color: "#dc3545", // Red color for the cross icon
                marginLeft: "8px", // Reduced space between text and icon
              }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent the parent div's onClick from being triggered
                removeItemFromCell(item.id); // Remove the item
              }}            />
          </div>
        ))}
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setShowModal(false)}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
)}

    </div>
  );
};

export default LibraryHandler;
