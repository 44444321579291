import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {  useParams } from 'react-router-dom';
import { BiPlus, BiCalendar, BiTime, BiCheckbox, BiText, BiUser, BiTrash, BiDotsHorizontal, BiFile, BiDialpad,BiChevronDown, BiDotsVertical, BiEdit, BiChevronUp, BiLink, BiNote, BiBook } from 'react-icons/bi';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AiOutlineTable,AiOutlineSchedule, AiOutlineMail, AiOutlinePhone, AiOutlineEnvironment } from 'react-icons/ai';  // Table icon
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { FaCalendarAlt, FaCheck, FaEnvelope, FaPencilAlt, FaPlus,FaTimes,FaColumns, FaChartBar } from 'react-icons/fa';
import { deleteColumn, deleteGroup, deleteRow, deleteSubRow, fetchFrames } from '../../features/workspaces/workspaceActions';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationPopup from '../common/ConfirmationPopup';
import { toast } from 'react-toastify';
import { SketchPicker } from 'react-color';
import { Modal, Button } from 'react-bootstrap';
import { BsX } from 'react-icons/bs';
import AutomationModal from '../common/AutomationModal';
import DraggableSubColumn from '../common/DraggableSubColumn';
import { format } from 'date-fns';
import TaskSelectionStripe from '../common/TaskSelectionStripe';
import TaskSelectionStripeSubrow from '../common/TaskSelectionStripeSubrow';
import DraggableColumn from '../common/DraggableColumn';
import KanbanViewBoard from '../common/KanbanViewBoard';
import { FaCircleChevronDown } from 'react-icons/fa6';
import FileUpload from '../common/FileUpload';
import TaskDetailsOffcanvas from '../common/TaskDetailsOffcanvas';
import GanttBoard from '../common/GanttBoard';
import LinkHandler from '../common/LinkHandler';
import EmailHandler from '../common/EmailHandler';
import PhoneNumberHandler from '../common/PhoneNumberHandler';
import LocationHandler from '../common/LocationHandler';
import ChartLayout from '../common/ChartLayout';
import LibraryHandler from '../common/LibraryHandler';
import NotesHandler from '../common/NotesHandler';


// const Loader = () => (
//   <div className="loader">
//     <img  src="/hotwash.png"

// alt="Hotwash"
// height={45}
// width={45}
//  className="loader-image" />
//   </div>
// );
const Loader = () => (
  <div className="loader">
    <div className="spinner"></div>
  </div>
);

const ColumnOptionsMenu = React.forwardRef(({ onSelect, onClose, style }, ref) => {
  const handleOptionSelect = (type, name) => {
    onSelect(type, name);
    onClose(); // Close the menu after selection
  };

  return (
    <div className="column-options-menu" ref={ref} >
      <div className="column-options-content">
        <div className="column-option" onClick={() => handleOptionSelect('date', 'date')}>
          <BiCalendar size={20} />
          <span>Date</span>
        </div>

        <div className="column-option" onClick={() => handleOptionSelect('timeline', 'timeline')}>
          <BiTime size={20} />
          <span>Timeline</span>
        </div>
        <div className="column-option" onClick={() => handleOptionSelect('status', 'dropdown')}>
          <BiCheckbox size={20} />
          <span>Status</span>
        </div>
        <div className="column-option" onClick={() => handleOptionSelect('text', 'text')}>
          <BiText size={20} />
          <span>Text</span>
        </div>
        <div className="column-option" onClick={() => handleOptionSelect('people', 'users')}>
          <BiUser size={20} />
          <span>People</span>
        </div>
        <div className="column-option" onClick={() => handleOptionSelect('Files', 'file')}>
          <BiFile size={20} />
          <span>File</span>
        </div>
        <div className="column-option" onClick={() => handleOptionSelect('Number', 'integer')}>
          <BiDialpad size={20} />
          <span>Number</span>
        </div>
        <div className="column-option" onClick={() => handleOptionSelect('Dropdown', 'multiDropdown')}>
          <FaCircleChevronDown size={20} />
          <span>Dropdown</span>
        </div>
        <div className="column-option" onClick={() => handleOptionSelect('Link', 'hyperlink')}>
      <BiLink size={20} />
      <span>Link</span>
    </div>
    <div className="column-option" onClick={() => handleOptionSelect('Location', 'location')}>
      <AiOutlineEnvironment size={20} />
      <span>Location</span>
    </div>
    <div className="column-option" onClick={() => handleOptionSelect('Phone', 'phone')}>
      <AiOutlinePhone size={20} />
      <span>Phone</span>
    </div>
    <div className="column-option" onClick={() => handleOptionSelect('Email', 'email')}>
      <AiOutlineMail size={20} />
      <span>Email</span>
    </div>
    {/* <div className="column-option" onClick={() => handleOptionSelect('Library', 'library')}>
      <BiBook size={20} />
      <span>Library</span>
    </div> */}
    <div className="column-option" onClick={() => handleOptionSelect('Notes', 'notes')}>
      <BiNote size={20} />
      <span>Notes</span>
    </div>
        {/* <div className="column-option" onClick={() => handleOptionSelect('email', 'email')}>
          <FaEnvelope size={20} />
          <span>Email</span>
        </div> */}

      </div>
      <style jsx>{`
        .column-options-menu {
          position: absolute;
          width: 280px;
          background: white;
          border: 1px solid #ddd;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          z-index: 100;
          padding: 0;
          margin: 0;
          list-style: none;
          right:0px !important;
        }

        .column-options-content {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 10px;
          padding: 10px;
        }

        .column-option {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 8px 12px;
        }

        .column-option:hover {
          background-color: #f0f0f0;
        }

        .column-option span {
          margin-left: 8px;
        }
      `}</style>
    </div>
  );
});

const ColumnOptionsMenuSubrow = React.forwardRef(({ onSelect, onClose, style }, ref) => {
  const handleOptionSelect = (type, name) => {
    onSelect(type, name);
    onClose(); // Close the menu after selection
  };

  return (
    <div className="column-options-menu" ref={ref} >
      <div className="column-options-content">
        <div className="column-option" onClick={() => handleOptionSelect('date', 'date')}>
          <BiCalendar size={20} />
          <span>Date</span>
        </div>
        <div className="column-option" onClick={() => handleOptionSelect('timeline', 'timeline')}>
          <BiTime size={20} />
          <span>Timeline</span>
        </div>
        <div className="column-option" onClick={() => handleOptionSelect('status', 'dropdown')}>
          <BiCheckbox size={20} />
          <span>Status</span>
        </div>
        <div className="column-option" onClick={() => handleOptionSelect('text', 'text')}>
          <BiText size={20} />
          <span>Text</span>
        </div>
        <div className="column-option" onClick={() => handleOptionSelect('people', 'users')}>
          <BiUser size={20} />
          <span>People</span>
        </div>
        <div className="column-option" onClick={() => handleOptionSelect('Files', 'file')}>
          <BiFile size={20} />
          <span>File</span>
        </div>
        <div className="column-option" onClick={() => handleOptionSelect('Number', 'integer')}>
          <BiDialpad size={20} />
          <span>Number</span>
        </div>
        <div className="column-option" onClick={() => handleOptionSelect('Dropdown', 'multiDropdown')}>
          <FaCircleChevronDown size={20} />
          <span>Dropdown</span>
        </div>
        <div className="column-option" onClick={() => handleOptionSelect('Link', 'hyperlink')}>
      <BiLink size={20} />
      <span>Link</span>
    </div>
    <div className="column-option" onClick={() => handleOptionSelect('Location', 'location')}>
      <AiOutlineEnvironment size={20} />
      <span>Location</span>
    </div>
    <div className="column-option" onClick={() => handleOptionSelect('Phone', 'phone')}>
      <AiOutlinePhone size={20} />
      <span>Phone</span>
    </div>
    <div className="column-option" onClick={() => handleOptionSelect('Email', 'email')}>
      <AiOutlineMail size={20} />
      <span>Email</span>
    </div>
    {/* <div className="column-option" onClick={() => handleOptionSelect('Library', 'library')}>
      <BiBook size={20} />
      <span>Library</span>
    </div> */}
    <div className="column-option" onClick={() => handleOptionSelect('Notes', 'notes')}>
      <BiNote size={20} />
      <span>Notes</span>
    </div>
    
      </div>
      <style jsx>{`
        .column-options-menu {
          position: absolute;
          width: 280px;
          background: white;
          border: 1px solid #ddd;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          z-index: 100;
          padding: 0;
          margin: 0;
          right:0px !important;
          list-style: none;
        }

        .column-options-content {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 10px;
          padding: 10px;
        }

        .column-option {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 8px 12px;
        }

        .column-option:hover {
          background-color: #f0f0f0;
        }

        .column-option span {
          margin-left: 8px;
        }
      `}</style>
    </div>
  );
});
const SheetPage = (user) => {
  const { frameId, sheetId } = useParams();
  const [groups, setGroups] = useState([]);
  const [tableHeaders, setTableHeaders] = useState();
  const [tableHeadersSubrow, setTableHeadersSubrow] = useState();

  const [rows, setRows] = useState([]);
  const [editableGroupId, setEditableGroupId] = useState(null);
  const [newGroupName, setNewGroupName] = useState('');
  // const datePickerRef = useRef(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuVisibleSubrow, setMenuVisibleSubrow] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false); // State to control the drawer
  /*eslint-disable*/
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [menuPositionSubrow, setMenuPositionSubrow] = useState({ top: 0, left: 0 });

  /*eslint-enable*/
  const menuRef = useRef(null);
  const [selectedCell, setSelectedCell] = useState(null);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [showDateRangePickerSubrow, setShowDateRangePickerSubrow] = useState(false);

  const [dateRangePickerPosition, setDateRangePickerPosition] = useState({ top: 0, left: 0 });
  const [userMenu, setUserMenu] = useState({ visible: false, position: {}, groupIndex: null, rowIndex: null, columnIndex: null,row_id:null,column_type:null,cell_data: [] });
  const [userMenuSubrow, setUserMenuSubrow] = useState({ visible: false, position: {}, groupIndex: null, rowIndex: null, columnIndex: null,row_id:null,column_type:null });
  const [offcanvasState, setOffcanvasState] = useState(false);

  const [selectedCard, setSelectedCard] = useState(null);

  const [taskDetails, setTaskDetails] = useState(null);
  const [user_data, setUserData] = useState([]);
  const userMenuRef = useRef(null);
  const groupMenuRef = useRef(null);
  // const columnMenuRef = useRef(null);
  const rowMenuRef = useRef(null);
  
  const layoutRef = useRef(null);

  const dropdownMenuRef = useRef(null);
  const multiselectDropdownMenuRef = useRef(null);

  const [isEditMode, setIsEditMode] = useState(false);
  const [isMultiSelectEditMode, setIsMultiSelectEditMode] = useState(false);
  const [loading, setLoading] = useState(true); // Add this state
  const [visibleSubTableRowId, setVisibleSubTableRowId] = useState([]);

  const [colorPickerVisible, setColorPickerVisible] = useState(null);
  const status = useSelector((state) => state.frames.status);
const dispatch=useDispatch();
  const timelineRef = useRef(null);
  const DeptID = localStorage.getItem('HDept');
  const [deleteingGroup, setDeleteingGroup] = useState(null);
  const [showGroupConfirmation, setShowGroupConfirmation] = useState(false);
  const [deleteingRow, setDeleteingRow] = useState(null);
  const [deleteingSubrow, setDeleteingSubrow] = useState(null);

  const [showRowConfirmation, setShowRowConfirmation] = useState(false);
  // const [openMenuColumnId, setOpenMenuColumnId] = useState(null);
  // const [isColumnMenuVisible, setIsColumnMenuVisible] = useState(false);
  // const [GroupID, setGroupId] = useState(false);
  const [showRowConfirmationSubrow, setShowRowConfirmationSubrow] = useState(false);

  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  });
  const { sheetName } = useParams(); // Extract sheetName from the URL
  const [valuedropdown,setvaluedropdown] = useState();
  const [dropdownMenu, setDropdownMenu] = useState({ visible: false, position: {}, groupIndex: null, rowIndex: null, columnIndex: null, row_id: null, column_type: null, ID: null, cell_data: null });
  const [dropdownMenuSubrow, setDropdownMenuSubrow] = useState({ visible: false, position: {}, groupIndex: null, rowIndex: null, columnIndex: null, row_id: null, column_type: null, ID: null, cell_data: null });
  const [valuedropdownSubrow,setvaluedropdownSubrow] = useState();
  const [searchQuery, setSearchQuery] = useState('');

  const [statusOptions, setStatusOptions] = useState([]);
  const [statusOptionsSubrow, setStatusOptionsSubrow] = useState([]);
  const [showColumnConfirmation, setShowColumnConfirmation] = useState(false);
  const [groupstatusOptions, setGroupStatusOptions] = useState([]); // Assuming options come from API
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [dropdownOptions, setDropDownOptions] = useState([]);
  const [multivaluedropdown,setMultivaluedropdown] = useState();
  const [multivaluedropdownSubrow,setMultivaluedropdownSubrow] = useState();
  const [dropdownOptionsSubrow, setDropDownOptionsSubrow] = useState([]);
  const [currentGroupFilter, setCurrentGroupFilter] = useState(null); // State to track grouping filter

  const [openMenuGroupId, setOpenMenuGroupId] = useState(null);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [openMenuRowId, setOpenMenuRowId] = useState(null);
  const [isRowMenuVisible, setIsRowMenuVisible] = useState(false);
  const [editedOptions, setEditedOptions] = useState([]);
  const [newLabel, setNewLabel] = useState({ id: 0, status_text: '', status_color: '#ff0000' });
  const [editedOptionsSubrow, setEditedOptionsSubrow] = useState([]);
  const [newLabelSubrow, setNewLabelSubrow] = useState({ id: 0, status_text: '', status_color: '#ff0000' });

  const [newMultiSelectLabel, setMultiSelectNewLabel] = useState({ id: 0, status_text: '' });
  const [editedMultiSelectOptions, setEditedMultiSelectOptions] = useState([]);
  const [editedMultiSelectOptionsSubrow, setEditedMultiSelectOptionsSubrow] = useState([]);
  const [isEditable, setIsEditable] = useState(false); // Default to false
  const [canDelete, setCanDelete] = useState(false); // Default to false

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsSubrow, setSelectedOptionsSubrow] = useState([]);
  const [columnWidths, setColumnWidths] = useState({});

  const [showNewLabelInput, setShowNewLabelInput] = useState(false);
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const staticPath = `${process.env.REACT_APP_TOS_PATH}/includes/Pages/upload/`;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dropdownOpen,setDropdownOpen] = useState(false);
  const [multiselectDropdownMenu, setMultiselectDropdownMenu] = useState({ visible: false, position: {}, groupIndex: null, rowIndex: null, columnIndex: null, row_id: null, column_type: null, ID: null, cell_data: null });
const[showMultiSelectNewLabelInput,setShowMultiSelectNewLabelInput]=useState(false)
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [multiselectDropdownMenuSubrow, setMultiselectDropdownMenuSubrow] = useState({ visible: false, position: {}, groupIndex: null, rowIndex: null, columnIndex: null, row_id: null, column_type: null, ID: null, cell_data: null });
  const tableRef = useRef(null);
  const [subColumnWidths, setSubColumnWidths] = useState({});
  const [hoveredSubRowId, setHoveredSubRowId] = useState(null);
  const [openMenuSubRowId, setOpenMenuSubRowId] = useState(null);
  const [isSubRowMenuVisible, setIsSubRowMenuVisible] = useState(false);
  const currentYear = new Date().getFullYear();
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [selectedsubTasks, setSelectedsubTasks] = useState([]);
  const [currentID, setCurrentID] = useState(null);
  const [currentGroupId,setcurrentGroupId]=useState()
  const [emailInputVisible, setEmailInputVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const emailInputRef = useRef(null);
  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [deletingColumn, setDeletingColumn] = useState(null);
  const [optionsMenuVisible, setOptionsMenuVisible] = useState(false);
  const [groupOptionsVisible, setGroupOptionsVisible] = useState(false);
  const [layoutOptionsVisible, setLayoutOptionsVisible] = useState(false);
  // const [selectedGroupOption, setSelectedGroupOption] = useState(null);
  const [selectedLayoutOption, setSelectedLayoutOption] = useState('Table');
  const [isAtTop, setIsAtTop] = useState(true);
  const sheetContainerRef = useRef(null); // Reference to the sheet container

    /*eslint-disable*/
  const [automationModalVisible, setAutomationModalVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to control menu visibility
  const [selectedOptionsByRow, setSelectedOptionsByRow] = useState({});
const [selectedOptionsBySubRow, setSelectedOptionsBySubRow] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const navigate = useNavigate();
  const timelineSubrowRef = useRef(null);
  const [showGantt, setShowGantt] = useState(false); // State to control Gantt visibility
      /*eslint-enable*/
  const [timelineColumns, setTimelineColumns] = useState([]); // Store timeline columns
  const [selectedColumnId, setSelectedColumnId] = useState(null); // Selected timeline column
  const dropdownRef = useRef(null); // To reference the dropdown
  const [selectedRowsData, setSelectedRowsData] = useState([]); // Full data of selected rows

const globalUserId =user?.user?.userid
const toggleOptionsMenu = () => {
  setOptionsMenuVisible(!optionsMenuVisible);
  setGroupOptionsVisible(false);
  setLayoutOptionsVisible(false);
};
useEffect(() => {
  if (!userMenu.visible) {
    setSearchQuery(''); // Reset searchQuery to empty string when userMenu is hidden
  }
}, [userMenu.visible]);

const handleCardClick = async (id) => {
  setSelectedCard(id); // Store selected card data
  setShowDrawer(true);   // Open the side drawer

  try {
    // Fetch details from the API using card.id
    const response = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/kanban-task/${id}/`);
    const data = await response.json();
    setTaskDetails(data); // Store fetched task details
    console.log("taskDetailstaskDetails",data)
  } catch (error) {
    console.error("Error fetching task details:", error);
  }
};
const toggleGroupOptions = () => {
  setGroupOptionsVisible(!groupOptionsVisible);
  setLayoutOptionsVisible(false);
};
const toggleMenu = () => {
  setIsMenuOpen(!isMenuOpen); // Toggle the menu open/close
};
const toggleMenuStatus = () => {
  setDropdownOpen(!dropdownOpen); // Toggle the menu open/close
};
const handleLayoutChange = (layout) => {
  setSelectedLayoutOption(layout); // Change the layout when an option is clicked
  if(layout ==='gantt'){
    fetchTimelineColumns();
    setShowGantt(true);
  }
  setIsMenuOpen(false); // Close the menu after selection
};
// const toggleLayoutOptions = () => {
//   setLayoutOptionsVisible(!layoutOptionsVisible);
//   setGroupOptionsVisible(false);
// };
const handleToggleScroll = () => {
  if (!sheetContainerRef.current) return;

  if (isAtTop) {
    // Scroll to the bottom of the container
    sheetContainerRef.current.scrollTo({
      top: sheetContainerRef.current.scrollHeight,
      behavior: 'smooth',
    });
  } else {
    // Scroll to the top of the container
    sheetContainerRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
  // Toggle the state
  setIsAtTop(!isAtTop);
};

const handleScroll = () => {
  if (!sheetContainerRef.current) return;

  const { scrollTop, scrollHeight, clientHeight } = sheetContainerRef.current;

  // Check if the user is at the top or bottom
  if (scrollTop === 0) {
    setIsAtTop(true);
  } else if (scrollTop + clientHeight >= scrollHeight) {
    setIsAtTop(false);
  }
};

useEffect(() => {
  const container = sheetContainerRef.current;

  if (container) {
    container.addEventListener('scroll', handleScroll);
    // Clean up event listener on component unmount
    return () => container.removeEventListener('scroll', handleScroll);
  }
}, []);

const selectGroupOption = (option) => {
  // setSelectedGroupOption(option);
  setGroupOptionsVisible(false);
  setOptionsMenuVisible(false)
};
// const toggleLayout = () => {
//   setIsMenuOpen((prev) => !prev); };


const selectLayoutOption = (option) => {

  setSelectedLayoutOption(option);
  setLayoutOptionsVisible(false);
  setOptionsMenuVisible(false)
 
};
const fetchTimelineColumns = async () => {
  try {
    
    const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/timeline-columns/${sheetId}`);
    const columns = response?.data?.data;
    setTimelineColumns(columns);
    // Set the first column id as the default selected column
    if (columns.length > 0) {
      setSelectedColumnId(columns[0].id);
    }
  } catch (error) {
    console.error('Error fetching timeline columns', error);
  }
};

// Button click handler to fetch timeline columns and show Gantt board
// const handleGanttButtonClick = () => {
//   fetchTimelineColumns();
//   setSelectedLayoutOption('gantboard')
//   setShowGantt(true); 
// };
useEffect(() => {
  const handleResize = () => {
    setWindowWidth(window.innerWidth);

    // Optionally reset the table width or apply other changes here
    if (tableRef.current) {
      tableRef.current.style.width = ""; // Removes the width style if it's applied
    }
  };

  // Add event listener for window resize
  window.addEventListener("resize", handleResize);

  // Clean up the event listener on component unmount
  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []); // Empty dependency array so it only runs once

    /*eslint-disable*/
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emailInputVisible && emailInputRef.current && !emailInputRef.current.contains(event.target)) {
        handleEmailSave();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [emailInputVisible]);
    /*eslint-enable*/
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleEmailClick = (ID) => {
    setEmailInputVisible(true);
    setCurrentID(ID); 
  };
  
  const handleEmailSave = () => {
    if (isValidEmail(email)) {
      setEmailError('');
      setEmailInputVisible(false);
      // Call API or handle the email save logic here
      addCellData(email, currentID); // Use currentID here
    } else {
      setEmailError('Invalid email format');
    }
  };
  
  const handleEmailRemove = () => {
    setEmail('');
    // Call API or handle the email remove logic here
    addCellData('', currentID); // Use currentID here
  };
  const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

  // const handleCheckboxChange = (taskId, groupId) => {
  //   setcurrentGroupId(groupId)
  // // const handleCheckboxChange = (taskId) => {
  //   setSelectedTasks((prevSelected) =>
  //     prevSelected.includes(taskId)
  //       ? prevSelected.filter((id) => id !== taskId)
  //       : [...prevSelected, taskId]
  //   );
  // };
  const handleCheckboxChange = (taskId, groupId, rowData) => {
    setcurrentGroupId(groupId);
  
    setSelectedTasks((prevSelected) => {
      const updatedTasks = prevSelected.includes(taskId)
        ? prevSelected.filter((id) => id !== taskId)
        : [...prevSelected, taskId];
  
      // Update selectedRowsData
      setSelectedRowsData((prevData) => {
        if (updatedTasks.includes(taskId)) {
          // Add row data if task is selected
          return [...prevData, rowData];
        } else {
          // Remove row data if task is deselected
          return prevData.filter((row) => row.id !== taskId);
        }
      });
  
      return updatedTasks;
    });
  };
  
  const handleSelectAllInGroup = (groupId, isSelected) => {
    const groupRows = groups.find(group => group.id === groupId)?.rows.slice(1) || []; // Get all rows in the group (except header)
    const groupRowIds = groupRows.map(row => row.id); // Get row IDs
  
    if (isSelected) {
      // If selected, add all row IDs to the selectedTasks list
      setSelectedTasks(prevSelected => {
        const updatedTasks = [...new Set([...prevSelected, ...groupRowIds])];
  
        // Add all row data to selectedRowsData
        setSelectedRowsData(prevData => {
          const newData = groupRows.filter(row => !prevData.some(existingRow => existingRow.id === row.id));
          return [...prevData, ...newData];
        });
  
        return updatedTasks;
      });
    } else {
      // If deselected, remove all row IDs from the selectedTasks list
      setSelectedTasks(prevSelected => prevSelected.filter(id => !groupRowIds.includes(id)));
  
      // Remove all row data from selectedRowsData
      setSelectedRowsData(prevData => prevData.filter(row => !groupRowIds.includes(row.id)));
    }
  };
  
  const handleClose = () => {
    setSelectedRowsData([])
    setSelectedTasks([])
  };
  const handleCheckboxChangeSubrow = (subtaskId, groupId) => {
    setcurrentGroupId(groupId)
  // const handleCheckboxChange = (taskId) => {
    setSelectedsubTasks((prevSelected) =>
      prevSelected.includes(subtaskId)
        ? prevSelected.filter((id) => id !== subtaskId)
        : [...prevSelected, subtaskId]
    );
  };
  const handleSelectAllInSubRow = (rowId, isSelected) => {
    const currentRow = groups.flatMap(group => group.rows).find(row => row.id === rowId); // Find the specific row
    const subRows = currentRow.sub_data?.slice(1) || []; // Get all sub-rows of this row
    const subRowIds = subRows.map(subRow => subRow.id); // Get the sub-row IDs
    
    if (isSelected) {
      // If selected, add all sub-row IDs of this row to the selectedsubTasks list
      setSelectedsubTasks(prevSelected => [...new Set([...prevSelected, ...subRowIds])]);
    } else {
      // If deselected, remove all sub-row IDs of this row from the selectedsubTasks list
      setSelectedsubTasks(prevSelected => prevSelected.filter(id => !subRowIds.includes(id)));
    }
  };
      /*eslint-disable*/

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emailInputVisible && emailInputRef.current && !emailInputRef.current.contains(event.target)) {
        handleEmailSave(); // Save on outside click
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [emailInputVisible]);
      /*eslint-enable*/
  // const handleShow= () => setShow(false);
  const handleShow = () => setShowDrawer(false);
  const handleCloseSubrow = () => {
    setSelectedsubTasks([]); // Clear selection when close is clicked
  };
  const subRowMenuRef = useRef(null);
  const handleAddSubRowMenuClick = (subRowId) => {
    setOpenMenuSubRowId(subRowId);
    setIsSubRowMenuVisible(true);
  };
  
  const handleDeleteSubRow = (subRowId) => {
    // Call your API to delete the sub row here
    setDeleteingSubrow(subRowId); // Store the frame ID to delete
    setShowRowConfirmationSubrow(true); // Show confirmation dialog
    setIsSubRowMenuVisible(false);
  };
  
  
  
  const handleConfirmRowDeleteSubrow = () => {
    deletRowSubmitSubrow(deleteingSubrow); // Call delete function with stored frame ID
    setShowRowConfirmationSubrow(false); // Close confirmation dialog
  };
  const deletColumnSubmit = async (id) => {
    try {
        await dispatch(deleteColumn(id));
        toast.success('Column deleted successfully!');
        fetchDataRow();
    } catch (error) {
        toast.error('Error deleting column');
    }
};

const handleConfirmColumnDelete = () => {
    deletColumnSubmit(deletingColumn); // Call delete function with stored column ID
    setShowColumnConfirmation(false); // Close confirmation dialog
};

const handleCancelColumnDelete = () => {
    setShowColumnConfirmation(false);
};
  const handleSubColumnResize = (id, newWidth) => {
    setSubColumnWidths((prevWidths) => ({
      ...prevWidths,
      [id]: newWidth,
    }));
  };
  useEffect(() => {
    if (tableRef.current) {
      const totalWidth = Array.from(tableRef.current.querySelectorAll('th')).reduce((width, th) => width + th.offsetWidth, 0);
      tableRef.current.style.width = `${totalWidth}px`;
    }

  }, [groups]);
  // Initialize column widths after groups data is available
  // useEffect(() => {
  //   if (groups && groups.length > 0) {
  //     const initialWidths = groups[0]?.rows[0]?.columns.reduce((acc, col) => {
  //       acc[col.id] = col.column_width || 100;
  //       return acc;
  //     }, {});
  //     setColumnWidths(initialWidths);
  //   }
  // }, [groups]); // Dependency array ensures useEffect runs when `groups` changes
  useEffect(() => {
    if (groups && groups.length > 0) {
      const initialWidths = groups.reduce((acc, group) => {
        const firstRowColumns = group.rows[0]?.columns || [];
        firstRowColumns.forEach((col) => {
          acc[col.id] = col.column_width || 100; // Default to 100 if column_width is undefined
        });
        return acc;
      }, {});
  
      setColumnWidths(initialWidths);
    }
  }, [groups]);
  console.log("columnWidths",columnWidths)
  useEffect(() => {
    if (groups && groups.length > 0) {
      const initialWidths = groups[0]?.rows[1]?.sub_data[0]?.columns.reduce((acc, col) => {
        acc[col.id] = col.column_width || 200;
        return acc;
      }, {});
      setSubColumnWidths(initialWidths);
    }
  }, [groups]);
  

  // Render loading indicator if necessary
  
  // const [dateRange, setDateRange] = useState({
  //   startDate: new Date(),
  //   endDate: new Date(),
  //   key: 'selection'
  // });
  // Update the useEffect in SheetPage to adjust menu visibility and positioning
/*eslint-disable*/

useEffect(() => {
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
//console.log("ioioio",menuVisible)     
 setMenuVisible(false);
 
 setShowDateRangePicker(false)
 setShowDateRangePickerSubrow(false)
      setShowDateRangePicker(false);
      setShowDateRangePickerSubrow(false);

      

    }
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsMenuOpen(false);
      // setDropdownOpen(false)
    }
  };
  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, [menuRef,showDateRangePicker,showDateRangePickerSubrow,isMenuOpen]);

useEffect(() => {
  // Fetch status options from the API
  if(valuedropdown !== null && valuedropdown !== undefined
  ){
    fetchStatusOptions();
  }
  if(valuedropdownSubrow !== null && valuedropdownSubrow !== undefined
  ){
    fetchStatusOptionsSubrow();
  }
  if(multivaluedropdown !== null && multivaluedropdown !== undefined
  ){
    fetchDropdownOptions();
  }
  
  if(multivaluedropdownSubrow !== null && multivaluedropdownSubrow !== undefined
  ){
    fetchDropdownOptionsSubrow();
  }
}, [valuedropdown,multivaluedropdown,valuedropdownSubrow,multivaluedropdownSubrow]);

const fetchStatusOptions = async () => {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/dropdown-status/main/${valuedropdown}/`);
      if (response) {
          setStatusOptions(response.data.data);
      }
  } catch (error) {
      //console.error('Error fetching status options:', error);
  }
};
const fetchStatusOptionsSubrow = async () => {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/dropdown-status/sub/${valuedropdownSubrow}/`);
      if (response) {
        setStatusOptionsSubrow(response.data.data);
      }
  } catch (error) {
      //console.error('Error fetching status options:', error);
  }
};
const fetchDropdownOptions = async () => {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/multi-dropdowns/main/${multivaluedropdown}/`);
      if (response) {
          setDropDownOptions(response.data.data);
      }
  } catch (error) {
      //console.error('Error fetching status options:', error);
  }
};
const fetchDropdownOptionsSubrow = async () => {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/multi-dropdowns/sub/${multivaluedropdownSubrow}/`);
      if (response) {
          setDropDownOptionsSubrow(response.data.data);
      }
  } catch (error) {
      //console.error('Error fetching status options:', error);
  }
};
useEffect(() => {
  const handleClickOutside = (event) => {
    // If clicked outside of both the main row and subrow date pickers
    if (
      timelineRef.current &&
      !timelineRef.current.contains(event.target) &&
      timelineSubrowRef.current &&
      !timelineSubrowRef.current.contains(event.target)
    ) {
      setShowDateRangePicker(false);
      setShowDateRangePickerSubrow(false);
    }
  };

  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, [timelineRef, timelineSubrowRef]);

useEffect(() => {
  const handleClickOutside = (event) => {
   
    
    if (dropdownMenuRef.current && !dropdownMenuRef.current.contains(event.target)) {
      setDropdownMenu(false);
      setIsEditMode(false)
      setShowNewLabelInput(false)
      setDropdownMenuSubrow(false);


    }
    if (multiselectDropdownMenuRef.current && !multiselectDropdownMenuRef.current.contains(event.target)) {
      setMultiselectDropdownMenu(false);
      setMultiselectDropdownMenuSubrow(false);

      setIsMultiSelectEditMode(false)
      setShowMultiSelectNewLabelInput(false)
    }
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuVisible(false);
      setMenuVisibleSubrow(false);


    }
    if (layoutRef.current && !layoutRef.current.contains(event.target)) {
      setOptionsMenuVisible(false);
    }
    if (rowMenuRef.current && !rowMenuRef.current.contains(event.target)) {
      setIsRowMenuVisible(false);
    }
    
    if (groupMenuRef.current && !groupMenuRef.current.contains(event.target)) {
      setIsMenuVisible(false);
    }
    if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
      setUserMenu({ ...userMenu, visible: false });
      setUserMenuSubrow({ ...userMenu, visible: false });

    }
    
    
  };

  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, [menuRef,optionsMenuVisible, userMenuRef, userMenu,userMenuSubrow,isMenuVisible,menuVisible,dropdownMenu,dropdownMenuSubrow]);
/*eslint-enable*/

// //console.log("dateRange",user_data)
const deletGroupSubmit = async (id) => {
  try {
    await dispatch(deleteGroup(id));
    // dispatch(fetchFrames());
    toast.success('Group deleted successfully!');

    fetchDataRow();
  } catch (error) {
    toast.error('Error deleting Group');

    //console.error(`Error deleting frame with ID ${id}:`, error);
  }
};
const handleDeleteGroup = (id) => {
  setDeleteingGroup(id); // Store the frame ID to delete
  setShowGroupConfirmation(true); // Show confirmation dialog
};


const handleConfirmGroupDelete = () => {
  deletGroupSubmit(deleteingGroup); // Call delete function with stored frame ID
  setShowGroupConfirmation(false); // Close confirmation dialog
};
const handleApplyChanges = () => {
  const updatedOptions = editedOptions.map(option => ({
    ...option,
    sheet_id: sheetId,
    column_id: valuedropdown,
    status_type:"main",
    id:option?.id
  }));

  if (newLabel.status_text) {
    updatedOptions.push({ ...newLabel, sheet_id: sheetId , column_id: valuedropdown,status_type:"main"});
  }

  fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/add-update-status/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updatedOptions),
  })
    .then(response => response.json())
    .then(data => {
      //console.log(data);
      fetchStatusOptions()
      setIsEditMode(false);
      setEditedOptions([]);
      setNewLabel({ id: 0, status_text: '', status_color: '#ff0000' });
      setShowNewLabelInput(false);
      fetchDataRow()
    })
    .catch(error => console.error('Error:', error));
};

const handleApplyChangesSubrow = () => {
  const updatedOptions = editedOptionsSubrow.map(option => ({
    ...option,
    sheet_id: sheetId,
    column_id: valuedropdownSubrow,
    status_type:"sub",
    id:option?.id

  }));

  if (newLabel.status_text) {
    updatedOptions.push({ ...newLabel, sheet_id: sheetId , column_id: valuedropdownSubrow,status_type:"sub"});
  }

  fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/add-update-status/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updatedOptions),
  })
    .then(response => response.json())
    .then(data => {
      //console.log(data);
      fetchStatusOptionsSubrow()
      setIsEditMode(false);
      setEditedOptions([]);
      setNewLabelSubrow({ id: 0, status_text: '', status_color: '#ff0000' });
      setShowNewLabelInput(false);
      fetchDataRow()

    })
    .catch(error => console.error('Error:', error));
};
const handleEditClick = () => {
  setIsEditMode(!isEditMode);
};
const handleMultiSelectEditClick = () => {
  setIsMultiSelectEditMode(!isEditMode);
};
const handleColorChange = (color, id) => {
  if (id === 0) {
    setNewLabel({ ...newLabel, status_color: color.hex });
  } else {
    const updatedOptions = statusOptions.map(option =>
      option.id === id ? { ...option, status_color: color.hex } : option
    );
    setStatusOptions(updatedOptions);

    if (!editedOptions.find(option => option.id === id)) {
      setEditedOptions([...editedOptions, { ...statusOptions.find(option => option.id === id), status_color: color.hex }]);
    } else {
      setEditedOptions(
        editedOptions.map(option =>
          option.id === id ? { ...option, status_color: color.hex } : option
        )
      );
    }
  }
};

const handleInputChange = (e, id) => {
  if (id === 0) {
    setNewLabel({ ...newLabel, status_text: e.target.value });
  } else {
    const updatedOptions = statusOptions.map(option =>
      option.id === id ? { ...option, status_text: e.target.value } : option
    );
    setStatusOptions(updatedOptions);

    if (!editedOptions.find(option => option.id === id)) {
      setEditedOptions([...editedOptions, { ...statusOptions.find(option => option.id === id), status_text: e.target.value }]);
    } else {
      setEditedOptions(
        editedOptions.map(option =>
          option.id === id ? { ...option, status_text: e.target.value } : option
        )
      );
    }
  }
};
const handleColorChangeSubrow = (color, id) => {
  if (id === 0) {
    setNewLabelSubrow({ ...newLabelSubrow, status_color: color.hex });
  } else {
    const updatedOptions = statusOptionsSubrow.map(option =>
      option.id === id ? { ...option, status_color: color.hex } : option
    );
    setStatusOptionsSubrow(updatedOptions);

    if (!editedOptionsSubrow.find(option => option.id === id)) {
      setEditedOptionsSubrow([...editedOptionsSubrow, { ...statusOptionsSubrow.find(option => option.id === id), status_color: color.hex }]);
    } else {
      setEditedOptionsSubrow(
        editedOptionsSubrow.map(option =>
          option.id === id ? { ...option, status_color: color.hex } : option
        )
      );
    }
  }
};

const handleInputChangeSubrow = (e, id) => {
  if (id === 0) {
    setNewLabelSubrow({ ...newLabelSubrow, status_text: e.target.value });
  } else {
    const updatedOptions = statusOptionsSubrow?.map(option =>
      option.id === id ? { ...option, status_text: e.target.value } : option
    );
    setStatusOptionsSubrow(updatedOptions);

    if (!editedOptionsSubrow.find(option => option?.id === id)) {
      setEditedOptionsSubrow([...editedOptionsSubrow, { ...statusOptionsSubrow.find(option => option?.id === id), status_text: e.target.value }]);
    } else {
      setEditedOptionsSubrow(
        editedOptionsSubrow.map(option =>
          option.id === id ? { ...option, status_text: e.target.value } : option
        )
      );
    }
  }
};
const handleMultiSelectInputChange = (e, id) => {
  if (id === 0) {
    setMultiSelectNewLabel({ ...newMultiSelectLabel, status_text: e.target.value });
  } else {
    const updatedOptions = dropdownOptions.map(option =>
      option.id === id ? { ...option, status_text: e.target.value } : option
    );
    setStatusOptions(updatedOptions);

    if (!editedMultiSelectOptions.find(option => option.id === id)) {
      setEditedMultiSelectOptions([...editedMultiSelectOptions, { ...dropdownOptions.find(option => option.id === id), status_text: e.target.value }]);
    } else {
      setEditedMultiSelectOptions(
        editedMultiSelectOptions.map(option =>
          option.id === id ? { ...option, status_text: e.target.value } : option
        )
      );
    }
  }
};
const handleMultiSelectInputChangeSubrow = (e, id) => {
  if (id === 0) {
    setMultiSelectNewLabel({ ...newMultiSelectLabel, status_text: e.target.value });
  } else {
    const updatedOptions = dropdownOptionsSubrow.map(option =>
      option.id === id ? { ...option, status_text: e.target.value } : option
    );
    setStatusOptionsSubrow(updatedOptions);

    if (!editedMultiSelectOptionsSubrow.find(option => option.id === id)) {
      setEditedMultiSelectOptionsSubrow([...editedMultiSelectOptionsSubrow, { ...dropdownOptionsSubrow.find(option => option.id === id), status_text: e.target.value }]);
    } else {
      setEditedMultiSelectOptions(
        editedMultiSelectOptionsSubrow.map(option =>
          option.id === id ? { ...option, status_text: e.target.value } : option
        )
      );
    }
  }
};
const handleAddNewLabelClick = () => {
  setShowNewLabelInput(true);
};
const handleAddMultiSelectNewLabelClick = () => {
  setShowMultiSelectNewLabelInput(true);
};
const handleCancelGroupDelete = () => {
  setShowGroupConfirmation(false);
};

const deletRowSubmit = async (id) => {
  try {
    await dispatch(deleteRow(id));
    // dispatch(fetchFrames());
    toast.success('Row deleted successfully!');
    setSelectedRowsData([])
    setSelectedTasks([])

    fetchDataRow();

    // window.location.reload()
  } catch (error) {
    toast.error('Error deleting Row');

    //console.error(`Error deleting frame with ID ${id}:`, error);
  }
};
const deletRowSubmitSubrow = async (id) => {
  try {
    await dispatch(deleteSubRow(id));
    // dispatch(fetchFrames());
    toast.success('Sub Row deleted successfully!');
    fetchDataRow();

    // window.location.reload()
  } catch (error) {
    toast.error('Error deleting Row');

    //console.error(`Error deleting frame with ID ${id}:`, error);
  }
};
const handleDeleteRow = (id) => {
  setDeleteingRow(id); // Store the frame ID to delete
  setShowRowConfirmation(true); // Show confirmation dialog
};


const handleConfirmRowDelete = () => {
  deletRowSubmit(deleteingRow); // Call delete function with stored frame ID
  setShowRowConfirmation(false); // Close confirmation dialog
};


const handleCancelRowDelete = () => {
  setShowRowConfirmation(false);
};
const handleCancelSubRowDelete = () => {
  setShowRowConfirmationSubrow(false);
};



/*eslint-disable*/



const updateUserCellValue = async (groupIndex, rowIndex, columnIndex, user, row_id, column_type, ID, ) => {
  try {
    const updatedGroups = [...groups]; // Clone the groups to update locally
    const cellValue =
      updatedGroups[groupIndex].rows[rowIndex].columns[columnIndex].value || [];
    const cellID = updatedGroups[groupIndex].rows[rowIndex].columns[0]?.id;
    console.log("cellIDcellID", cellID, updatedGroups[groupIndex].rows[rowIndex]);

    // Add or remove the user from the cellValue array
    if (!cellValue.some((u) => u.id === user.id)) {
      cellValue.push(user);

      // Call the notification API when a user is added
      const notificationPayload = {
        cell: cellID,
        user: globalUserId, // Replace with your global user ID
        to_user: user?.id,
        notificatio_type: "general",
      };

      try {
        const notificationApiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/add-assignee-notification/`;
        const notificationResponse = await fetch(notificationApiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(notificationPayload),
        });

        if (!notificationResponse.ok) {
          throw new Error("Failed to send notification");
        }
        const notificationData = await notificationResponse.json();
      } catch (notificationError) {
        console.error("Error sending notification:", notificationError);
      }
    } else {
      const index = cellValue.findIndex((u) => u.id === user.id);
      cellValue.splice(index, 1);
    }

    // Update the state with the new cell value
    updatedGroups[groupIndex].rows[rowIndex].columns[columnIndex].value = cellValue;
    setGroups(updatedGroups);

    // Prepare the data for the API call (multiple rows case)
    const rowData = {
      cell_data: cellValue.map((u) => ({ user: u.name, id: u.id, file_name: u?.file_name })),
    };

    // If selectedRowsData is available, handle multiple updates
    if (selectedRowsData && selectedRowsData.length > 0) {
      const updatePromises = selectedRowsData.map(async (selectedRow) => {
        const matchingColumn = selectedRow.columns[columnIndex];
        const columnId = matchingColumn?.id;

        if (!columnId) return; // Skip if no valid columnId is found

        // Prepare the payload for API call
        const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/cell/${columnId}/`; // Use dynamic columnId
        const response = await fetch(apiUrl, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...rowData,
            row_id: selectedRow.id, // Send the correct row_id in the payload
          }),
        });

        if (!response.ok) {
          throw new Error(`Failed to update cell for row ID: ${selectedRow.id}`);
        }
        return response.json();
      });

      // Wait for all updates to complete
      await Promise.all(updatePromises);
      console.log("Multiple cell data updated successfully");
    } else {
      // Fallback for single row update
      const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/cell/${ID}/`; // Use the ID for single update
      const response = await fetch(apiUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(rowData),
      });

      if (!response.ok) {
        throw new Error("Failed to update cell data");
      }
      const data = await response.json();
      console.log("Single cell data updated successfully:", data);
    }

    // Optionally, refresh data or UI
    fetchDataRow();
  } catch (error) {
    console.error("Error updating cell data:", error);
  }
};

// Function for handling notification when a user is added
const sendNotification = async (cellValue, user) => {
  const notificationPayload = {
    cell: cellValue.id,
    user: globalUserId, // Replace with your global user ID
    to_user: user?.id,
    notification_type: "general",
  };

  try {
    const notificationApiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/add-assignee-notification/`;
    const notificationResponse = await fetch(notificationApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(notificationPayload),
    });

    if (!notificationResponse.ok) {
      throw new Error("Failed to send notification");
    }
  } catch (notificationError) {
    console.error("Error sending notification:", notificationError);
  }
};






/*eslint-disable*/

const updateUserCellValueSubrow = async (groupIndex, rowIndex, columnIndex, user, row_id, column_type, ID,cell_data,subRowIndex,subColumnIndex,selectedRowsData) => {
  //console.log("Updating cell value for row_id:", row_id, "column_type:", column_type);

  const updatedGroups = [...groups];
  let cellValue = updatedGroups[groupIndex].rows[rowIndex].sub_data[subRowIndex]?.columns[subColumnIndex].value || [];

  // Add or remove the user from the cellValue array
  if (!cellValue.some(u => u.id === user.id)) {
    cellValue.push(user);
  } else {
    const index = cellValue.findIndex(u => u.id === user.id);
    cellValue.splice(index, 1);
  }

  // Update the state with the new cell value
  updatedGroups[groupIndex].rows[rowIndex].columns[columnIndex].value = cellValue;
  setGroups(updatedGroups);

  // Prepare the data for the API call
  const cellData = cellValue.map(u => ({ user: u.name, id: u.id, file_name: u?.file_name }));
  const rowData = { cell_data: cellData };
console.log("kjhgfdfghjkl",ID)
  try {
    const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/sub-cell/${ID}/`;
    const response = await fetch(apiUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(rowData),
    });

    if (!response.ok) {
      throw new Error('Failed to update cell data');
    }

    const data = await response.json();
    fetchDataRow()
    //console.log('Cell data updated successfully:', data);
  } catch (error) {
    //console.error('Error updating cell data:', error);
  }
};

const handleDateRangeChange = async (ranges) => {
  const { selection } = ranges;
  setDateRange(selection);
  setShowDateRangePicker(false);

  if (selectedCell) {
    const { groupIndex, rowIndex, columnIndex, row_id, column_type, ID } = selectedCell;
    const updatedGroups = [...groups];

    // Format dates
    const startDateFormatted = format(
      selection.startDate,
      selection.startDate.getFullYear() === currentYear ? "dd MMM" : "dd MMM yyyy"
    );
    const endDateFormatted = format(
      selection.endDate,
      selection.endDate.getFullYear() === currentYear ? "dd MMM" : "dd MMM yyyy"
    );
    const cellData = `${startDateFormatted} - ${endDateFormatted}`;
console.log("selectedRowsData",selectedRowsData)
    if (selectedRowsData && selectedRowsData.length > 0) {
      // Handle multiple rows update
      const updatePromises = selectedRowsData.map(async (row) => {
        const matchingColumn = row.columns.find((column) => column.column_index === columnIndex);

        if (matchingColumn) {
          // Update the column values in the local state
          const rowToUpdate = updatedGroups[groupIndex]?.rows.find((r) => r.id === row.id);
          if (rowToUpdate) {
            rowToUpdate.columns[columnIndex] = {
              ...rowToUpdate.columns[columnIndex],
              startDate: selection.startDate,
              endDate: selection.endDate,
            };
          }

          // Call API to update the cell
          const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/cell/${matchingColumn.id}/`;
          const response = await fetch(apiUrl, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ cell_data: cellData }),
          });

          if (!response.ok) {
            throw new Error(`Failed to update cell data for column ${matchingColumn.id}`);
          }

          return response.json();
        }

        return null;
      });

      try {
        // Wait for all updates to complete
        await Promise.all(updatePromises);
       // setSelectedRowsData([])
        toast.success(" Timeline updated successfully")

      } catch (error) {
        console.error("Error updating multiple cells:", error);
      }
    } else {
      // Handle single cell update
      const row = updatedGroups[groupIndex]?.rows[rowIndex];
      if (row) {
        row.columns[columnIndex] = {
          ...row.columns[columnIndex],
          startDate: selection.startDate,
          endDate: selection.endDate,
        };

        try {
          const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/cell/${ID}/`;
          const response = await fetch(apiUrl, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ cell_data: cellData }),
          });

          if (!response.ok) {
            throw new Error("Failed to update cell data");
          }
         // setSelectedRowsData([])
toast.success("Timeline updated successfully")
          console.log("Single cell updated successfully");
        } catch (error) {
          console.error("Error updating single cell:", error);
        }
      }
    }

    // Update the state with the new date range
    setGroups(updatedGroups);
    fetchDataRow(); // Refresh data
  }
};

const handleDateRangeChangeSubrow = async (ranges) => {
  const { selection } = ranges;
  setDateRange(selection);
  setShowDateRangePickerSubrow(false);

  if (selectedCell) {
    const { groupIndex, rowIndex, columnIndex, row_id, column_type, ID,cell_data,subRowIndex,subColumnIndex } = selectedCell;
    const updatedGroups = [...groups];
    const row = updatedGroups[groupIndex].rows[rowIndex].sub_data[subRowIndex];
    // updatedGroups[groupIndex].rows[rowIndex].sub_data[subRowIndex]?.columns[subColumnIndex].value
    if (row) {
      row.columns[columnIndex] = {
        ...row.columns[subColumnIndex],
        startDate: selection.startDate,
        endDate: selection.endDate
      };

      const cellData = `${selection.startDate.toISOString()} - ${selection.endDate.toISOString()}`;

      const rowData = {
        cell_data: cellData,
      };

      try {
        const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/sub-cell/${ID}/`;
        const response = await fetch(apiUrl, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(rowData),
        });

        if (!response.ok) {
          throw new Error('Failed to add cell data');
        }

        const data = await response.json();
        fetchDataRow();

        //console.log('Cell data added successfully:', data);
      } catch (error) {
        //console.error('Error adding cell data:', error);
      }

      // Update the state with the new date range
      setGroups(updatedGroups);
    }
  }
};
/*eslint-enable*/


  // const handleUserSelect = (user) => {
  //   const { groupIndex, rowIndex, columnIndex,row_id,column_type,ID,cell_data } = userMenu;
  //   //console.log("jjjjjjjjjjj11",row_id,column_type)
  //   updateUserCellValue(groupIndex, rowIndex, columnIndex, user,row_id,column_type,ID,cell_data);
  // };
  // const handleUserSelect = (user) => {
  //   const { groupIndex, rowIndex, columnIndex, row_id, column_type, ID } = userMenu;
  //   //console.log("Handling user select for row_id:", row_id, "column_type:", column_type);
  //   updateUserCellValue(groupIndex, rowIndex, columnIndex, user, row_id, column_type, ID);
  // };
  const handleUserSelect = (user) => {
    console.log("userMenu", userMenu);
  
    // Ensure cell_data is an array. If it's an object, convert it into an array.
    const currentCellData = Array.isArray(userMenu?.cell_data) ? userMenu.cell_data : [];
    const updatedCellData = [...currentCellData, user]; // Add the new user to the array.
  
    // Update the user menu state with the new cell data
    setUserMenu({
      ...userMenu,
      cell_data: updatedCellData,
    });
  
    // Call the updateUserCellValue function with the updated data
    const { groupIndex, rowIndex, columnIndex, row_id, column_type, ID } = userMenu;
    //console.log("Handling user select for row_id:", row_id, "column_type:", column_type);
    updateUserCellValue(groupIndex, rowIndex, columnIndex, user, row_id, column_type, ID);  };
  // const handleUserSelect = (user) => {
  //   const { groupIndex, rowIndex, columnIndex, row_id, column_type, ID } = userMenu;
  //   //console.log("Handling user select for row_id:", row_id, "column_type:", column_type);
  //   updateUserCellValue(groupIndex, rowIndex, columnIndex, user, row_id, column_type, ID);
  // };
  
  const handleRemoveUser = (user) => {
    // Ensure cell_data is an array and the user exists
    const updatedCellData = (userMenu?.cell_data || []).filter((u) => u?.id !== user?.id); // Add null/undefined checks
  
    if (updatedCellData.length === userMenu?.cell_data?.length) {
      console.warn("User not found in cell_data to remove");
    }
  
    setUserMenu({ ...userMenu, cell_data: updatedCellData });
  
    const { groupIndex, rowIndex, columnIndex, row_id, column_type, ID } = userMenu;
    //console.log("Handling user select for row_id:", row_id, "column_type:", column_type);
    updateUserCellValue(groupIndex, rowIndex, columnIndex, user, row_id, column_type, ID);  
  };
  
  // console.log("jhgfdfghjiikjhg",userMenu?.cell_data)
  
  const handleUserSelectSubrow = (user) => {
    const { groupIndex, rowIndex, columnIndex, row_id, column_type, ID,cell_data,subRowIndex ,subColumnIndex} = userMenuSubrow;
    //console.log("Handling user select for row_id:", row_id, "column_type:", column_type);
    updateUserCellValueSubrow(groupIndex, rowIndex, columnIndex, user, row_id, column_type, ID,cell_data,subRowIndex,subColumnIndex);
  };
  const handleCellClick = (event,groupIndex, rowIndex, columnIndex, header,row_id,column_type,ID,cell_data,theadId,selectedRowsData) => {
    const rect = event.target.getBoundingClientRect();
    const iconPosition = event.currentTarget.getBoundingClientRect();

    //console.log("jjjjjjjjjjj11222",row_id,column_type,ID)
    //console.log("sbdbaajdd",theadId)
  
    if (header.column_type === 'timeline') {
      setSelectedCell({ groupIndex, rowIndex, columnIndex, row_id, column_type, ID, cell_data,selectedRowsData });
      setDateRangePickerPosition({
        top: iconPosition.bottom,
        left: iconPosition.left,
      });
      setShowDateRangePicker(true);
    } else if (header.column_type === 'users') {
      setUserMenu({
        visible: true,
        position: {
          top: rect.bottom + window.scrollY,
          left: rect.left,
        },
        groupIndex,
        rowIndex,
        columnIndex,
        row_id,column_type,ID,cell_data,selectedRowsData,cell_data
      });
    }else if (header.column_type === 'dropdown') {
      setDropdownMenu({
        visible: true,
        position: {
          top: rect.bottom + window.scrollY,
          left: rect.left,
        },
        groupIndex,
        rowIndex,
        columnIndex,
        row_id,
        column_type,
        ID,
        cell_data,selectedRowsData
      });
      setvaluedropdown(theadId?.id)
    }else if (header.column_type === 'multiDropdown') {
      setMultiselectDropdownMenu({
        visible: true,
        position: {
          top: rect.bottom + window.scrollY,
          left: rect.left,
        },
        groupIndex,
        rowIndex,
        columnIndex,
        row_id,
        column_type,
        ID,
        cell_data,selectedRowsData
      });
      setMultivaluedropdown(theadId?.id)
    }
   
  };
  const handleCellClickSubrow = (event,groupIndex, rowIndex, columnIndex, header,row_id,column_type,ID,cell_data,subRowIndex,subColumnIndex,theadId) => {
    const rect = event.target.getBoundingClientRect();
    const iconPosition = event.currentTarget.getBoundingClientRect();

    //console.log("jjjjjjjjjjj11222",row_id,column_type,ID)
    console.log("sbdbaajdd",theadId)
  
    if (header.column_type === 'timeline') {
      setSelectedCell({ groupIndex, rowIndex, columnIndex, row_id, column_type, ID, cell_data,subRowIndex,subColumnIndex });
      setDateRangePickerPosition({
        top: iconPosition.bottom,
        left: iconPosition.left,
      });
      setShowDateRangePickerSubrow(true);
    } else if (header.column_type === 'users') {
      setUserMenuSubrow({
        visible: true,
        position: {
          top: rect.bottom + window.scrollY,
          left: rect.left,
        },
        groupIndex,
        rowIndex,
        columnIndex,
        row_id,column_type,ID,cell_data,subRowIndex,subColumnIndex
      });
    }else if (header.column_type === 'dropdown') {
      setDropdownMenuSubrow({
        visible: true,
        position: {
          top: rect.bottom + window.scrollY,
          left: rect.left,
        },
        groupIndex,
        rowIndex,
        columnIndex,
        row_id,
        column_type,
        ID,
        cell_data,subRowIndex,subColumnIndex
      });
      setvaluedropdownSubrow(theadId?.id)
    }else if (header.column_type === 'multiDropdown') {
      setMultiselectDropdownMenuSubrow({
        visible: true,
        position: {
          top: rect.bottom + window.scrollY,
          left: rect.left,
        },
        groupIndex,
        rowIndex,
        columnIndex,
        row_id,
        column_type,
        ID,
        cell_data,subRowIndex,subColumnIndex
      });
      setMultivaluedropdownSubrow(theadId?.id)
    }
   
  };
  /*eslint-disable*/

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_ROOT}/sch/get_user_data/${DeptID}/`);
        const data = await response.json();
        setUserData(data);
      } catch (error) {
        //console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [sheetId, frameId]);
  const renderTooltip = (props) => (
    <Tooltip id="add-group-tooltip" {...props}>
      You need admin or edit access to perform this action.
    </Tooltip>
  );
  const fetchDataRow = async () => {
    // setLoading(true);
    try {
      if (sheetId && frameId && globalUserId) {
        if (currentGroupFilter) {
          console.log("currentGroupFilter",currentGroupFilter)
          // Fetch grouped data based on the current filter
          if (currentGroupFilter.type === 'status') {
            await handleStatusSelect(currentGroupFilter.value);
          }
          // Add additional conditions here for other types of grouping filters
          return;
        }
        const groupsResponse = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/whole-sheet-data/${sheetId}/${globalUserId}`);
        const groupData = groupsResponse.data?.data;
        const editable = groupsResponse.data?.is_admin || groupsResponse.data?.can_edit || groupsResponse.data?.is_owner;
        const isDelete = groupsResponse.data?.is_admin ||  groupsResponse.data?.is_owner;

        console.log("isEditable",editable)
        setIsEditable(editable);
        setCanDelete(isDelete);


        setGroups(groupData);
        if (groupData?.length > 0) {

          const initialHeaders = groupData[0]?.rows[0].columns?.length;

          const initialHeadersSubrow = groupData[0]?.rows[1]?.sub_data[0]?.columns?.length;
          setTableHeadersSubrow(initialHeadersSubrow);

          setTableHeaders(initialHeaders);

          setRows(groupData[0].rows); // Assuming first group's rows as initial rows
        }

        const headersPromises = groupData.map(async (group) => {
          const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/group-heders/${group.id}`);
          return response.data.headers;
        });
        setLoading(false);
        const statusResponse = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/all-status-columns/${sheetId}`);
        setGroupStatusOptions(statusResponse.data.all_status);
      
        const allHeaders = await Promise.all(headersPromises);
        // const mergedHeaders = allHeaders.flat();
        // setTableHeaders(mergedHeaders); // Uncomment if needed
      }
    } catch (error) {
      setLoading(false);
      //console.error('Error fetching data:', error);
    }
  };
  const fetchData = async () => {
    setLoading(true);
    try {
      if (sheetId && frameId && globalUserId) {
        
        const groupsResponse = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/whole-sheet-data/${sheetId}/${globalUserId}`);
        const groupData = groupsResponse.data?.data;
        const editable = groupsResponse.data?.is_admin || groupsResponse.data?.can_edit || groupsResponse.data?.is_owner;
        const isDelete = groupsResponse.data?.is_admin ||  groupsResponse.data?.is_owner;

        console.log("isEditable",editable)
        setIsEditable(editable);
        setCanDelete(isDelete);


        setGroups(groupData);
        if (groupData?.length > 0) {

          const initialHeaders = groupData[0]?.rows[0].columns?.length;

          const initialHeadersSubrow = groupData[0]?.rows[1]?.sub_data[0]?.columns?.length;
          setTableHeadersSubrow(initialHeadersSubrow);

          setTableHeaders(initialHeaders);

          setRows(groupData[0].rows); // Assuming first group's rows as initial rows
        }

        const headersPromises = groupData.map(async (group) => {
          const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/group-heders/${group.id}`);
          return response.data.headers;
        });
        setLoading(false);
        const statusResponse = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/all-status-columns/${sheetId}`);
        setGroupStatusOptions(statusResponse.data.all_status);
      
        const allHeaders = await Promise.all(headersPromises);
        // const mergedHeaders = allHeaders.flat();
        // setTableHeaders(mergedHeaders); // Uncomment if needed
      }
    } catch (error) {
      setLoading(false);
      //console.error('Error fetching data:', error);
    }
  };
 
  useEffect(() => {
    fetchData();
    setSelectedRowsData([])
    setSelectedTasks([])
  }, [sheetId, frameId,globalUserId]);
  // console.log("kjhgfdsdfghopkjhg",selectedTasks)

  const handleStatusSelect = async (statusId, event) => {
    try {
      setSelectedStatus(statusId);
      setCurrentGroupFilter({ type: 'status', value: statusId }); // Set the grouping filter

      const response = await axios.get(
        `${process.env.REACT_APP_API_ROOT}/hotwash/group-by-status/${sheetId}/${statusId}`
      );
     

   

      setGroups(response.data?.data); // Assuming API returns the grouped data
      setIsEditable(true);
      setCanDelete(true);

      if (response.data.groups?.length > 0) {
        const initialHeaders = response.data.groups[0]?.rows[0].columns?.length;
        const initialHeadersSubrow = response.data.groups[0]?.rows[1]?.sub_data[0]?.columns?.length;
        setTableHeadersSubrow(initialHeadersSubrow);
        setTableHeaders(initialHeaders);
        setRows(response.data.groups[0].rows); // Assuming first group's rows as initial rows
      }

      setDropdownOpen(false); // Close dropdown after selection
    } catch (error) {
      console.error('Error fetching grouped data:', error);
    }
  };

    /*eslint-enable*/
    const handleClearFilter = () => {
      setSelectedStatus(null); // Clear selected status
      setCurrentGroupFilter(null); // Reset the grouping filter

      fetchData(); // Call fetchData to reset data
      setDropdownOpen(false); // Close the dropdown
    };
    
  const handleAddRow = async (groupId) => {
    const groupToUpdate = groups.find(group => group.id === groupId);
  
      const payload = {
        group_id: groupId,
        row_index: groupToUpdate.rows.length,
        sheet_id:parseInt(sheetId)
      };
  
      try {
        // Make the API call to add the new row
        const response = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/add-row/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
  
        // Check if the response is successful
        if (response) {
          // const data = await response.json();
          fetchDataRow()
  // window.location.reload()
          // Update the state with the new row added to the specific group
          // setGroups(prevGroups =>
          //   prevGroups.map(group => {
          //     if (group.id === groupId) {
          //       return {
          //         ...group,
          //         rows: [...group.rows, newRow],
          //       };
          //     }
          //     return group;
          //   })
          // );
        } else {
          //console.error('Failed to add row:', response.statusText);
        }
      } catch (error) {
        //console.error('Error adding row:', error);
      }
    // }
  };
  useEffect(() => {
    const fetchAndUpdate = () => {
      const DeptId = localStorage.getItem('HDept');
      const DivId = localStorage.getItem('HDiv');
      if (DeptId && DivId && globalUserId) {
        dispatch(fetchFrames({ DeptId, DivId,globalUserId }));
      }
    };

    // Fetch frames initially
    if (status === 'idle') {
      fetchAndUpdate();
    }

    // Listen for storage changes
    const handleStorageChange = (event) => {
      if (event.key === 'DeptId' || event.key === 'DivId') {
        fetchAndUpdate();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Cleanup event listener
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [dispatch, status,globalUserId]);
  const handleAddGroup = async () => {
    const newGroupId = Date.now();
    const newGroupName = `Group ${groups.length + 1}`;
  
    // Assuming headers are derived from the first group's first row's columns
    const initialHeaders = groups.length > 0 ? groups[0].rows[0].columns.map(column => ({
      ...column,
      id: Date.now(), // Generate a unique id for each header
    })) : [];
  
    const newRow = {
      id: Date.now(), // Generate a unique id for the row
      columns: initialHeaders.map(header => ({
        ...header,
        value: '', // Optionally set default values for headers
      })),
      type: 'header', // Indicate this row is for headers
    };
  
    const newGroup = {
      id: newGroupId,
      name: newGroupName,
      rows: [newRow], // Initialize with the header row
    };
  
    try {
      const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/add-group/`;
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sheet_id: sheetId }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to add group');
      }
  
      const data = await response.json();
      console.log('Group added successfully:', data);
      fetchDataRow();

      // Update the state to include the new group
      setGroups(prevGroups => [...prevGroups, newGroup]);
    } catch (error) {
      //console.error('Error adding group:', error);
    }
  };
  function isImageFile(fileName) {
    const extension = fileName.split('.').pop().toLowerCase();
    const imageExtensions = ['png', 'jpg', 'jpeg', 'svg', 'webp'];
    return imageExtensions.includes(extension);
  }
  
  const handleDotsClick = (event,docs) => {
    // Prevents parent click event (cell click) from triggering
    setSelectedDocs(docs);
    setShowModal(true);
  };

  const handleDeleteDoc = async (docId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/delete-doc-file/${docId}/`);
      if (response) {

        fetchDataRow()
        toast.success('File deleted successfully!');
        setShowModal(false)
        // fetchDocuments();
      } else {
        //console.error('Failed to delete the document:', response.status, response.data);
      }
    } catch (error) {
      //console.error('Error deleting the document:', error);
    }
  };
    /*eslint-disable*/

  const handleAddColumnClick = async (name,type) => {
    //console.log("typetype",type,name)
    const newColumn = {
      sheet_id: sheetId,
      name: name,
      column_type: type,
      column_index: tableHeaders 
    };
    
  
    try {
      // API call to add a new column
      const response = await axios.post(`${process.env.REACT_APP_API_ROOT}/hotwash/add-column/`, newColumn);
  
      if (response) {
                setMenuVisible(false);

        if (sheetId && frameId && globalUserId) {
          const groupsResponse = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/whole-sheet-data/${sheetId}/${globalUserId}`);
          const groupData = groupsResponse.data?.data;
          const editable = groupsResponse.data?.is_admin || groupsResponse.data?.can_edit || groupsResponse.data?.is_owner;
          const isDelete = groupsResponse.data?.is_admin ||  groupsResponse.data?.is_owner;

        console.log("isEditable",editable)
        setIsEditable(editable);
        setCanDelete(isDelete);


          setGroups(groupData);
          if (groupData.length > 0) {
            const initialHeaders = groupData[0]?.rows[0].columns?.length;
            setTableHeaders(initialHeaders);
            const initialHeadersSubrow = groupData[0]?.rows[1]?.sub_data[0]?.columns?.length;
            setTableHeadersSubrow(initialHeadersSubrow);
            setRows(groupData[0].rows); // Assuming first group's rows as initial rows
          }
  
          const headersPromises = groupData.map(async (group) => {
            const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/group-heders/${group.id}`);
            return response.data.headers;
          });
  
          const allHeaders = await Promise.all(headersPromises);
          const mergedHeaders = allHeaders.flat();
          // setTableHeaders(mergedHeaders); // Uncomment if needed
        }
      }
    } catch (error) {
      //console.error('Error adding column:', error);
    }
  };
  const handleAddColumnClickSubrow = async (name,type) => {
    //console.log("typetype",type,name)
    const newColumn = {
      sheet_id: sheetId,
      name: name,
      column_type: type,
      column_index: tableHeadersSubrow // The new column index
    };
    
  
    try {
      // API call to add a new column
      const response = await axios.post(`${process.env.REACT_APP_API_ROOT}/hotwash/add-sub-column/`, newColumn);
  
      if (response) {
                setMenuVisibleSubrow(false);
                fetchDataRow()
       
      }
    } catch (error) {
      //console.error('Error adding column:', error);
    }
  };
    /*eslint-enable*/

  // const handleColumnOptionsClick = (event) => {
  //   const { clientX, clientY } = event;
  //   setMenuPosition({ top: clientY, left: clientX });
  //   setMenuVisible(true);
  // };
  const handleColumnOptionsClick = (event) => {
    const iconPosition = event.currentTarget.getBoundingClientRect();
    setMenuPosition({
      top: iconPosition.bottom,
      left: iconPosition.left,
    });
    setMenuVisible(true);
  };
  
  const handleColumnOptionsClickSubrow = (event) => {
    
    const iconPosition = event.currentTarget.getBoundingClientRect();
    setMenuPositionSubrow({
      top: iconPosition.bottom,
      left: iconPosition.left,
    });
    setMenuVisibleSubrow(true);
  };
  // const handleGroupNameClick = (groupId) => {
  //   setEditableGroupId(groupId);
  //   const groupToUpdate = groups.find(group => group.id === groupId);
  //   setNewGroupName(groupToUpdate.name);
  // };

  const handleGroupNameChange = (event) => {
    setNewGroupName(event.target.value);
  };
  const handleEditGroupName = (group) => {
    setEditableGroupId(group.id);
    setNewGroupName(group.name); 
    setIsMenuVisible(false)
  };
  const handleKeyDown = (event, groupId) => {
    if (event.key === 'Enter') {
      handleGroupNameBlur(groupId);  // Call handleGroupNameBlur on Enter
    }
  };
  const handleGroupNameBlur = async (groupId) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_ROOT}/hotwash/update-group/${groupId}/`, {
        name: newGroupName,
        sheet_id: sheetId
      });

      setGroups(groups.map(group => {
        if (group.id === groupId) {
          return { ...group, name: newGroupName };
        }
        return group;
      }));

      setEditableGroupId(null);
    } catch (error) {
      //console.error('Error updating group name:', error);
    }
  };
  const handleAddMenuClick = (groupId) => {
    setOpenMenuGroupId(openMenuGroupId === groupId ? null : groupId);
    setIsMenuVisible(true);
  };
      /*eslint-disable*/
  const handleAddRowMenuClick = (groupId) => {
    setOpenMenuRowId(openMenuRowId === groupId ? null : groupId);
    setIsRowMenuVisible(true);
  };
  
      /*eslint-enable*/
  const renderGroupName = (group) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }} className="add-column-button-container">
        <BiDotsVertical
          style={{ cursor: 'pointer', marginLeft: '8px' }}
          onClick={() => handleAddMenuClick(group.id)}
        />
  
        {editableGroupId === group.id ? (
          <input
            type="text"
             maxLength="100"
            className="form-control form-control-sm mx-2"
            value={newGroupName}  // Bind the input field to newGroupName
            onChange={handleGroupNameChange}
            onBlur={() => handleGroupNameBlur(group.id)}
            onKeyDown={(event) => handleKeyDown(event, group.id)}  // Call handleKeyDown on key press
            autoFocus
          />
        ) : (
          <h5   style={{
            maxWidth: '350px',       // Set a max width for ellipsis
            whiteSpace: 'nowrap',    // Prevent text from wrapping
            overflow: 'hidden',      // Hide overflow
            textOverflow: 'ellipsis' // Add ellipsis when text overflows
          }}className="editable-group-name mx-2" onClick={() => toggleGroupCollapse(group.id)}>
            {collapsedGroups[group.id] ? (
              <BiChevronDown className="me-2" />
            ) : (
              <BiChevronUp className="me-2" />
            )}
            {group.name}
          </h5>
        )}
  
        {openMenuGroupId === group.id && isMenuVisible && (
          <div className="add-column-group frame-name" ref={groupMenuRef}>
            {/* <p className="menu-item" onClick={() => toggleGroupCollapse(group.id)}>
              {collapsedGroups[group.id] ? (
                <>
                  <BiChevronDown className="me-2" /> Expand
                </>
              ) : (
                <>
                  <BiArchiveIn className="me-2" /> Collapse
                </>
              )}
            </p> */}
            {!(isEditable) ? (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-delete1`}>You need admin access and Edit permissions</Tooltip>}
      >
        <span className="">
          <p className="menu-item" style={{ color: 'gray', cursor: 'not-allowed' }}>
          <BiEdit className="me-2" /> Edit    </p>
        </span>
      </OverlayTrigger>
    ) : (
      <p className="menu-item" onClick={() => handleEditGroupName(group)}>
      <BiEdit className="me-2" /> Edit
    </p>
          
    )}  
        {!(canDelete) ? (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-delete1`}>You need admin access and Delete permissions</Tooltip>}
      >
        <span className="">
          <p className="menu-item" style={{ color: 'gray', cursor: 'not-allowed' }}>
          <BiTrash className="me-2" /> Delete    </p>
        </span>
      </OverlayTrigger>
    ) : (
      <p className="menu-item" onClick={() => handleDeleteGroup(group.id)}>
      <BiTrash className="me-2" /> Delete
    </p>
          
    )}                 
           
           
          </div>
        )}
      </div>
    );
  };
  
  
  // const renderGroupName = (group) => {
  //   if (editableGroupId === group.id) {
  //     return (
  //       <input
  //         type="text"
  //         className="form-control form-control-sm"
  //         value={newGroupName}
  //         onChange={handleGroupNameChange}
  //         onBlur={() => handleGroupNameBlur(group.id)}
  //         autoFocus
  //       />
  //     );
  //   } else {
  //     return (
    


  //       <div style={{ display: 'flex', alignItems: 'center' }} className='add-column-button-container'>
  //         <BiDotsVertical
  //         // className="bi bi-three-dots-horizontal"
  //         style={{ cursor: 'pointer', marginLeft: '8px' }}
  //         onClick={() => handleAddMenuClick(group.id)}
  //       ></BiDotsVertical>
  //       <h5 className="editable-group-name mx-2" onClick={() => handleGroupNameClick(group.id)}>
  //         {group.name}
  //       </h5>
        
  //         {/* {openMenuGroupId === group?.id && isMenuVisible && (
  //         <div ref={groupMenuRef} className="add-column-group frame-name">
  //           <p className="menu-item" onClick={''}>
  //           <BiArchiveIn className="me-2" />Collapse
  //           </p>
  //           <p className="menu-item" onClick={() => handleDeleteGroup(group?.id)}>
  //           <BiTrash className="me-2" />Delete
  //           </p>
           
  //         </div>
  //       )} */}
  //        {openMenuGroupId === group.id && isMenuVisible && (
  //           <div className="add-column-group frame-name">
  //             <p className="menu-item" onClick={() => toggleGroupCollapse(group.id)}>
  //               {collapsedGroups[group.id] ? (
  //                 <>
  //                   <BiChevronDown className="me-2" /> Expand
  //                 </>
  //               ) : (
  //                 <>
  //                   <BiArchiveIn className="me-2" /> Collapse
  //                 </>
  //               )}
  //             </p>
  //             <p className="menu-item" onClick={() => handleDeleteGroup(group.id)}>
  //               <BiTrash className="me-2" /> Delete
  //             </p>
  //             <p className="menu-item" onClick={}>
  //               <BiEdit className="me-2" /> Edit
  //             </p>
  //           </div>
  //         )}

  //     </div>
  //     );
  //   }
  // };
/*eslint-disable*/

  const handleSelect = (ranges, rowIndex) => {
    const { startDate, endDate } = ranges.selection;
    const newRows = [...rows];
    newRows[rowIndex].selectionRange = ranges.selection;
    newRows[rowIndex].isOpenRange = false; // Close the date range picker
    setRows(newRows);
  };

  const handleDateChange = async (
    date,
    groupIndex,
    rowIndex,
    columnIndex,
    row_id,
    column_type,
    ID,
    selectedRowsData
  ) => {
    const updatedGroups = [...groups];
    const group = updatedGroups[groupIndex];
    let rowData;
  
    if (!group || !group.rows[rowIndex] || !group.rows[rowIndex].columns[columnIndex]) {
      return; // Row or column not found
    }
  
    // Convert selected date to IST
    const utcDate = new Date(date);
    const istOffset = 5.5 * 60 * 60 * 1000; // 5 hours 30 minutes in milliseconds
    const istDate = new Date(utcDate.getTime() + istOffset);
  
    // Update the value in the specific group, row, and column
    group.rows[rowIndex].columns[columnIndex].value = istDate;
    setGroups(updatedGroups);
  
    // Prepare row data with the IST date
    rowData = {
      cell_data: istDate, // Store the IST date in cell data
    };
  
    try {
      if (selectedRowsData && selectedRowsData.length > 0) {
        // When multiple rows are selected, update all selected columns
        const matchingData = selectedRowsData
          .map((row) => {
            // Find the matching column in the current row
            const matchingColumn = row.columns.find(
              (column) => column.column_index === columnIndex
            );
            if (matchingColumn) {
              return {
                columnId: matchingColumn.id,  // Collect column_id if column_index matches
                rowId: row.id,                // Collect row_id for the current row
              };
            }
            return null;
          })
          .filter((data) => data !== null);  // Filter out null values
  
        // Loop over the matching data and update them using the API
        const updatePromises = matchingData.map(async ({ columnId, rowId }) => {
          const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/cell/${columnId}/`; // Use dynamic column ID in the URL
          const response = await fetch(apiUrl, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...rowData,
              row_id: rowId, // Send the correct row_id in the payload
            }),
          });
  
          if (!response.ok) {
            throw new Error(`Failed to update column ${columnId} for row ${rowId}`);
          }
          return response.json();
        });
  
        // Wait for all updates to complete
        await Promise.all(updatePromises);
       // setSelectedRowsData([])
        console.log("Cells updated successfully");
      } else {
        // Fallback logic when selectedRowsData is not available (single cell update)
        const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/cell/${ID}/`;  // Use the single ID in the URL
        const response = await fetch(apiUrl, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(rowData),
        });
  
        if (!response.ok) {
          throw new Error('Failed to update cell data');
        }
  
        const data = await response.json();
       // setSelectedRowsData([])

        console.log('Cell data updated successfully:', data);
      }
  
      // Refresh data after the update
      fetchDataRow();
    } catch (error) {
      console.error('Error adding cell data:', error);
    }
  };
  
  /*eslint-enable*/
  const handleDateChangeSubrow = async(date, groupIndex, rowIndex, columnIndex,row_id,column_type,ID) => {
    let rowData={
    
      cell_data: date,
    }
    try {
      const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/sub-cell/${ID}/`;
      const response = await fetch(apiUrl, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(rowData),
      });
      if (!response.ok) {
        throw new Error('Failed to add cell data');
      }
      const data = await response.json();
      fetchDataRow();
      console.log('Cell data added successfully:', data);
    } catch (error) {
      //console.error('Error adding cell data:', error);
    }
  
  };
  // const formatDate = (date) => {
  //   const d = new Date(date);
  //   const month = `${d.getMonth() + 1}`.padStart(2, '0');
  //   const day = `${d.getDate()}`.padStart(2, '0');
  //   const year = d.getFullYear();
  //   return `${month}/${day}/${year}`;
  // };
  
  const toggleGroupCollapse = (groupId) => {
    setCollapsedGroups(prevState => ({
      ...prevState,
      [groupId]: !prevState[groupId]
    }));
    setIsMenuVisible(false)
  };
  useEffect(() => {
    const initialCollapsedState = {};
    groups.forEach((group) => {
      initialCollapsedState[group.id] = false;
    });
    setCollapsedGroups(initialCollapsedState);
  }, [groups, sheetId]);

  // Function to toggle collapse/expand for all groups
  const toggleAllGroupsCollapse = () => {
    setCollapsedGroups((prevState) => {
      const areAllCollapsed = Object.values(prevState).every((state) => state === true);
      const updatedState = {};
      groups.forEach((group) => {
        updatedState[group.id] = !areAllCollapsed;
      });
      return updatedState;
    });
  };

  // Handle the Ctrl + G keypress to trigger the toggleAllGroupsCollapse
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.ctrlKey && event.key === 'g') {
        event.preventDefault();
        toggleAllGroupsCollapse();
      }
    };

    // Add event listener
    window.addEventListener('keydown', handleKeyPress);

    // Clean up event listener on component unmount or when `sheetId` changes
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [toggleAllGroupsCollapse]); // Add `toggleAllGroupsCollapse` to dependencies

  const addCellData = async (data,ID) => {
    let rowData={cell_data:data}
    try {
      const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/cell/${ID}/`;
      const response = await fetch(apiUrl, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(rowData),
      });
      if (!response.ok) {
        throw new Error('Failed to add cell data');
      }
      const data = await response.json();
      console.log('Cell data added successfully:', data);
    } catch (error) {
      //console.error('Error adding cell data:', error);
    }
  };
  const addCellDataSubrow = async (data,ID) => {
    let rowData={cell_data:data}
    try {
      const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/sub-cell/${ID}/`;
      const response = await fetch(apiUrl, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(rowData),
      });
      if (!response.ok) {
        throw new Error('Failed to add cell data');
      }
      const data = await response.json();
      fetchDataRow();
      console.log('Cell data added successfully:', data);
    } catch (error) {
      //console.error('Error adding cell data:', error);
    }
  };
  const handleBatchUpdate = async (headerId, updatedValue, header, selectedRowsData, rowIndex, columnIndex, row_id) => {
    try {
      // Iterate over selected rows and update matching columns based on the columnIndex
      const updatePromises = selectedRowsData.map(async (row) => {
        // Check if the row's column index matches the updated column index
        const matchingColumn = row.columns.find(
          (column) => column.column_index === columnIndex // Compare with the columnIndex of the updated row
        );
  console.log("matchingColumn",matchingColumn)
        if (!matchingColumn) {
          // Skip if the column index doesn't match
          return null;
        }
  
        // Construct payload for API call
        const payload = {
          row_id: row.id,
          column_id: matchingColumn.id,
          value: updatedValue,
        };
  
        // Call the API to update the status
        // const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/cell/${headerId}/`;
        // const response = await fetch(apiUrl, {
        //   method: 'PATCH',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify(payload),
        // });
  
        // if (!response.ok) {
        //   throw new Error(`Failed to update row ${row.id} for column ${matchingColumn.id}`);
        // }
  
        // return response.json(); // Return the updated data
      });
  
      // Wait for all promises to resolve
      await Promise.all(updatePromises);
  
      // Refresh data after the batch update
      fetchDataRow();
      console.log('Batch update successful');
    } catch (error) {
      console.error('Error in batch update:', error);
    }
  };
  
  const renderInputByType = (header, groupIndex, rowIndex, columnIndex,row_id,groupHeader,column_type,ID,cell_data,docs,theadId) => {
    const row = groups[groupIndex]?.rows[rowIndex];
   //console.log("santhuskns",header)
    const isBatchUpdate = selectedTasks.length > 0; // `selectedRows` should be the state tracking selected checkboxes
// console.log("selectedTasks",selectedRowsData)
    if (!row) return null;
    const handleClick = (event) => {
      if (isBatchUpdate) {
        const allColumns = selectedRowsData.flatMap((row) => row.columns || []);

        handleCellClick(
          event,
          groupIndex,
          rowIndex,
          columnIndex,
          header,
          row_id,
          column_type,
          ID,
          cell_data,
          theadId,selectedRowsData
        );
      } else {
        // Call single cell update
        handleCellClick(
          event,
          groupIndex,
          rowIndex,
          columnIndex,
          header,
          row_id,
          column_type,
          ID,
          cell_data,
          theadId,selectedRowsData
        );
      }
    };
    switch (header?.column_type) {
     
      case 'dropdown':
        return (
          <div
          onClick={handleClick} // Click handler
          style={{
              backgroundColor: cell_data?.status_color || 'transparent', // Default to transparent if status_color is undefined
              color: cell_data?.status_color ? "white" : "black", // Default text color to black if status_color is not defined
              marginTop: "5px",
              cursor: 'pointer', // Adding a cursor to indicate it's clickable
              minHeight:"50px",

          }}
      >
          {cell_data && cell_data.status_text ? cell_data.status_text : '-'}
      </div>
      
        );
          case 'multiDropdown':
            // Check if cell_data is an array and contains items
            const statusItems = Array.isArray(cell_data) ? cell_data.map(item => item.status_text) : [];
          
            return (
              <div
                onClick={(event) => handleCellClick(event, groupIndex, rowIndex, columnIndex, header, row_id, column_type, ID, cell_data, theadId,selectedRowsData)}
                className="multi-dropdown-cell"
                style={{
                  backgroundColor: cell_data?.length > 0 ? cell_data[0]?.status_color : 'transparent', // Adjust if necessary
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight:"50px",
                }}
              >
                {statusItems.length > 0 ? (
                  statusItems.map((text, index) => (
                    <span key={index} className="status-item mx-1" style={{
                      
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                      {text}
                    </span>
                  ))
                ) : (
                  '-'
                )}
              </div>
            );
          
          case 'date':
            const isValidDate = (date) => !isNaN(new Date(date).getTime());
            const selectedDate = isValidDate(cell_data) ? new Date(cell_data) : null;
          
            return (
              <div className="date-cell-container">
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => handleDateChange(date, groupIndex, rowIndex, columnIndex, row_id, column_type, ID,selectedRowsData)}
                  dateFormat="dd/MM/yyyy"
                  className="form-control no-border date-input"
                  showYearDropdown  // Enables the year dropdown
                  scrollableYearDropdown // Makes the year dropdown scrollable
                  yearDropdownItemNumber={15} // Number of years to display in the dropdown
                />
              </div>
            );
            case 'timeline':
              let startDate = null;
              let endDate = null;
            
              if (typeof cell_data === 'string') {
                const dates = cell_data.split(" - ");
                if (dates.length === 2) {
                  const startYear = dates[0].includes(currentYear) ? currentYear : null;
                  const endYear = dates[1].includes(currentYear) ? currentYear : null;
            
                  startDate = new Date(`${dates[0]} ${startYear || '2024'}`);
                  endDate = new Date(`${dates[1]} ${endYear || '2024'}`);
                }
              }
              
              return (
                <div className="timeline-cell-container" onClick={(event) => handleCellClick(event, groupIndex, rowIndex, columnIndex, header, row_id, column_type, ID, cell_data)}>
                  {startDate && endDate ? (
                    `${format(startDate, startDate.getFullYear() === currentYear ? 'dd MMM' : 'dd MMM yyyy')} - ${format(endDate, endDate.getFullYear() === currentYear ? 'dd MMM' : 'dd MMM yyyy')}`
                  ) : (
                    <FaCalendarAlt /> // Render calendar icon when no dates are selected
                  )}
                </div>
              );
            
  case 'users': {
    // Ensure cell_data is always treated as an array
    const userData = Array.isArray(cell_data) ? cell_data : [];
  
    if (!row.columns[columnIndex].value && userData.length > 0) {
      row.columns[columnIndex].value = userData.map(user => ({
        id: user.id,
        name: user.user,
        file_name: user.file_name
      }));
    }
  
    return (
      <div 
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',                  minHeight:"50px",
        }}  // Center horizontally
        onClick={(event) => handleCellClick(event, groupIndex, rowIndex, columnIndex, header, row_id, column_type, ID, cell_data,selectedRowsData)} 
        className="user-cell"
      >
        {row?.columns[columnIndex]?.value?.length > 0 ? (
          row.columns[columnIndex].value.map(user => (
            <div key={user.id} className="user-initial" style={{ margin: '0 5px' }}>  {/* Add margin between users */}
              <img
                src={`${staticPath}/${user?.file_name}`}
                alt={user.name}
                className="rounded-circle user-image"  // Use CSS class for uniform size
              />
            </div>
          ))
        ) : (
          <div className="user-initial">
            <img
              src="/avtar.png"
              alt="Avatar"
              className="rounded-circle user-image"  // Apply same class for consistency
            />
          </div>
        )}
      </div>
    );
}    
case 'integer':
  return (
    <div className="integer-cell-container" style={{                  minHeight:"50px",
    }}>
      <input
        type="number"
        className="form-control no-border integer-input"
        defaultValue={typeof cell_data === 'object' ? '' : cell_data || ""}
        onChange={(e) => addCellData(e.target.value, ID)}
      />
    </div>
  );

  case 'file':
    return (
      <FileUpload
        ID={ID}
        docs={docs}
        fetchData={fetchDataRow}
        cellId={row_id}
        onDotsClick={handleDotsClick}

      />
    );
    case 'hyperlink':
  return (
    <LinkHandler
  ID={ID}
  cellId={row_id}
  apiUrl="http://localhost:8000/hotwash/cell"
  fetchData={fetchDataRow} // Function to reload data
  cell_data={cell_data} // Contains name and link
/>

  );
  case 'library':
    return (
      <LibraryHandler
    ID={ID}
    cellId={row_id}
    apiUrl="http://localhost:8000/hotwash/cell"
    fetchData={fetchDataRow} // Function to reload data
    cell_data={cell_data} // Contains name and link
    userId={globalUserId}
  />
  
    );
    case 'notes':
    return (
      <NotesHandler
    ID={ID}
    cellId={row_id}
    apiUrl="http://localhost:8000/hotwash/cell"
    fetchData={fetchDataRow} // Function to reload data
    cell_data={cell_data} // Contains name and link
    userId={globalUserId}
  />
  
    );
  case 'phone':
    return (
      <PhoneNumberHandler
    ID={ID}
    cellId={row_id}
    apiUrl="http://localhost:8000/hotwash/cell"
    fetchData={fetchDataRow} 
    cell_data={cell_data} 
  />
  
    );
  case 'email':
    return (
      <EmailHandler
    ID={ID}
    cellId={row_id}
    apiUrl="http://localhost:8000/hotwash/cell"
    fetchData={fetchDataRow} // Function to reload data
    cell_data={cell_data} // Contains name and link
  />
  
    );
    case 'location':
      return (
        <LocationHandler
      ID={ID}
      cellId={row_id}
      apiUrl="http://localhost:8000/hotwash/cell"
      fetchData={fetchDataRow} 
      cell_data={cell_data} 
    />
    
      );
    

// case 'email':
//   return (
//     <div className="email-container" style={{ position: 'relative' }}>
//     {email ? (
//       <div style={{ display: 'flex', alignItems: 'center' }}>
//         <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
//           <span>{email}</span>
//         </a>
//         <FaTimes
//           className="email-remove-icon"
//           onClick={handleEmailRemove}
//           style={{ cursor: 'pointer', marginLeft: '8px' }}
//         />
//       </div>
//     ) : (
//       <FaEnvelope
//         className="email-icon"
//         onClick={() => handleEmailClick(ID)} // Pass ID to handleEmailClick
//         style={{ cursor: 'pointer' }}
//       />
//     )}
//     {emailInputVisible && (
//       <div ref={emailInputRef} className="email-input-popup">
//         <input
//           type="email"
//           value={email}
//           onChange={(e) => handleEmailChange(e.target.value)} // Pass email directly
//           className="form-control"
//           placeholder="Enter email"
//         />
//         {emailError && <div className="email-error">{emailError}</div>}
//         <button onClick={handleEmailSave}>Save</button>
//       </div>
//     )}
//   </div>
//   ); 
      default:
        return (
          <OverlayTrigger
            placement="top" // Position of the tooltip (can be 'top', 'bottom', 'left', 'right')
            overlay={
              <Tooltip id={`tooltip-${ID}`} style={{ fontSize: '18px' }}>
                {typeof cell_data === 'object' ? '' : cell_data || 'No data available'}
              </Tooltip>
            }
          >
            <input
              type="text"
              className="form-control no-border"
              defaultValue={typeof cell_data === 'object' ? '' : cell_data || ''}
              onChange={(e) => addCellData(e.target.value, ID)}
            />
          </OverlayTrigger>
        );
      //   return <input
      //   type="text"
      //   className="form-control no-border"
      //   defaultValue={typeof cell_data === 'object' ? '' : cell_data || ""}

      //   // defaultValue={cell_data || ""}
          
      //     onChange={(e) =>
      //       addCellData(e.target.value, ID)
      //     }
      // />;
    //   return(
    //      <div>
    //   <input
    //     type="text"
    //     className="form-control no-border"
    //     defaultValue={typeof cell_data === 'object' ? '' : cell_data || ''}
    //     onChange={(e) => addCellData(e.target.value, ID)}
    //     data-tooltip-id="tooltip" // Connect this input to the tooltip
    //     data-tooltip-content={cell_data} // Tooltip content
    //   />
    //   <Tooltip id="tooltip" />
    // </div>
    //   )
    }
  };
  const renderInputByTypeSubrow = (header, groupIndex, rowIndex, columnIndex,row_id,groupHeader,column_type,ID,cell_data,docs,subRowIndex,subColumnIndex,theadId) => {
    const row = groups[groupIndex]?.rows[rowIndex]?.sub_data[subRowIndex];
   console.log("santhusknyyyys",docs)
  
    if (!row) return null;

    switch (header?.column_type) {
     
      case 'dropdown':
        return (
          <div
          onClick={(event) => handleCellClickSubrow(event,groupIndex, rowIndex, columnIndex, header, row_id, column_type, ID, cell_data,subRowIndex,subColumnIndex,theadId)}
          style={{
              backgroundColor: cell_data?.status_color || 'transparent', // Default to transparent if status_color is undefined
              color: cell_data?.status_color ? "white" : "black", // Default text color to black if status_color is not defined
              marginTop: "5px",
              cursor: 'pointer',
              minHeight:"50px",
              // Adding a cursor to indicate it's clickable
          }}
      >
          {cell_data && cell_data.status_text ? cell_data.status_text : '-'}
      </div>
      
        );
        case 'multiDropdown':
  // Extract status items
  const statusItems = Array.isArray(cell_data) ? cell_data.map(item => item.status_text) : [];
  
  return (
    <div
      onClick={(event) => handleCellClickSubrow(event, groupIndex, rowIndex, columnIndex, header, row_id, column_type, ID, cell_data, subRowIndex, subColumnIndex, theadId)}
      className="multi-dropdown-cell"
      style={{
        backgroundColor: cell_data?.[0]?.status_color || 'transparent', // Adjust based on your logic
        display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    minHeight:"50px",

      }}
    >
      {statusItems.length > 0 ? (
        statusItems.map((text, index) => (
          <span key={index} className="status-item mx-1">
            {text}
          </span>
        ))
      ) : (
        '-'
      )}
    </div>
  );

      case 'date':
                const isValidDate = (date) => !isNaN(new Date(date).getTime());

        const selectedDate = isValidDate(cell_data) ? new Date(cell_data) : null;





        return (
          <DatePicker
          // selected={cell_data ? new Date(cell_data) : null}

          selected={selectedDate}
          onChange={(date) => handleDateChangeSubrow(date, groupIndex, rowIndex, columnIndex, row_id, column_type, ID)}
          dateFormat="dd/MM/yyyy"
          className="form-control no-border"
          showYearDropdown  // Enables the year dropdown
  scrollableYearDropdown // Makes the year dropdown scrollable
  yearDropdownItemNumber={15} 
        />
        );

        case 'timeline':
  //console.log("santhu123", cell_data);

  let startDate = null;
  let endDate = null;

  if (typeof cell_data === 'string') {
    const dates = cell_data.split(" - ");
    if (dates.length === 2) {
      startDate = new Date(dates[0]);
      endDate = new Date(dates[1]);
    }
  }

  return (
    <div onClick={(event) => handleCellClickSubrow(event, groupIndex, rowIndex, columnIndex, header, row_id, column_type, ID, cell_data,subRowIndex,subColumnIndex)}>
      {startDate && endDate ? (
        `${new Intl.DateTimeFormat('en-GB').format(startDate)} - ${new Intl.DateTimeFormat('en-GB').format(endDate)}`
      ) : (
        <FaCalendarAlt /> // Render calendar icon when no dates are selected
      )}
    </div>
  );

          case 'users': {
            // Ensure cell_data is always treated as an array
            const userData = Array.isArray(cell_data) ? cell_data : [];
          
            if (!row.columns[columnIndex].value && userData.length > 0) {
              row.columns[columnIndex].value = userData.map(user => ({
                id: user.id,
                name: user.user,
                file_name: user.file_name
              }));
            }
          
            return (
              <div 
                style={{ display: 'flex', alignItems: 'center' ,                  minHeight:"50px",
                }} 
                onClick={(event) => handleCellClickSubrow(event, groupIndex, rowIndex, columnIndex, header, row_id, column_type, ID, cell_data,subRowIndex,subColumnIndex)} 
                className="user-cell"
              >
                {row?.columns[columnIndex]?.value?.length > 0 ? (
                  row.columns[columnIndex].value.map(user => (
                    <div key={user.id} className="user-initial">
                      <img
                        src={`${staticPath}/${user?.file_name}`}
                        alt={user.name}
                        className="rounded-circle"
                        width="30"
                        height="30"
                      />
                    </div>
                  ))
                ) : (
                  <div className="user-initial">
                    <img
                      src="/avtar.png"
                      alt="Avatar"
                      className="rounded-circle me-2"
                      width="30"
                      height="30"
                    />
                  </div>
                )}
              </div>
            );
          }
          
    
      case 'integer':
        return <input
        type="number"
        className="form-control no-border"
        defaultValue={typeof cell_data === 'object' ? '' : cell_data || ""}

        // defaultValue={cell_data || ""}
          
          onChange={(e) =>
            addCellDataSubrow(e.target.value, ID)
          }
      />;
        case 'file':
              /*eslint-disable*/
        const handleFileChange = async (e) => {
  const file = e.target.files[0];
  if (file) {
    const formData = new FormData();
    formData.append('cell_id', ID);
    formData.append('doc_file', file);
    formData.append('doc_type', 'sub');


    try {
      const response = await axios.post(`${process.env.REACT_APP_API_ROOT}/hotwash/add-doc-file/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      //console.log('File uploaded successfully', response.data);
      fetchDataRow(); // Refresh data after upload
    } catch (error) {
      //console.error('Error uploading file:', error);
    }
  }
};
    /*eslint-enable*/
const getFileSrcAndClass = (docFile) => {
  const extension = docFile.split('.').pop().toLowerCase();
  const imageExtensions = ['png', 'jpg', 'jpeg', 'svg', 'webp'];
  const fileext=['pdf']
  if (imageExtensions.includes(extension)) {
    return {
      src: `${process.env.REACT_APP_API_ROOT}/${docFile}`,
      className: 'image-file',
    };
  }
  if (fileext.includes(extension)) {
    return {
      src: 'https://cdn.monday.com/images/file-types/pdf_v2.svg',
      className: 'image-file',
    };
  }
  // src="https://cdn.monday.com/images/file-types/pdf_v2.svg"
  return {
    src: 'https://cdn.monday.com/images/file-types/unknown_v3.svg',
    className: 'doc-file',
  };
};

return (
  <div
    className={`cell-container ${docs && docs.length > 0 ? 'docs-present' : 'no-docs'}`}
    onClick={(event) => handleCellClickSubrow(event, groupIndex, rowIndex, columnIndex, header, row_id, column_type, ID, cell_data,subRowIndex,subColumnIndex)}
  >
    {docs && docs.length > 0 && (
      <div className="dots-container" >
        <BiDotsHorizontal className="dots-icon" onClick={(event) =>handleDotsClick(event,docs)} style={{cursor:'pointer'}}/>
      </div>
    )}
    <div className="docs-container">
      <ul className="doc-list">
        {docs?.map(doc => {
          const { src, className } = getFileSrcAndClass(doc.doc_file);
          return (
            <a href={`${process.env.REACT_APP_API_ROOT}/${doc.doc_file}`} key={doc.id} target="_blank" rel="noopener noreferrer">
              <img
                src={src}
                alt="Document"
                className={`doc-image ${className}`}
              />
            </a>
          );
        })}
      </ul>
      <label className="custom-file-upload">
        <FaPlus className="plus-icon" />
        <input
          type="file"
          onChange={handleFileChange}
          className="form-control no-border"
        />
      </label>
    </div>
  </div>
);
         
        
default:
  return (
    <div className="input-cell-container">
      <input
        type="text"
        className="form-control no-border text-input"
        defaultValue={typeof cell_data === 'object' ? '' : cell_data || ""}
        onChange={(e) => addCellDataSubrow(e.target.value, ID)}
      />
    </div>
  );

    }
  };
  // const moveColumn = (dragIndex, hoverIndex) => {
  //   const newGroups = [...groups];
  //   const [removed] = newGroups[0].rows[0].columns.splice(dragIndex, 1);
  //   newGroups[0].rows[0].columns.splice(hoverIndex, 0, removed);
  //   setGroups(newGroups);
  // };
 
  const handleDropdownSelect = async (status) => {
    const { groupIndex, rowIndex, columnIndex, row_id, column_type, ID ,selectedRowsData} = dropdownMenu;

    // Update the cell value and background color
    updateDropdownCellValue(groupIndex, rowIndex, columnIndex, status, row_id, column_type, ID,selectedRowsData);
    
    // Close the dropdown menu
    setDropdownMenu({ visible: false });
};
const handleDropdownSelectSubrow = async (status) => {
  const { groupIndex, rowIndex, columnIndex, row_id, column_type, ID,subRowIndex,subColumnIndex } = dropdownMenuSubrow;

  // Update the cell value and background color
  updateDropdownCellValueSubrow(groupIndex, rowIndex, columnIndex, status, row_id, column_type, ID,subRowIndex,subColumnIndex);
  
  // Close the dropdown menu
  setDropdownMenuSubrow({ visible: false });
};
// const handleStatusTextChange = (index, newText) => {
//   const updatedOptions = [...selectedOptions];
//   updatedOptions[index] = {
//     ...updatedOptions[index],
//     status_text: newText,
//   };
//   setSelectedOptions(updatedOptions);
// };


    /*eslint-disable*/
const handleMultiSelectDropdown = (status) => {
  const { groupIndex, rowIndex, columnIndex, row_id, column_type, ID } = multiselectDropdownMenu;

  // Get the selected options for the current row (based on row_id)
  const currentRowSelectedOptions = selectedOptionsByRow[row_id] || [];

  const existingIndex = currentRowSelectedOptions.findIndex(option => option.id === status.id);

  let updatedSelectedOptions;
  if (existingIndex > -1) {
    // Option is already selected, so remove it
    updatedSelectedOptions = currentRowSelectedOptions.filter(option => option.id !== status.id);
  } else {
    // Option is not selected, so add it
    updatedSelectedOptions = [...currentRowSelectedOptions, status];
  }

  // Update the state for selected options for the specific row
  setSelectedOptionsByRow((prev) => ({
    ...prev,
    [row_id]: updatedSelectedOptions,
  }));

  // Prepare the array of selected status objects with id and status_text
  const selectedStatuses = updatedSelectedOptions.map(option => ({
    id: option.id,
    status_text: option.status_text,
  }));

  // Update the cell value directly
  const selectedTexts = selectedStatuses.map(option => option.status_text).join(', ');
  updateMultiSelectDropdownCellValue(groupIndex, rowIndex, columnIndex, selectedStatuses, row_id, column_type, ID,selectedRowsData);
};
    /*eslint-enable*/
const handleRemoveSelectedOption = (optionId) => {
  const { groupIndex, rowIndex, columnIndex, row_id, column_type, ID } = multiselectDropdownMenu;

  // Filter out the option to be removed
  const updatedSelectedOptions = selectedOptions.filter(option => option.id !== optionId);

  // Update the selected options state
  setSelectedOptions(updatedSelectedOptions);

  // Update the cell value directly
  const selectedTexts = updatedSelectedOptions.map(option => option.status_text).join(', ');
  updateMultiSelectDropdownCellValue(groupIndex, rowIndex, columnIndex, selectedTexts, row_id, column_type, ID,selectedRowsData);
};
const handleMultiSelectDropdownSubrow = (status) => {
  const { groupIndex, rowIndex, columnIndex, row_id, column_type, ID, subRowIndex } = multiselectDropdownMenuSubrow;

  // Get the selected options for the current sub-row (based on row_id and subRowIndex)
  const currentSubRowSelectedOptions = selectedOptionsBySubRow[row_id] && selectedOptionsBySubRow[row_id][subRowIndex] ? selectedOptionsBySubRow[row_id][subRowIndex] : [];

  const existingIndex = currentSubRowSelectedOptions.findIndex(option => option.id === status.id);

  let updatedSelectedOptions;
  if (existingIndex > -1) {
    // Option is already selected, so remove it
    updatedSelectedOptions = currentSubRowSelectedOptions.filter(option => option.id !== status.id);
  } else {
    // Option is not selected, so add it
    updatedSelectedOptions = [...currentSubRowSelectedOptions, status];
  }

  // Update the state for selected options for the specific sub-row
  setSelectedOptionsBySubRow((prev) => ({
    ...prev,
    [row_id]: {
      ...prev[row_id],
      [subRowIndex]: updatedSelectedOptions,
    },
  }));

  // Prepare the array of selected status objects with id and status_text
  const selectedStatuses = updatedSelectedOptions.map(option => ({
    id: option.id,
    status_text: option.status_text,
  }));

  // Update the cell value directly
  updateMultiSelectDropdownCellValueSubrow(groupIndex, rowIndex, columnIndex, selectedStatuses, row_id, column_type, ID, subRowIndex);
};


const getSelectedLayout = () => {
  switch (selectedLayoutOption) {
    case 'kanban':
      return (
        <>
          <FaColumns /> Kanban
        </>
      );
    case 'gantt':
      return (
        <>
          <AiOutlineSchedule /> Gantt Board
        </>
      );
      case 'chart':
        return (
          <>
            <FaChartBar/>Chart
          </>
        );
    default:
      return (
        <>
          <AiOutlineTable /> Table
        </>
      );
  }
};
    /*eslint-disable*/
const handleRemoveSelectedOptionSubrow = (optionId) => {
  const { groupIndex, rowIndex, columnIndex, row_id, column_type, ID ,cell_data,subRowIndex,subColumnIndex} = multiselectDropdownMenuSubrow;

  // Filter out the option to be removed
  const updatedSelectedOptions = selectedOptionsSubrow.filter(option => option.id !== optionId);

  // Update the selected options state
  setSelectedOptionsSubrow(updatedSelectedOptions);

  // Update the cell value directly
  const selectedTexts = updatedSelectedOptions.map(option => option.status_text).join(', ');
  updateMultiSelectDropdownCellValueSubrow(groupIndex, rowIndex, columnIndex, selectedTexts, row_id, column_type, ID);
};
    /*eslint-enable*/

const handleAddOrUpdateMultiDropdown = () => {
  // Prepare the options to be sent to the API
  const updatedOptions = editedMultiSelectOptions.map(option => ({
    ...option,
    sheet_id: sheetId,
    column_id: multivaluedropdown,
    dropdown_type: "main"

  }));
  // Add the new option if it's present and not already in the list
  if (newMultiSelectLabel.status_text ) {
    updatedOptions.push({
      ...newMultiSelectLabel,
      sheet_id: sheetId,
      column_id: multivaluedropdown,
      dropdown_type: "main"

    });
  }

  // Call the API to update or add the options
  fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/add-update-multidropdown/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updatedOptions),
  })
    .then(response => response.json())
    .then(data => {
      console.log('Data successfully updated:', data);
      // Refresh the status options or any necessary state after the update
      fetchDropdownOptions(); 
      setIsMultiSelectEditMode(false);
      setEditedMultiSelectOptions([]);
      setMultiSelectNewLabel({ id: 0, status_text: '' });
      setShowMultiSelectNewLabelInput(false);
    })
    .catch(error => console.error('Error:', error));
};
const handleAddOrUpdateMultiDropdownSubrow = () => {
  // Prepare the options to be sent to the API
  const updatedOptions = editedMultiSelectOptionsSubrow.map(option => ({
    ...option,
    sheet_id: sheetId,
    column_id: multivaluedropdownSubrow,
    dropdown_type: "sub"

  }));
  // Add the new option if it's present and not already in the list
  if (newMultiSelectLabel.status_text ) {
    updatedOptions.push({
      ...newMultiSelectLabel,
      sheet_id: sheetId,
      column_id: multivaluedropdownSubrow,
      dropdown_type: "sub"

    });
  }

  // Call the API to update or add the options
  fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/add-update-multidropdown/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updatedOptions),
  })
    .then(response => response.json())
    .then(data => {
      console.log('Data successfully updated:', data);
      // Refresh the status options or any necessary state after the update
      fetchDropdownOptionsSubrow(); 
      setIsMultiSelectEditMode(false);
      setEditedMultiSelectOptions([]);
      setMultiSelectNewLabel({ id: 0, status_text: '' });
      setShowMultiSelectNewLabelInput(false);
    })
    .catch(error => console.error('Error:', error));
};

const handleApplyMultiSelectChanges = async () => {
  const { groupIndex, rowIndex, columnIndex, row_id, column_type, ID,selectedRowsData } = multiselectDropdownMenu;

  // Call the function to add or update the dropdown options
  handleAddOrUpdateMultiDropdown();

  // Get the updated status texts from the selected options
  const selectedTexts = selectedOptions.map(option => option.status_text).join(', ');

  // Update the cell value with the selected and edited options
  updateMultiSelectDropdownCellValue(groupIndex, rowIndex, columnIndex, selectedTexts, row_id, column_type, ID,selectedRowsData);

  // Close the dropdown menu
  // setMultiselectDropdownMenu({ visible: false });
};
const handleApplyMultiSelectChangesSubrow = async () => {
  const { groupIndex, rowIndex, columnIndex, row_id, column_type, ID ,cell_data,subRowIndex,subColumnIndex} = multiselectDropdownMenuSubrow;

  // Call the function to add or update the dropdown options
  handleAddOrUpdateMultiDropdownSubrow();

  // Get the updated status texts from the selected options
  const selectedTexts = selectedOptionsSubrow.map(option => option.status_text).join(', ');

  // Update the cell value with the selected and edited options
  updateMultiSelectDropdownCellValueSubrow(groupIndex, rowIndex, columnIndex, selectedTexts, row_id, column_type, ID,cell_data,subRowIndex,subColumnIndex);

  // Close the dropdown menu
  // setMultiselectDropdownMenu({ visible: false });
};
// const updateMultiSelectDropdownCellValue = async (groupIndex, rowIndex, columnIndex, selectedTexts, row_id, column_type, ID) => {
//   const updatedGroups = [...groups];
//   updatedGroups[groupIndex].rows[rowIndex].columns[columnIndex].value = {
//     status_text: selectedTexts,
//   };

//   setGroups(updatedGroups);

//   const rowData = {
//     cell_data: {
//       status_text: selectedTexts,
//     }
//   };

//   try {
//     const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/cell/${ID}/`;
//     const response = await fetch(apiUrl, {
//       method: 'PATCH',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(rowData),
//     });

//     if (!response.ok) {
//       throw new Error('Failed to update cell data');
//     }

//     fetchDataRow();
//   } catch (error) {
//     console.error('Error updating cell data:', error);
//   }
// };
const updateMultiSelectDropdownCellValue = async (
  groupIndex,
  rowIndex,
  columnIndex,
  selectedStatuses,
  row_id,
  column_type,
  ID,
  selectedRowsData
) => {
  const updatedGroups = [...groups];

  if (selectedRowsData && selectedRowsData.length > 0) {
    // Handle multiple rows update
    const updatePromises = selectedRowsData.map(async (row) => {
      const matchingColumn = row.columns.find((column) => column.column_index === columnIndex);

      if (matchingColumn) {
        // Update the local state for each selected row
        const rowToUpdate = updatedGroups[groupIndex]?.rows.find((r) => r.id === row.id);
        if (rowToUpdate) {
          rowToUpdate.columns[columnIndex].value = selectedStatuses;
        }

        // Call API to update the cell
        const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/cell/${matchingColumn.id}/`;
        const response = await fetch(apiUrl, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ cell_data: selectedStatuses }),
        });

        if (!response.ok) {
          throw new Error(`Failed to update cell data for column ${matchingColumn.id}`);
        }

        return response.json();
      }

      return null;
    });

    try {
      // Wait for all updates to complete
      await Promise.all(updatePromises);
      console.log("Multiple dropdown cells updated successfully");
      //// setSelectedRowsData([])

    } catch (error) {
      console.error("Error updating multiple dropdown cells:", error);
    }
  } else {
    // Handle single cell update
    updatedGroups[groupIndex].rows[rowIndex].columns[columnIndex].value = selectedStatuses;

    try {
      const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/cell/${ID}/`;
      const response = await fetch(apiUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cell_data: selectedStatuses }),
      });

      if (!response.ok) {
        throw new Error("Failed to update cell data");
      }
// setSelectedRowsData([])
      console.log("Single dropdown cell updated successfully");
    } catch (error) {
      console.error("Error updating single dropdown cell:", error);
    }
  }

  // Update the local state
  setGroups(updatedGroups);
  fetchDataRow(); // Refresh data after updating
};

    /*eslint-disable*/
const updateMultiSelectDropdownCellValueSubrow = async (groupIndex, rowIndex, columnIndex, statusArray, row_id, column_type, ID, subRowIndex, subColumnIndex) => {
  const updatedGroups = [...groups];
  // updatedGroups[groupIndex]?.rows[rowIndex]?.sub_data[subRowIndex]?.columns[subColumnIndex].value={
  // // updatedGroups[groupIndex].rows[rowIndex].columns[columnIndex].value = {
  //   status_text: selectedTexts,
  // };

  // setGroups(updatedGroups);

  const rowData = {
    cell_data: statusArray,
  };

  try {
    const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/sub-cell/${ID}/`;
    const response = await fetch(apiUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(rowData),
    });

    if (!response.ok) {
      throw new Error('Failed to update cell data');
    }

    fetchDataRow();
  } catch (error) {
    console.error('Error updating cell data:', error);
  }
};
    /*eslint-enable*/
    const updateDropdownCellValue = async (
      groupIndex,
      rowIndex,
      columnIndex,
      status,
      row_id,
      column_type,
      ID,
      selectedRowsData
    ) => {
      try {
        console.log("selectedRowsData:", selectedRowsData);
    
        // Prepare the updatedGroups to reflect the change in the UI (if needed)
        const updatedGroups = [...groups];
        updatedGroups[groupIndex].rows[rowIndex].columns[columnIndex].value = {
          status_text: status.status_text,
          status_color: status.status_color,
          id: status?.id,
        };
        setGroups(updatedGroups);
    
        // Check if selectedRowsData exists and has rows, otherwise use the fallback logic
        if (selectedRowsData && selectedRowsData.length > 0) {
          // When selectedRowsData is available, update based on column and row IDs
          const matchingData = selectedRowsData
            .map((row) => {
              // Find the matching column in the current row
              const matchingColumn = row.columns.find(
                (column) => column.column_index === columnIndex
              );
              if (matchingColumn) {
                return {
                  columnId: matchingColumn.id,  // Collect column_id if column_index matches
                  rowId: row.id,                // Collect row_id for the current row
                };
              }
              return null;
            })
            .filter((data) => data !== null);  // Filter out null values
    
          console.log("matchingData", matchingData);
    
          // Prepare the payload for API call
          const rowData = {
            cell_data: {
              status_text: status.status_text,
              status_color: status.status_color,
              id: status?.id,
            },
          };
    
          // Loop over the matching data and update them using the API
          const updatePromises = matchingData.map(async ({ columnId, rowId }) => {
            const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/cell/${columnId}/`; // Use dynamic column ID in the URL
            const response = await fetch(apiUrl, {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                ...rowData,
                row_id: rowId, // Send the correct row_id in the payload
              }),
            });
    
            if (!response.ok) {
              throw new Error(`Failed to update column ${columnId} for row ${rowId}`);
            }
            return response.json();
          });
    
          // Wait for all updates to complete
          await Promise.all(updatePromises);
    
          // Refresh data after the batch update
          fetchDataRow();
          // setSelectedRowsData()
          console.log("Cell data updated successfully");
        } else {
          // Fallback logic when selectedRowsData is not available
          const rowData = {
            cell_data: {
              status_text: status.status_text,
              status_color: status.status_color,
              id: status?.id,
            },
          };
    
          const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/cell/${ID}/`;  // Use the single ID in the URL
          const response = await fetch(apiUrl, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(rowData),
          });
    
          if (!response.ok) {
            throw new Error('Failed to update cell data');
          }
    
          const data = await response.json();
          fetchDataRow();
          // setSelectedRowsData()
          console.log('Cell data updated successfully:', data);
        }
      } catch (error) {
        console.error('Error updating cell data:', error);
      }
    };
    
    
    
// const toggleLayout = () => {
//   setSelectedLayoutOption(prev => (prev === 'kanban' ? 'table' : 'kanban'));
// };
const updateDropdownCellValueSubrow = async (groupIndex, rowIndex, columnIndex, status, row_id, column_type, ID,subRowIndex,subColumnIndex) => {
  //console.log("Updating cell value for row_id:", row_id, "column_type:", column_type);

  // Update the cell value and background color
  const updatedGroups = [...groups];
  updatedGroups[groupIndex].rows[rowIndex].columns[columnIndex].value = {
    status_text: status.status_text,
      status_color: status.status_color,
      id:status?.id
  };

  setGroups(updatedGroups);

  // Prepare the data for the API call
  const rowData = {
      cell_data: {
          status_text: status.status_text,
          status_color: status.status_color,
          id:status?.id
          
      }
  };

  try {
      const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/sub-cell/${ID}/`;
      const response = await fetch(apiUrl, {
          method: 'PATCH',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(rowData),
      });

      if (!response.ok) {
          throw new Error('Failed to update cell data');
      }

      const data = await response.json();
      fetchDataRow()
      console.log('Cell data updated successfully:', data);
  } catch (error) {
      //console.error('Error updating cell data:', error);
  }
};
// console.log("groupstatusOptions",groupstatusOptions)
const toggleSubTable = (rowId) => {
  if (visibleSubTableRowId.includes(rowId)) {
    setVisibleSubTableRowId(visibleSubTableRowId.filter(id => id !== rowId));
  } else {
    setVisibleSubTableRowId([...visibleSubTableRowId, rowId]);
  }
  // if (visibleSubTableRowId === rowId) {
  //   setVisibleSubTableRowId(null); // Hide the subtable if it's already visible
  // } else {
  //   setVisibleSubTableRowId(rowId); // Show the subtable for the clicked row
  // }
};
// const handleAddRow = async (groupId) => {
  const handleAddSubItem = async(rowId,groupId) => {

  const groupToUpdate = groups.find(group => group.id === groupId);

    const payload = {
      main_row_id:rowId,

      // group_id: groupId,
      row_index: groupToUpdate.rows.length,
      sheet_id:sheetId,
      cell_data:''
    };

    try {
      // Make the API call to add the new row
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/add-sub-row/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      // Check if the response is successful
      if (response.ok) {
        fetchDataRow()
        // const data = await response.json();
// window.location.reload()
        // Update the state with the new row added to the specific group
        // setGroups(prevGroups =>
        //   prevGroups.map(group => {
        //     if (group.id === groupId) {
        //       return {
        //         ...group,
        //         rows: [...group.rows, newRow],
        //       };
        //     }
        //     return group;
        //   })
        // );
      } else {
        //console.error('Failed to add row:', response.statusText);
      }
    } catch (error) {
      //console.error('Error adding row:', error);
    }
  // }
};
// const handleAddSubItem = (rowId) => {
//   setGroups(prevGroups => {
//     return prevGroups.map(group => {
//       return {
//         ...group,
//         rows: group.rows.map(row => {
//           if (row.id === rowId) {
//             return {
//               ...row,
//               sub_data: [
//                 ...row.sub_data,
//                 {
//                   id: `new-${Date.now()}`,
//                   columns: row.sub_data[0].columns.map(column => ({
//                     ...column,
//                     cell_data: ''
//                   }))
//                 }
//               ]
//             };
//           }
//           return row;
//         })
//       };
//     });
//   });
// };
// const handleAddSubColumn = (rowId) => {
//   setGroups(prevGroups => {
//     return prevGroups.map(group => {
//       return {
//         ...group,
//         rows: group.rows.map(row => {
//           if (row.id === rowId) {
//             const newColumnId = `new-column-${Date.now()}`;
//             const newColumn = {
//               id: newColumnId,
//               name: 'New Column',
//               column_type: 'text',
//               cell_data: ''
//             };

//             return {
//               ...row,
//               sub_data: row.sub_data.map(subRow => ({
//                 ...subRow,
//                 columns: [...subRow.columns, newColumn]
//               }))
//             };
//           }
//           return row;
//         })
//       };
//     });
//   });
// };
// console.log("selectedStatus",groups)
const calculateTableWidth = () => {
  const defaultColumnWidth = 100;
  if (!groups || groups.length === 0) return `${defaultColumnWidth}px`;

  // Calculate total width of columns for the 0th row of the first group
  const totalWidth = groups[0]?.rows[0]?.columns.reduce((sum, col) => {
    return sum + (col.column_width || defaultColumnWidth);
  }, 0);

  return `${totalWidth}px`; // Convert to a string with 'px'
};

const tableWidth = calculateTableWidth();

  return (
    <>
    {loading ? (
      <Loader />
    ) : (
    <DndProvider backend={HTML5Backend} >
      <div className="sheet-wrapper" style={{ position: 'relative' }}>

      <div className='sheetcontainer table-scroll-container'         ref={sheetContainerRef}
       style={{
         overflowX: 'auto', // Enable horizontal scrolling
         whiteSpace: 'nowrap', // Prevent table elements from wrapping
         maxWidth: '100%', // Limit the scroll area to the container width
       }}>
      <div className='py-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: '20px', marginRight: '20px', marginBottom: '20px',boxShadow:'0 1px 3px rgba(0, 0, 0, 0.1)'  }}>
    <h2 style={{ textDecoration: 'underline' }}>{sheetName}</h2>
    <div className="" style={{ display: 'flex', alignItems: 'center',}}>
    <BiDotsHorizontal className="mx-2" onClick={toggleOptionsMenu}  style={{display:"none"}}/>
          {optionsMenuVisible && (
            <div ref={layoutRef} className='add-column-group frame-name' style={{ position: 'absolute', top: '84px', right: '20px',  }}>
              {!groupOptionsVisible && !layoutOptionsVisible ? (
                
                <>
                  <div onClick={toggleGroupOptions} className='menu-item'>Group by</div>
                  <div className='menu-item' onClick={() => {}} style={{ padding: '8px', cursor: 'pointer' }}>Sort</div>
                  <div  className='menu-item' onClick={() => {}} style={{ padding: '8px', cursor: 'pointer' }}>Filter</div>
                  {/* <div  className='menu-item' onClick={toggleLayoutOptions} style={{ padding: '8px', cursor: 'pointer' }}>Layout</div> */}
                </>
              ) : groupOptionsVisible ? (
                <>
                  <div className='menu-item' onClick={() => selectGroupOption('assignee')} style={{ padding: '8px', cursor: 'pointer' }}>Assignee</div>
                  <div className='menu-item' onClick={() => selectGroupOption('status')} style={{ padding: '8px', cursor: 'pointer' }}>Status</div>
                  <div className='menu-item' onClick={() => selectGroupOption('timeline')} style={{ padding: '8px', cursor: 'pointer' }}>Timeline</div>
                </>
              ) : (
                <>
                  <div className='menu-item' onClick={() => selectLayoutOption('kanban')} style={{ padding: '8px', cursor: 'pointer' }}>Kanban</div>
                  <div className='menu-item' onClick={() => selectLayoutOption('table')} style={{ padding: '8px', cursor: 'pointer' }}>Table</div>
                  <div className='menu-item' onClick={() => selectLayoutOption('timeline')} style={{ padding: '8px', cursor: 'pointer' }}>Timeline</div>
                  <div className='menu-item' onClick={() => selectLayoutOption('chart')} style={{ padding: '8px', cursor: 'pointer' }}>Chart</div>

                </>
              )}
            </div>
          )}
                {/* <button className="gantt-board-button" onClick={handleGanttButtonClick}> Gantt Board</button> */}

                <div className="group-by-toggle mx-2">
      <span className="group-by-label">Group By:</span>
      <span className="selected-layout" onClick={toggleMenuStatus}>
      {selectedStatus
          ? ` ${groupstatusOptions.find((option) => option.id === selectedStatus)?.name}`
          : 'Select'}
      </span>
      
      {dropdownOpen && (
        <div className="group-by-dropdown">
          <ul>
            {groupstatusOptions?.map((option) => (
              <li
                key={option.id}
                onClick={(event) => handleStatusSelect(option?.id, event)}
              >
                {option.name}
              </li>
            ))}
            <li onClick={handleClearFilter} style={{ fontWeight: 'bold', color: 'red' }}>
          Clear Filter
        </li>
          </ul>
        </div>
      )}
    </div>

                <div className="layout-toggle mx-2" ref={dropdownRef}>
      <span className="layout-label">Layout:</span>
      <span className="selected-layout" onClick={toggleMenu}>
        {getSelectedLayout()}
      </span>

      {isMenuOpen && (
        <div className="layout-dropdown">
          <ul>
            <li onClick={() => handleLayoutChange('table')}>
              <AiOutlineTable /> Table
            </li>
            <li onClick={() => handleLayoutChange('kanban')}>
              <FaColumns /> Kanban
            </li>
            <li onClick={() => handleLayoutChange('chart')}>
              <FaChartBar /> chart
            </li>
            <li onClick={() => handleLayoutChange('gantt')}>
              <AiOutlineSchedule /> Gantt Board
            </li>
          </ul>
        </div>
      )}
    </div>
          {/* <div className="layout-toggle mx-2" onClick={toggleLayout}>
        <span className="layout-label">Layout:</span>
        <span className={`layout-option ${selectedLayoutOption}`}>
          {selectedLayoutOption === 'kanban' ? 'Kanban' : 'Table'}
        </span>
      </div> */}
      <div onClick={openModal} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
  <img 
    //  src="/automation1.png"
     src={`${process.env.REACT_APP_TOS_PATH}/assets/svg/new/automation 2.png`}

    // src={require('./path/to/your/image.png')} 
    alt="Automate" 
    style={{ marginRight: '8px', width: '26px', height: '26px' }} 
  />
  <span className='layout-option'>Automate</span>
</div>

    
    <AutomationModal isOpen={isModalOpen} onClose={closeModal} globalSheetId={sheetId} isEditable={isEditable} canDelete={canDelete}
    />
    {/* {shouldShowKanbanBoard && (
        <div style={{ marginTop: '20px' }}>
          <KanbanViewBoard option={selectedGroupOption} />
        </div>
      )} */}
    </div>
  </div>
  <div>
  </div>

  {/* {selectedLayoutOption === 'gantt' ? (
  // Render GanttBoard when the layout option is 'gantboard'
  <GanttBoard
    timelineColumns={timelineColumns}
    selectedColumnId={selectedColumnId}
    setSelectedColumnId={setSelectedColumnId}
    fetchData={fetchDataRow}
          globalSheetId={sheetId}
          option={selectedLayoutOption}
          globalUserId={globalUserId}
          showDrawer={showDrawer} setShowDrawer={setShowDrawer} 
          offcanvasState={offcanvasState} setOffcanvasState={setOffcanvasState} selectedCard={selectedCard} 
          setSelectedCard={setSelectedCard} taskDetails={taskDetails} setTaskDetails={setTaskDetails}
          handleShow={handleShow}
          isEditable={isEditable}
          canDelete={canDelete}

  />
) : (
  <>
      {selectedLayoutOption === 'kanban' ? (
        // Render KanbanViewBoard when the layout option is 'kanban'
        <KanbanViewBoard
          fetchData={fetchDataRow}
          globalSheetId={sheetId}
          option={selectedLayoutOption}
          globalUserId={globalUserId}
          showDrawer={showDrawer} setShowDrawer={setShowDrawer} 
          offcanvasState={offcanvasState} setOffcanvasState={setOffcanvasState} selectedCard={selectedCard} 
          setSelectedCard={setSelectedCard} taskDetails={taskDetails} setTaskDetails={setTaskDetails}
          handleShow={handleShow}
          isEditable={isEditable}
          canDelete={canDelete|| true}
        />
      ) : ( */}
        
        {
  selectedLayoutOption === "gantt" ? (
    // Render GanttBoard when the layout option is 'gantt'
    <GanttBoard
      timelineColumns={timelineColumns}
      selectedColumnId={selectedColumnId}
      setSelectedColumnId={setSelectedColumnId}
      fetchData={fetchDataRow}
      globalSheetId={sheetId}
      option={selectedLayoutOption}
      globalUserId={globalUserId}
      showDrawer={showDrawer}
      setShowDrawer={setShowDrawer}
      offcanvasState={offcanvasState}
      setOffcanvasState={setOffcanvasState}
      selectedCard={selectedCard}
      setSelectedCard={setSelectedCard}
      taskDetails={taskDetails}
      setTaskDetails={setTaskDetails}
      handleShow={handleShow}
      isEditable={isEditable}
      canDelete={canDelete}
    />
  ) : selectedLayoutOption === "kanban" ? (
    // Render KanbanViewBoard when the layout option is 'kanban'
    <KanbanViewBoard
      fetchData={fetchDataRow}
      globalSheetId={sheetId}
      option={selectedLayoutOption}
      globalUserId={globalUserId}
      showDrawer={showDrawer}
      setShowDrawer={setShowDrawer}
      offcanvasState={offcanvasState}
      setOffcanvasState={setOffcanvasState}
      selectedCard={selectedCard}
      setSelectedCard={setSelectedCard}
      taskDetails={taskDetails}
      setTaskDetails={setTaskDetails}
      handleShow={handleShow}
      isEditable={isEditable}
      canDelete={canDelete || true}
    />
  ) : selectedLayoutOption === "chart" ? (
    // Render ChartLayout when the layout option is 'chart'
    <ChartLayout
      // fetchData={fetchDataRow}
      sheetId={sheetId}
      // option={selectedLayoutOption}
      globalUserId={globalUserId}
      setSelectedLayoutOption={setSelectedLayoutOption}
      // chartData={chartData}
      // setChartData={setChartData}
      // handleShow={handleShow}
      // isEditable={isEditable}
    />
  ) : (
  groups?.map((group, groupIndex) => (
    
    <div key={group.id} style={{ marginLeft: '20px' }} className="my-2">
      <div  className='px-2 py-1' style={{width:'70%',backgroundColor:'white'}}> {renderGroupName(group)}</div>
      {/* <div className="table-container" style={{ display: collapsedGroups[group.id] ? 'none' : 'block' }}> */}
      <div  className={!isEditable ? 'table-disabled' : ''}  style={{ display: collapsedGroups[group.id] ? 'none' : 'block' }}>

      <div
    //    <div
    //    className="table-scroll-container"
    //    style={{
    //      overflowX: 'auto', // Enable horizontal scrolling
    //      whiteSpace: 'nowrap', // Prevent table elements from wrapping
    //      maxWidth: '100%', // Limit the scroll area to the container width
    //    }}
    //  >    
    >    
<table className="table" ref={tableRef}  style={{ width: tableWidth }}>

<thead>
<tr style={{padding:'2px !important'}}>
<th  className="fixed-column" style={{   width: '60px' }}>  <input
                  type="checkbox"
                  style={{ width: '28%', height: '30px' }}
                  checked={
                    group.rows.slice(1).every(row => selectedTasks.includes(row.id)) // Check if all rows in the group are selected
                  }
                  onChange={(e) => handleSelectAllInGroup(group.id, e.target.checked)} // Select all or deselect all
                /></th>
{group.rows[0]?.columns.map((header, index) => (
 <DraggableColumn
 key={header.id}
 header={header}
 columnWidth={columnWidths[header.id] || 100} 
 // onResize={handleColumnResize}
//  fetchRowData={fetchDataRow}
 setColumnWidths={setColumnWidths}
 fetchData={fetchDataRow}
 setShowColumnConfirmation={setShowColumnConfirmation}
 setDeletingColumn={setDeletingColumn}
 canDelete={canDelete}
/>

))}
<th style={{ position: 'relative',  width: '60px' }}>
 <BiPlus size={24} onClick={handleColumnOptionsClick} />
</th>
</tr>
</thead>
<tbody>
{group.rows?.slice(1).map((row, rowIndex) => (
<React.Fragment key={row.id}>
<tr

 onMouseEnter={() => setHoveredRowId(row.id)}
 onMouseLeave={() => setHoveredRowId(null)}
 style={{}}
>
 <td style={{ position: 'relative', padding: '0' }}>
<input
type="checkbox"
checked={selectedTasks.includes(row.id)}
onChange={() => handleCheckboxChange(row.id, group?.id, row)}
style={{
width: '28%',    // Increase width to 80% of the <td>
height: '30px',  // Increase height as needed
margin: '5px',   // Adjust margin as needed for spacing
}}
/>

{/* {hoveredRowId === row.id && (
<BiDotsHorizontal
className="three-dots"
style={{
cursor: 'pointer',
position: 'absolute',
right: '48px',  // Position the dots outside the left side of the <td>
top: '50%',
transform: 'translateY(-50%)',
}}
onClick={() => handleAddRowMenuClick(row.id)}
/>
)} */}

{openMenuRowId === row?.id && isRowMenuVisible && (
<div ref={rowMenuRef} className="add-column-group frame-name">
<p className="menu-item" onClick={() => handleDeleteRow(hoveredRowId)}>
<BiTrash className="me-2" />Delete
</p>
</div>
)}
</td>

 {row.columns.map((header, columnIndex) => (
   <td key={columnIndex}     style={{ backgroundColor: header?.cell_data?.status_color || '', ...(columnIndex === 0 && { display: 'flex', alignItems: 'center' ,minHeight:'55px'}), // Set background color here
    
     
      }}// Set background color here
>
{columnIndex === 0 && (
<>
   <BiChevronDown
     onClick={() => toggleSubTable(row.id)}
     style={{ cursor: 'pointer', marginLeft: '8px' }}
   />
<p style={{ 
padding: '10px 0', 
margin: 0,
cursor: 'pointer' ,
height: '100%', 
fontWeight: '600', 
color: '#B0B0B0',  display: 'flex', 
alignItems: 'center'
}}
onClick={() => toggleSubTable(row.id)}
>
({row?.total_subtask})
</p>
<div className="open-button-container">
                <button className="open-button"  onClick={() => handleCardClick(header.id)}>Open</button>
              </div>
</>
 )}
     {renderInputByType(header, groupIndex, rowIndex + 1, columnIndex, row.id, group, header?.column_type, header?.id, header?.cell_data,header?.docs, group.rows[0].columns[columnIndex])}
    
   </td>
 ))}
 <td></td>
</tr>
{visibleSubTableRowId.includes(row.id)  && (
<tr>
<td colSpan={group.rows[0]?.columns.length + 2} className="subtable-container">
<div className="horizontal-line"></div>

<table className="table table-sm sub-table my-2 mx-4">
<thead>
<tr>
<th style={{ width: '60px' }}>
<th style={{ width: '60px' }}>
<input
                type="checkbox"
                checked={
                  row.sub_data.slice(1).every(subRow => selectedsubTasks.includes(subRow.id)) // Check if all sub-rows of this row are selected
                }
                onChange={(e) => handleSelectAllInSubRow(row.id, e.target.checked)} // Select/Deselect all sub-rows for this row
              />
            </th></th>

{row.sub_data[0]?.columns.map((subHeader, subIndex) => (
<DraggableSubColumn
key={subHeader.id}
subHeader={subHeader}
subColumnWidth={subColumnWidths[subHeader.id] || 200} // Provide a default value if undefined
onResizeSubColumn={handleSubColumnResize} // Define this function to update state
fetchData={fetchDataRow}
/>
))}
<th style={{ width: '60px' }}>
<BiPlus size={24} onClick={handleColumnOptionsClickSubrow} style={{ position: 'relative' }} />
</th>
</tr>
</thead>
<tbody>
{/* {group.rows?.slice(1).map((row, rowIndex) => ( */}

{row.sub_data?.slice(1)?.map((subRow, subRowIndex) => (
<tr
key={subRow.id}
onMouseEnter={() => setHoveredSubRowId(subRow.id)}
onMouseLeave={() => setHoveredSubRowId(null)}
>
<td style={{ position: 'relative', padding: '0' }}>
<div style={{ width: '80%', display: 'inline-block' }}>
<input
type="checkbox"
checked={selectedsubTasks.includes(subRow.id)}
onChange={() => handleCheckboxChangeSubrow(subRow.id, group?.id)}
style={{ width: '100%', height: '100%', margin: '0' }}
/>
</div>

{hoveredSubRowId === subRow.id && (
<BiDotsHorizontal
className="three-dots"
style={{
cursor: 'pointer',
position: 'absolute',
left: '-20px', // Position the dots outside the left side of the <td>
top: '50%',
transform: 'translateY(-50%)',
}}
onClick={() => handleAddSubRowMenuClick(subRow.id)}
/>
)}

{openMenuSubRowId === subRow?.id && isSubRowMenuVisible && (
<div ref={subRowMenuRef} className="add-column-group frame-name">
<p className="menu-item" onClick={() => handleDeleteSubRow(hoveredSubRowId)}>
<BiTrash className="me-2" />Delete
</p>
</div>
)}
</td>

{subRow.columns.map((subCell, subCellIndex) => (
<td key={subCell.id} style={{ backgroundColor: subCell?.cell_data?.status_color || '' }}>
 {renderInputByTypeSubrow(
   subCell,
   groupIndex,
   rowIndex + 1,
   subCellIndex,
   subRow.id,
   row.sub_data,
   subCell?.column_type,
   subCell?.id,
   subCell?.cell_data,
   subCell?.docs,
   subRowIndex,
   subCellIndex,
   group.rows[1].sub_data[0]?.columns[subCellIndex]
 )}
</td>
))}
<td></td>
</tr>
))}
<tr>
<td></td>
<td colSpan={row.sub_data[0]?.columns.length + 1} className="text-center">
<button
type="button"
className="btn btn-sm"
onClick={() => handleAddSubItem(row.id, group.id)}
style={{ float: "left" }}
>
<BiPlus /> Add Sub Item
</button>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
)}

</React.Fragment>
))}
<tr>
<td></td>
<td colSpan={groups[0]?.rows[0]?.columns.length + 1} className="text-center">
 <button
   type="button"
   className="btn btn-sm"
   onClick={() => handleAddRow(group.id)}
   style={{ float: "left" }}
 >
   <BiPlus /> Add Row
 </button>
</td>
</tr>
</tbody>
</table>
</div>
       {/* )
      } */}
      
    </div>
      {/* {menuVisible && (
        <ColumnOptionsMenu
          onSelect={handleAddColumnClick}
          onClose={() => setMenuVisible(false)}
          ref={menuRef}
          style={{
            position: 'absolute',
           
          }}
          
        />
      )} */}
{selectedTasks.length > 0 && (
    <TaskSelectionStripe
      selectedCount={selectedTasks.length}
      selectedTaskIds={selectedTasks} // Pass selected tasks to the stripe
      onClose={handleClose}
      fetchData={fetchDataRow}
      sheetId={sheetId}
      currentGroupId={currentGroupId}
      canDelete={canDelete}
      isEdit={setIsEditable}
      setSelectedRowsData={setSelectedRowsData}
      setSelectedTasks={setSelectedTasks}
  
    />
  )}
  {selectedsubTasks.length > 0 && (
    <TaskSelectionStripeSubrow
      selectedCount={selectedsubTasks.length}
      selectedTaskIds={selectedsubTasks} // Pass selected tasks to the stripe
      onClose={handleCloseSubrow}
      fetchData={fetchDataRow}
      sheetId={sheetId}
      currentGroupId={currentGroupId}
    />
  )}
      {menuVisible && (
<ColumnOptionsMenu
onSelect={handleAddColumnClick}
onClose={() => setMenuVisible(false)}
ref={menuRef}
style={{
  position: 'absolute',
  top: `${menuPosition.top+10}px`,
  left: `${menuPosition.left - 280}px`,    }}
/>
)}

         {menuVisibleSubrow && (
        <ColumnOptionsMenuSubrow 
          onSelect={handleAddColumnClickSubrow}
          onClose={() => setMenuVisibleSubrow (false)}
          ref={menuRef}
          style={{
            position: 'absolute',
            top: `${menuPositionSubrow.top+10}px`,
            left: `${menuPositionSubrow.left - 280}px`,    }}
        
        />
      )}
        {/* {userMenu.visible && (
    <div
    ref={userMenuRef}
      className="column-options-menu"
      style={{
        top: userMenu.position.top-50,
        left: userMenu.position.left-370,
      }}
    >
      <div className="column-options-content">
        {user_data?.user_data?.map(user => (
           <div key={user.id} className="column-option" onClick={() => handleUserSelect(user)}>
           <div className="user-initial">
           <img
              src={user?.avatar||`${staticPath}/${user?.file_name}`}
              alt='user'
              className="rounded-circle me-2"
              width="30"
              height="30"
            />
           </div>
           <span>{user.name}</span>
         </div>
        ))}
      </div>
    </div>
  )} */}
 {userMenu?.visible && (
  <div
    ref={userMenuRef}
    className="column-options-menu"
    style={{
      top: userMenu.position.top - 50,
      left: userMenu.position.left - 370,
    }}
  >
    <div className="column-options-content">
      {/* Search Bar */}
      <div className="search-bars">
        <input
          type="text"
          placeholder="Search users..."
          onChange={(e) => setSearchQuery(e.target.value)}
          className="form-control mb-2"
        />
      </div>

      <div className="assigned-users-section">
  <h5>Assigned</h5>
  {Array.isArray(userMenu?.cell_data) && userMenu.cell_data.length > 0 ? (
    userMenu?.cell_data?.map((user) => (
      <div key={user.id} className="user-tag d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <img
            src={user?.avatar || `${staticPath}/${user?.file_name}`}
            alt="user"
            className="rounded-circle me-2"
            width="30"
            height="30"
          />
          <span>{user?.user || user?.name}</span>
        </div>
        <button
          className="remove-btn"
          onClick={() => handleRemoveUser(user)}
          aria-label="Remove user"
        >
          <span className="remove-icon">✕</span> {/* Crossmark icon */}
        </button>
      </div>
    ))
  ) : (
    <p>No users assigned</p>
  )}
</div>


      {/* Suggestions */}
      <div className="suggestions-section">
        <h5>Suggestions</h5>
        {user_data?.user_data
          ?.filter(
            (user) =>
              // Check if userMenu.cell_data is an array and it's empty or user is not assigned
              (!Array.isArray(userMenu?.cell_data) || !userMenu.cell_data.some((assigned) => assigned.id === user.id)) &&
              // Filter by search query if provided
              (!searchQuery || user.name.toLowerCase().includes(searchQuery.toLowerCase()))
          )
          .map((user) => (
            <div
              key={user.id}
              className="column-option d-flex align-items-center"
              onClick={() => handleUserSelect(user)}
            >
              <img
                src={user?.avatar || `${staticPath}/${user?.file_name}`}
                alt="user"
                className="rounded-circle me-2"
                width="30"
                height="30"
              />
              <span>{user.name}</span>
            </div>
          ))}
      </div>

    </div>
  </div>
)}


   {userMenuSubrow.visible && (
    <div
    ref={userMenuRef}
      className="column-options-menu"
      style={{
        top: userMenuSubrow.position.top,
        left: userMenuSubrow.position.left,
      }}
    >
      <div className="column-options-content">
        {user_data?.user_data?.map(user => (
           <div key={user.id} className="column-option" onClick={() => handleUserSelectSubrow(user)}>
           <div className="user-initial">
           <img
              src={user?.avatar||`${staticPath}/${user?.file_name}`}
              alt='user'
              className="rounded-circle me-2"
              width="30"
              height="30"
            />
           </div>
           <span>{user.name}</span>
         </div>
        ))}
      </div>
    </div>
  )}
   <Modal show={showModal} onHide={() => setShowModal(false)}>
    <Modal.Header closeButton>
      <Modal.Title>Documents List</Modal.Title>
    </Modal.Header>
    <Modal.Body>
     
      <div className="selected-users d-flex flex-wrap mb-3">
      {selectedDocs.map(doc => (
<div key={doc.id} className="selected-user d-flex align-items-center me-2 mb-2">
{isImageFile(doc.doc_file) ? (
  <img
    src={`${process.env.REACT_APP_API_ROOT}${doc.doc_file}`}
    alt={"doc"}
    className=" me-1"
    width="20"
    height="20"
  />
) : (
  <img
    src="https://cdn.monday.com/images/file-types/unknown_v3.svg"
    alt={"doc"}
    className=" me-1"
    width="20"
    height="20"
  />
)}
<span>
  <a href={`${process.env.REACT_APP_API_ROOT}${doc.doc_file}`} target="_blank" rel="noopener noreferrer">
    {doc.doc_file.split('/').pop()} {/* Display only the file name */}
  </a>
</span>
<BsX className="ml-1 cursor-pointer remove-icon" onClick={() => handleDeleteDoc(doc.id)} />
</div>
))}


      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
    </Modal.Footer>
  </Modal>
  <TaskDetailsOffcanvas showDrawer={showDrawer} handleShow={handleShow} taskDetails={taskDetails} setTaskDetails={setTaskDetails} selectedCard={selectedCard} fetchData={fetchDataRow} setOffcanvasState={setOffcanvasState} offcanvasState={offcanvasState} globalUserId={globalUserId}/>

{
dropdownMenu.visible && (
<div
  ref={dropdownMenuRef}
  className="custom-dropdown-menu"
  style={{
    top: dropdownMenu.position.top,
    left: dropdownMenu.position.left,
  }}
>
  <div className="custom-dropdown-content">
    {statusOptions.map((option, index) => (
      <div
        key={option.id}
        className={`custom-dropdown-option ${isEditMode ? 'edit-mode' : ''}`}
        onClick={() => !isEditMode && handleDropdownSelect(option)}
        style={{
          backgroundColor: !isEditMode ? option.status_color : 'transparent',
          border: isEditMode ? '1px solid #ccc' : 'none',
        }}
      >
        {isEditMode ? (
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <input
              type="text"
              defaultValue={option.status_text}
              onChange={(e) => handleInputChange(e, option.id)}
              className="edit-input"
              style={{ flexGrow: 1 }}
            />
            <div
              className="color-square"
              style={{
                width: '24px',
                height: '24px',
                backgroundColor: option.status_color,
                cursor: 'pointer',
                marginLeft: '8px',
                borderRadius: '4px',
              }}
              onClick={() => setColorPickerVisible(option.id)}
            />
            {colorPickerVisible === option.id && (
              <div style={{ position: 'absolute', zIndex: 1000 }}>
                <SketchPicker
                  color={option.status_color}
                  onChangeComplete={(color) => {
                    handleColorChange(color, option.id);
                    setColorPickerVisible(null);
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          <span>{option.status_text}</span>
        )}
      </div>
    ))}
    {showNewLabelInput && (
      <div className="custom-dropdown-option edit-mode" style={{ border: '1px solid #ccc' }}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <input
            type="text"
            value={newLabel.status_text}
            onChange={(e) => handleInputChange(e, 0)}
            className="edit-input"
            style={{ flexGrow: 1 }}
          />
          <div
            className="color-square"
            style={{
              width: '28px',
              height: '24px !important',
              backgroundColor: newLabel.status_color,
              cursor: 'pointer',
              marginLeft: '8px',
              borderRadius: '4px',
            }}
            onClick={() => setColorPickerVisible(0)}
          />
          {colorPickerVisible === 0 && (
            <div style={{ position: 'absolute', zIndex: 1000 }}>
              <SketchPicker
                color={newLabel.status_color}
                onChangeComplete={(color) => {
                  handleColorChange(color, 0);
                  setColorPickerVisible(null);
                }}
              />
            </div>
          )}
        </div>
      </div>
    )}
    <div className="custom-divider"></div>
    <div className="custom-edit-label" onClick={isEditMode ? handleApplyChanges : handleEditClick}>
      {isEditMode ? <FaCheck /> : <FaPencilAlt />}
      <span className="mx-1">{isEditMode ? 'Apply Changes' : 'Edit Labels'}</span>
    </div>
    {isEditMode && (
      <div className="custom-add-new-label" onClick={handleAddNewLabelClick}>
        <FaPlus />
        <span className="mx-1">Add New Label</span>
      </div>
    )}
  </div>
</div>
)

}
{
dropdownMenuSubrow.visible && (
<div
  ref={dropdownMenuRef}
  className="custom-dropdown-menu"
  style={{
    top: dropdownMenuSubrow.position.top,
    left: dropdownMenuSubrow.position.left,
  }}
>
  <div className="custom-dropdown-content">
    {statusOptionsSubrow.map((option, index) => (
      <div
        key={option.id}
        className={`custom-dropdown-option ${isEditMode ? 'edit-mode' : ''}`}
        onClick={() => !isEditMode && handleDropdownSelectSubrow(option)}
        style={{
          backgroundColor: !isEditMode ? option.status_color : 'transparent',
          border: isEditMode ? '1px solid #ccc' : 'none',
        }}
      >
        {isEditMode ? (
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <input
              type="text"
              defaultValue={option.status_text}
              onChange={(e) => handleInputChangeSubrow(e, option.id)}
              className="edit-input"
              style={{ flexGrow: 1 }}
            />
            <div
              className="color-square"
              style={{
                width: '24px',
                height: '24px',
                backgroundColor: option.status_color,
                cursor: 'pointer',
                marginLeft: '8px',
                borderRadius: '4px',
              }}
              onClick={() => setColorPickerVisible(option.id)}
            />
            {colorPickerVisible === option.id && (
              <div style={{ position: 'absolute', zIndex: 1000 }}>
                <SketchPicker
                  color={option.status_color}
                  onChangeComplete={(color) => {
                    handleColorChangeSubrow(color, option.id);
                    setColorPickerVisible(null);
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          <span>{option.status_text}</span>
        )}
      </div>
    ))}
    {showNewLabelInput && (
      <div className="custom-dropdown-option edit-mode" style={{ border: '1px solid #ccc' }}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <input
            type="text"
            value={newLabelSubrow.status_text}
            onChange={(e) => handleInputChangeSubrow(e, 0)}
            className="edit-input"
            style={{ flexGrow: 1 }}
          />
          <div
            className="color-square"
            style={{
              width: '28px',
              height: '24px !important',
              backgroundColor: newLabelSubrow.status_color,
              cursor: 'pointer',
              marginLeft: '8px',
              borderRadius: '4px',
            }}
            onClick={() => setColorPickerVisible(0)}
          />
          {colorPickerVisible === 0 && (
            <div style={{ position: 'absolute', zIndex: 1000 }}>
              <SketchPicker
                color={newLabelSubrow.status_color}
                onChangeComplete={(color) => {
                  handleColorChangeSubrow(color, 0);
                  setColorPickerVisible(null);
                }}
              />
            </div>
          )}
        </div>
      </div>
    )}
    <div className="custom-divider"></div>
    <div className="custom-edit-label" onClick={isEditMode ? handleApplyChangesSubrow : handleEditClick}>
      {isEditMode ? <FaCheck /> : <FaPencilAlt />}
      <span className="mx-1">{isEditMode ? 'Apply Changes' : 'Edit Labels'}</span>
    </div>
    {isEditMode && (
      <div className="custom-add-new-label" onClick={handleAddNewLabelClick}>
        <FaPlus />
        <span className="mx-1">Add New Label</span>
      </div>
    )}
  </div>
</div>
)

}

{
multiselectDropdownMenu.visible && (
<div
  ref={multiselectDropdownMenuRef}
  className="custom-dropdown-menu"
  style={{
    top: multiselectDropdownMenu.position.top,
    left: multiselectDropdownMenu.position.left,
  }}
>
  <div className="custom-dropdown-content">
    <div className="selected-options">
      {selectedOptions.map((option) => (
        <div key={option.id} className="selected-option">
          <span>{option.status_text}</span>
          <FaTimes onClick={() => handleRemoveSelectedOption(option.id)} />
        </div>
      ))}
    </div>
    {dropdownOptions?.map((option) => (
      <div
        key={option.id}
        className={`custom-dropdown-options ${isMultiSelectEditMode ? 'edit-mode' : ''}`}
        onClick={() => !isMultiSelectEditMode && handleMultiSelectDropdown(option)}
        style={{
          // backgroundColor: selectedOptions.some(o => o.id === option.id) ? option.status_color : 'transparent',
          // border: isMultiSelectEditMode ? '1px solid #ccc' : 'none',
        }}
      >
        {isMultiSelectEditMode ? (
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <input
              type="text"
              defaultValue={option.status_text}
              onChange={(e) => handleMultiSelectInputChange(e, option.id)}
              className="edit-input"
              style={{ flexGrow: 1 }}
            />
          </div>
        ) : (
          <span>{option.status_text}</span>
        )}
      </div>
    ))}
    {showMultiSelectNewLabelInput && (
      <div className="custom-dropdown-options edit-mode" style={{ border: '1px solid #ccc' }}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <input
            type="text"
            value={newMultiSelectLabel.status_text}
            onChange={(e) => handleMultiSelectInputChange(e, 0)}
            className="edit-input"
            style={{ flexGrow: 1 }}
          />
        </div>
      </div>
    )}
    <div className="custom-divider"></div>
    <div className="custom-edit-label" onClick={isMultiSelectEditMode ? handleApplyMultiSelectChanges : handleMultiSelectEditClick}>
      {isMultiSelectEditMode ? <FaCheck /> : <FaPencilAlt />}
      <span className="mx-1">{isMultiSelectEditMode ? 'Apply Changes' : 'Edit Labels'}</span>
    </div>
    {isMultiSelectEditMode && (
      <div className="custom-add-new-label" onClick={handleAddMultiSelectNewLabelClick}>
        <FaPlus />
        <span className="mx-1">Add New Label</span>
      </div>
    )}
  </div>
</div>
)
}
{
  multiselectDropdownMenuSubrow.visible && (
    <div
      ref={multiselectDropdownMenuRef}
      className="custom-dropdown-menu"
      style={{
        top: multiselectDropdownMenuSubrow.position.top,
        left: multiselectDropdownMenuSubrow.position.left,
      }}
    >
      <div className="custom-dropdown-content">
        {/* <div className="selected-options">
          {selectedOptionsBySubRow[multiselectDropdownMenuSubrow.row_id]?.[multiselectDropdownMenuSubrow.subRowIndex]?.map((option) => (
            <div key={option.id} className="selected-option">
              <span>{option.status_text}</span>
              <FaTimes onClick={() => handleRemoveSelectedOptionSubrow(option.id)} />
            </div>
          )) || null}
        </div> */}
        {dropdownOptionsSubrow?.map((option) => (
          <div
            key={option.id}
            className={`custom-dropdown-options ${isMultiSelectEditMode ? 'edit-mode' : ''}`}
            onClick={() => !isMultiSelectEditMode && handleMultiSelectDropdownSubrow(option)}
          >
            {isMultiSelectEditMode ? (
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <input
                  type="text"
                  defaultValue={option.status_text}
                  onChange={(e) => handleMultiSelectInputChangeSubrow(e, option.id)}
                  className="edit-input"
                  style={{ flexGrow: 1 }}
                />
              </div>
            ) : (
              <span>{option.status_text}</span>
            )}
          </div>
        ))}
        {showMultiSelectNewLabelInput && (
          <div className="custom-dropdown-options edit-mode" style={{ border: '1px solid #ccc' }}>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <input
                type="text"
                value={newMultiSelectLabel.status_text}
                onChange={(e) => handleMultiSelectInputChangeSubrow(e, 0)}
                className="edit-input"
                style={{ flexGrow: 1 }}
              />
            </div>
          </div>
        )}
        <div className="custom-divider"></div>
        <div className="custom-edit-label" onClick={isMultiSelectEditMode ? handleApplyMultiSelectChangesSubrow : handleMultiSelectEditClick}>
          {isMultiSelectEditMode ? <FaCheck /> : <FaPencilAlt />}
          <span className="mx-1">{isMultiSelectEditMode ? 'Apply Changes' : 'Edit Labels'}</span>
        </div>
        {isMultiSelectEditMode && (
          <div className="custom-add-new-label" onClick={handleAddMultiSelectNewLabelClick}>
            <FaPlus />
            <span className="mx-1">Add New Label</span>
          </div>
        )}
      </div>
    </div>
  )
}


    </div>
    
  
    
        
  )

))}
   <div className="add-group-container d-flex justify-content-between align-items-center mx-4 my-2">
  {/* Left: Add Group Button */}
  {selectedLayoutOption === 'kanban' || selectedLayoutOption === 'gantt' || selectedLayoutOption === 'chart' ? (
    ''
  ) : (
    <OverlayTrigger
      placement="top"
      overlay={!isEditable ? renderTooltip : <></>}
    >
      <span className="d-inline-block">
        <button
          type="button"
          className={!isEditable ? 'table-disabled btn btn-outline-primary btn-sm' : 'btn btn-outline-primary btn-sm'}
          onClick={isEditable ? handleAddGroup : null}
          style={{ pointerEvents: !isEditable ? 'none' : 'auto' }}
        >
          <BiPlus /> Add Group
        </button>
      </span>
    </OverlayTrigger>
  )}

  {/* Right: Go to Top/Bottom Toggle Button */}
 
  <ConfirmationPopup
                isOpen={showColumnConfirmation}
                onClose={handleCancelColumnDelete}
                onConfirm={handleConfirmColumnDelete}
                isLoading={false} // You can set this to true when deleting
            />
        <ConfirmationPopup
          isOpen={showGroupConfirmation}
          onClose={handleCancelGroupDelete}
          onConfirm={handleConfirmGroupDelete}
          isLoading={false} // You can set this to true when deleting
        />
        <ConfirmationPopup
          isOpen={showRowConfirmation}
          onClose={handleCancelRowDelete}
          onConfirm={handleConfirmRowDelete}
          isLoading={false} // You can set this to true when deleting
        />
        <ConfirmationPopup
          isOpen={showRowConfirmationSubrow}
          onClose={handleCancelSubRowDelete}
          onConfirm={handleConfirmRowDeleteSubrow}
          isLoading={false} // You can set this to true when deleting
        />
        
</div>
</div>

{/* 
      <div className="add-group-button mx-4 my-2">
      { selectedLayoutOption === 'kanban'|| selectedLayoutOption==='gantt'||selectedLayoutOption==='chart' ? (
        ''
      ):(
        <OverlayTrigger
      placement="top" // Tooltip will be shown on top
      overlay={!isEditable ? renderTooltip : <></>} // Show tooltip only when not editable
    >
      <span className="d-inline-block">
        <button
          type="button"
          className={!isEditable ? 'table-disabled btn btn-outline-primary btn-sm' : 'btn btn-outline-primary btn-sm'} 
          onClick={isEditable ? handleAddGroup : null} // Only call handleAddGroup if editable
          style={{ pointerEvents: !isEditable ? 'none' : 'auto' }} // Allow tooltip to show when disabled
        >
          <BiPlus /> Add Group
        </button>
      </span>
    </OverlayTrigger>
      )}
        <ConfirmationPopup
                isOpen={showColumnConfirmation}
                onClose={handleCancelColumnDelete}
                onConfirm={handleConfirmColumnDelete}
                isLoading={false} // You can set this to true when deleting
            />
        <ConfirmationPopup
          isOpen={showGroupConfirmation}
          onClose={handleCancelGroupDelete}
          onConfirm={handleConfirmGroupDelete}
          isLoading={false} // You can set this to true when deleting
        />
        <ConfirmationPopup
          isOpen={showRowConfirmation}
          onClose={handleCancelRowDelete}
          onConfirm={handleConfirmRowDelete}
          isLoading={false} // You can set this to true when deleting
        />
        <ConfirmationPopup
          isOpen={showRowConfirmationSubrow}
          onClose={handleCancelSubRowDelete}
          onConfirm={handleConfirmRowDeleteSubrow}
          isLoading={false} // You can set this to true when deleting
        />
        
      </div> */}
   
   {/* <button
  type="button"
  className="scroll-toggle-btn"
  onClick={handleToggleScroll}
>
  {isAtTop ? <BiChevronDown /> : <BiChevronUp />}
</button> */}
<button
  type="button"
  className="scroll-toggle-btn"
  onClick={handleToggleScroll}
>
  <span className="scroll-icon">
    {isAtTop ? <BiChevronDown /> : <BiChevronUp />}
  </span>
  <span className="scroll-text">
    {isAtTop ? 'Go to Bottom' : 'Go to Top'}
  </span>
</button>
      </div>
      <div
        className={`date-range-picker ${showDateRangePicker ? 'visible' : ''}`}
        ref={timelineRef}
        style={{
          top: `${dateRangePickerPosition.top}px`,
          left: `${dateRangePickerPosition.left-800}px`,
        }}
       
      >
        <DateRangePicker
          onChange={handleDateRangeChange}
          moveRangeOnFirstSelection={false}
          ranges={[dateRange]}
          months={2}
          direction="horizontal"
          className="date-range-picker"
          
        />
      </div>
      <div
        className={`date-range-picker ${showDateRangePickerSubrow ? 'visible' : ''}`}
        ref={timelineSubrowRef}
        style={{
          top: `${dateRangePickerPosition.top}px`,
          left: `${dateRangePickerPosition.left-800}px`,
        }}
       
      >
        <DateRangePicker
          onChange={handleDateRangeChangeSubrow}
          moveRangeOnFirstSelection={false}
          ranges={[dateRange]}
          months={2}
          direction="horizontal"
          className="date-range-picker"
          
        />
      </div>
    </DndProvider>
     )}
    </>
  );
};

export default SheetPage;
