import React, { useState, useRef, useEffect } from "react";
import { AiOutlineLink, AiOutlineEdit, AiOutlineClose } from "react-icons/ai"; // Importing React Icons

const LinkHandler = ({
  ID,
  cellId,
  apiUrl,
  fetchData,
  initialName,
  initialLink,
  cell_data,
}) => {
  const [linkName, setLinkName] = useState(cell_data?.name || initialName || "");
  const [linkUrl, setLinkUrl] = useState(cell_data?.link || initialLink || "");
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 }); // Store position

  // Close popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Save link and name
  const handleSave = async () => {
    try {
      const payload = {
        cell_data: {
          name: linkName || linkUrl, // If no name, use URL as the name
          link: linkUrl,
        },
      };

      await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/cell/${ID}/`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      fetchData(); // Refresh the data
      setShowPopup(false); // Close the popup
    } catch (error) {
      console.error("Error saving link:", error);
    }
  };

  // Delete the link
  const handleDelete = async () => {
    try {
      const payload = {
        cell_data: {
          name: "",
          link: "",
        },
      };

      await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/cell/${ID}/`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      fetchData(); // Refresh the data
      setLinkName("");
      setLinkUrl("");
    } catch (error) {
      console.error("Error deleting link:", error);
    }
  };
  const handleShowPopup = (event) => {
    const rect = event.target.getBoundingClientRect();
    const scrollX = window.scrollX || 0;
    const scrollY = window.scrollY || 0;

    setPopupPosition({
      top: rect.bottom + scrollY + 5, // Position 5px below the triggering element
      left: rect.left + scrollX, // Align with the left edge of the triggering element
    });
    setShowPopup(true);
  };
  return (
    <div style={{ position: "relative" }}>
      {/* Show the link icon if no link exists */}
      {!linkUrl && !linkName && (
        <div
          className="link-icon"
          onClick={(e) => handleShowPopup(e)}
          style={{ cursor: "pointer", display: "flex", alignItems: "center", minHeight: '50px', justifyContent: 'center' }}
        >
          <AiOutlineLink size={20} color="#007bff" /> {/* React Icon */}
        </div>
      )}

      {/* Show link preview if link exists */}
      {linkUrl && (
        <div style={{ display: "flex", alignItems: "center", justifyContent: 'center',minHeight: '50px' }}>
          {/* <a
            href={linkUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#007bff", textDecoration: "none" }}
          >
            {linkName || linkUrl}
          </a> */}
          <div
            style={{
              maxWidth: "200px", // Limit the width
              overflow: "hidden", // Hide overflow
              textOverflow: "ellipsis", // Show ellipsis if text is too long
              whiteSpace: "nowrap", // Prevent wrapping
              marginRight: "10px", // Space between text and icons
            }}
          >
            <a
              href={linkUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#007bff", textDecoration: "none" }}
            >
              {linkName || linkUrl}
            </a>
          </div>
          {/* Edit and Delete Icons */}
          <div className="open-button-containers">
            <AiOutlineEdit
              size={20}
              color="#007bff"
              style={{ marginLeft: "10px", cursor: "pointer" }}
              onClick={() => setShowPopup(true)}
            />
            <AiOutlineClose
              size={20}
              color="red"
              style={{ marginLeft: "10px", cursor: "pointer" }}
              onClick={handleDelete}
            />
          </div>
        </div>
      )}

      {/* Popup for adding/editing link */}
      {showPopup && (
        <div
          ref={popupRef}
          className="link-popup"
          style={{
           
              position: "fixed",
              top: `${popupPosition.top}px`,
              left: `${popupPosition.left-180}px`,
              zIndex: 1000,
              backgroundColor: "#fff",
              padding: "10px",
              boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
              width: "380px",
           
          }}
        >
          <h4 style={{ marginBottom: "10px", textAlign: "left" }}>Link</h4>

          <div style={{ marginBottom: "10px", textAlign: "left" }}>
            <label style={{ display: "block", marginBottom: "5px" }}>
              Write or paste a link
            </label>
            <input
              type="url"
              className="form-control"
              value={linkUrl}
              onChange={(e) => setLinkUrl(e.target.value)}
              placeholder="https://example.com"
              style={{ textAlign: "left" }}
            />
          </div>
          <div style={{ marginBottom: "10px", textAlign: "left" }}>
            <label style={{ display: "block", marginBottom: "5px" }}>
              Text to display
            </label>
            <input
              type="text"
              className="form-control"
              value={linkName}
              onChange={(e) => setLinkName(e.target.value)}
              placeholder="Enter a link name"
              style={{ textAlign: "left" }}
            />
          </div>
          <div style={{ marginTop: "10px", textAlign: "right" }}>
            <button className="btn btn-primary btn-sm" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LinkHandler;
