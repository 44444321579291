import React, { useState, useRef, useEffect } from "react";
import { AiOutlineMail, AiOutlineEdit, AiOutlineClose } from "react-icons/ai";

const EmailHandler = ({
  ID,
  cellId,
  apiUrl,
  fetchData,
  initialName,
  initialEmail,
  cell_data,
}) => {
  const [emailName, setEmailName] = useState(cell_data?.name || initialName || "");
  const [emailAddress, setEmailAddress] = useState(cell_data?.email || initialEmail || "");
  const [popupEmailName, setPopupEmailName] = useState(emailName);
  const [popupEmailAddress, setPopupEmailAddress] = useState(emailAddress);
  const [showPopup, setShowPopup] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const popupRef = useRef(null);

  // Close popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle Popup Position
  const handleShowPopup = (event) => {
    const rect = event.target.getBoundingClientRect();
    const scrollX = window.scrollX || 0;
    const scrollY = window.scrollY || 0;

    setPopupPosition({
      top: rect.bottom + scrollY + 5, // 5px below the element
      left: rect.left + scrollX,
    });

    // Populate popup inputs with current email and name
    setPopupEmailName(emailName);
    setPopupEmailAddress(emailAddress);
    setShowPopup(true);
  };

  // Save email and name
  const handleSave = async () => {
    // Validate email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!popupEmailAddress || !emailRegex.test(popupEmailAddress)) {
      alert("Please enter a valid email address.");
      return;
    }

    try {
      const payload = {
        cell_data: {
          name: popupEmailName || popupEmailAddress, // If no name, use email as the name
          email: popupEmailAddress,
        },
      };

      await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/cell/${ID}/`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      fetchData(); // Refresh the data
      setEmailName(popupEmailName); // Update the main state
      setEmailAddress(popupEmailAddress);
      setShowPopup(false); // Close the popup
    } catch (error) {
      console.error("Error saving email:", error);
    }
  };

  // Delete the email
  const handleDelete = async () => {
    try {
      const payload = {
        cell_data: {
          name: "",
          email: "",
        },
      };

      await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/cell/${ID}/`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      fetchData(); // Refresh the data
      setEmailName("");
      setEmailAddress("");
    } catch (error) {
      console.error("Error deleting email:", error);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {/* Show the email icon if no email exists */}
      {!emailAddress && !emailName && (
        <div
          className="email-icon"
          onClick={handleShowPopup}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            minHeight: "50px",
            justifyContent: "center",
          }}
        >
          <AiOutlineMail size={20} color="#007bff" />
        </div>
      )}

      {/* Show email preview if email exists */}
      {emailAddress && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "50px",
          }}
        >
          <a
            href={`mailto:${emailAddress}`}
            style={{
              color: "#007bff",
              textDecoration: "none",
              maxWidth: "150px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {emailName || emailAddress}
          </a>
          <div className="open-button-containers">
            <AiOutlineEdit
              size={20}
              color="#007bff"
              style={{ marginLeft: "10px", cursor: "pointer" }}
              onClick={handleShowPopup}
            />
            <AiOutlineClose
              size={20}
              color="red"
              style={{ marginLeft: "10px", cursor: "pointer" }}
              onClick={handleDelete}
            />
          </div>
        </div>
      )}

      {/* Popup for adding/editing email */}
      {showPopup && (
        <div
          ref={popupRef}
          className="email-popup"
          style={{
            position: "fixed",
            top: `${popupPosition.top}px`,
            left: `${popupPosition.left - 180}px`,
            zIndex: 1000,
            backgroundColor: "#fff",
            padding: "10px",
            boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
            width: "380px",
          }}
        >
          <h4 style={{ marginBottom: "10px", textAlign: "left" }}>Email</h4>

          <div style={{ marginBottom: "10px", textAlign: "left" }}>
            <label style={{ display: "block", marginBottom: "5px" }}>
              Write or paste an email address
            </label>
            <input
              type="email"
              className="form-control"
              value={popupEmailAddress}
              onChange={(e) => setPopupEmailAddress(e.target.value)}
              placeholder="example@example.com"
              style={{ textAlign: "left" }}
            />
          </div>
          <div style={{ marginBottom: "10px", textAlign: "left" }}>
            <label style={{ display: "block", marginBottom: "5px" }}>
              Name to display
            </label>
            <input
              type="text"
              className="form-control"
              value={popupEmailName}
              onChange={(e) => setPopupEmailName(e.target.value)}
              placeholder="Enter a display name"
              style={{ textAlign: "left" }}
            />
          </div>
          <div style={{ marginTop: "10px", textAlign: "right" }}>
            <button className="btn btn-primary btn-sm" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailHandler;
