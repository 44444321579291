import React from "react";
import DataTable from "react-data-table-component";
import { AiOutlineUser, AiOutlineEdit, AiOutlineDelete, AiOutlineLock, AiOutlineGlobal } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const EnhancedTable = ({ frames, user, handleManageAccess, handleEditModalShow, handleDeleteClick, formatDate }) => {
    const navigate = useNavigate(); // Initialize useNavigate
 const handleCardClick = (id) => {
    navigate(`/workspace-details/${id}`);
  };
  const handleRowClick = (rowId, e) => {
    // Check if the clicked target is part of the actions column (i.e., icon)
    if (e.target.closest(".action-icon")) {
      return; // Don't navigate if actions icon was clicked
    }
    handleCardClick(rowId); // Navigate if clicked anywhere else in the row
  };
  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "50px",
      center: true,
    },
    {
        name: "Workspace Name",
        selector: (row) => (
          <div className="image-container" style={{ display: "flex", alignItems: "center", gap: "8px",cursor: "pointer",  }}onClick={() => handleCardClick(row.id)}>
            <img
              src={`${process.env.REACT_APP_API_ROOT}/${row.icon}`}
              alt="Workspace Icon"
              className="main-frame-icon"
              style={{ width: "32px", height: "32px" }} // Adjust size as needed
            />
            <span>{row.workspace_name}</span>
          </div>
        ),
        sortable: true,
        wrap: true,
      },
      
      {
        name: "Privacy Type",
        selector: (row) => row.privacy_type,
        sortable: true,
        cell: (row) => (
          <div style={{ display: "flex", alignItems: "center", gap: "8px",cursor: "pointer",  }}onClick={() => handleCardClick(row.id)}>
            <span
              style={{
                backgroundColor: row.privacy_type === "public" ? "#28a745" : "#dc3545",
                color: "#fff",
                padding: "4px 8px",
                borderRadius: "12px",
                fontSize: "0.85rem",
                display: "inline-flex",
                alignItems: "center", // Ensures icon and text are vertically aligned
              }}
            >
              {/* Icon for Privacy Type */}
              {row.privacy_type === "public" ? (
                <AiOutlineGlobal
                  style={{
                    marginRight: "5px",
                    fontSize: "1rem",
                    color: "#fff",
                    verticalAlign: "middle", // Ensures the icon is vertically centered
                  }}
                />
              ) : (
                <AiOutlineLock
                  style={{
                    marginRight: "5px",
                    fontSize: "1rem",
                    color: "#fff",
                    verticalAlign: "middle", // Ensures the icon is vertically centered
                  }}
                />
              )}
              {row.privacy_type}
            </span>
          </div>
        ),
      },      
      
    {
      name: "Tags",
      selector: (row) =>
        row.tags?.length > 0
          ? row.tags.map((tag) => `#${tag.tag_name}`).join(", ")
          : "No Tags",
      wrap: true,
    },
    {
      name: "Created By",
      cell: (row) =>
        row.user_details?.studid === user?.studid ? (
          <div style={{cursor: "pointer", }}onClick={() => handleCardClick(row.id)}>
            <strong>{user?.name}</strong>
            <img
              src="/King.png"
              alt="King Badge"
              style={{ marginLeft: "8px", width: "20px", height: "20px" }}
            />
          </div>
        ) : (
          row.user_details?.name
        ),
    },
    {
      name: "Created At",
      selector: (row) => formatDate(row.created_at),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div style={{ display: "flex", gap: "" }}>
          <AiOutlineUser
            className="action-icon"
            style={{ cursor: "pointer", color: "#6c757d", fontSize: "1.3rem" }} // Same color and size
            title="Manage Access"
            onClick={() => handleManageAccess(row.id)}
          />
          <AiOutlineEdit
            className="action-icon"
            style={{ cursor: "pointer", color: "#6c757d", fontSize: "1.3rem" }} // Same color and size
            title="Edit"
            onClick={() => handleEditModalShow(row.id)}
          />
          <AiOutlineDelete
            className="action-icon"
            style={{ cursor: "pointer", color: "#6c757d", fontSize: "1.3rem" }} // Same color and size
            title="Delete"
            onClick={() => handleDeleteClick(row.id)}
          />
        </div>
      ),
      
    },
  ];

  const customStyles = {
    // header: {
    //   style: {
    //     backgroundColor: "#007bff",
    //     color: "#fff",
    //     fontWeight: "bold",
    //     fontSize: "1.2rem",
    //   },
    // },
    rows: {
      style: {
        minHeight: "60px",
        "&:hover": {
          backgroundColor: "#f1f1f1",
        },
      },
    },
    headCells: {
      style: {
        backgroundColor: "#e9ecef",
        fontWeight: "bold",
        fontSize: "1.1rem",
        color: "#495057",
      },
    },
    cells: {
      style: {
        fontSize: "1rem",
      },
    },
  };

  return (
    <div style={{ padding: "20px", background: "#f8f9fa", borderRadius: "8px" }}>
      <DataTable
        // title="Workspace Management"
        columns={columns}
        data={frames}
        customStyles={customStyles}
        highlightOnHover
        pagination
        responsive
        striped
        onRowClicked={(row, e) => handleRowClick(row.id, e)} // Attach onRowClick to navigate on row click

      />
    </div>
  );
};

export default EnhancedTable;
