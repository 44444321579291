import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Picker from 'emoji-picker-react';
import DataTable from 'react-data-table-component';
import { FaEdit, FaTrash } from 'react-icons/fa'; // For Edit and Delete icons
import ConfirmationPopup from './ConfirmationPopup';

const ManageTags = () => {
  const [tags, setTags] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false); // For confirmation modal
  const [selectedTag, setSelectedTag] = useState({ id: null, name: '' });
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // Search term state
  const deptId = localStorage.getItem('HDept');

  const fetchTags = async () => {
    try {
      // Retrieve the department ID from localStorage
      const deptId = localStorage.getItem('HDept');
      
      // If deptId is not found, log an error or return early
      if (!deptId) {
        console.error('Department ID not found in localStorage');
        return;
      }
  
      // Make the API request using the deptId
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/tags/${deptId}/`);
      const data = await response.json();
      
      // Get tags from the response data
      const tagsData = data?.tags;
      
      // Set the tags state
      setTags(tagsData || []); // Set empty array if no data
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };
  
  useEffect(() => {
    fetchTags(); // Fetch tags on component mount
  }, []);
  
  const handleSave = async () => {
    const method = selectedTag.id ? 'PUT' : 'POST';
    const url = selectedTag.id
      ? `${process.env.REACT_APP_API_ROOT}/hotwash/tag/${selectedTag.id}/`
      : `${process.env.REACT_APP_API_ROOT}/hotwash/add-tag/`;

    try {
      await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ tag_name: selectedTag.name, department_id: deptId }),
      });
      fetchTags();
      setShowModal(false);
    } catch (error) {
      console.error('Error saving tag:', error);
    }
  };

  // Handle deletion request and show confirmation modal
  const handleDeleteRequest = (id) => {
    setSelectedTag({ id });
    setShowConfirmation(true); // Show confirmation modal
  };

  // Confirm deletion
  const handleConfirmDelete = async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/tag-delete/${selectedTag.id}/`, {
        method: 'GET',
      });
      fetchTags();
      setShowConfirmation(false); // Close confirmation modal after deletion
    } catch (error) {
      console.error('Error deleting tag:', error);
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false); // Close confirmation modal on cancel
  };

  const handleEmojiClick = (emoji) => {
    setSelectedTag({ ...selectedTag, name: selectedTag.name });
    setShowEmojiPicker(false);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Update the search term
  };

  // Filter tags based on the search term
  const filteredTags = tags.filter((tag) =>
    tag.tag_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Define columns for DataTable
  const columns = [
    {
      name: 'S. No',
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: 'Tag Name',
      selector: (row) => row.tag_name,
      sortable: true,
    },
    {
        name: 'Created BY',
        selector: (row) => "Veni",
        sortable: true,
      },
      {
        name: 'Created At',
        selector: (row) => "11-12-2024",
        sortable: true,
      },
    {
      name: 'Action',
      cell: (row) => (
        <div className="d-flex justify-content-around mx-2">
          <FaEdit
            className="cursor-pointer mx-2"
            style={{ fontSize: '20px' }} // Increase size here
            onClick={() => {
              setSelectedTag({ id: row.id, name: row.tag_name });
              setShowModal(true);
            }}
          />
          <FaTrash
            className="text-danger cursor-pointer"
            style={{ fontSize: '20px' }} // Increase size here
            onClick={() => handleDeleteRequest(row.id)} // Show confirmation before delete
          />
        </div>
      ),
    },
  ];

  return (
    <div className="workspace-details-page">
      <div className="p-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h4>Manage Tags</h4>
          <Button onClick={() => setShowModal(true)}>Create Tag</Button>
        </div>

        {/* Search Input */}
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search tags..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>

        {/* DataTable */}
        <DataTable
          columns={columns}
          data={filteredTags}
          pagination
          highlightOnHover
          pointerOnHover
        />

        {/* Modal for Creating/Editing Tags */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedTag.id ? 'Edit Tag' : 'Create Tag'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSave();
              }}
            >
              <div className="mb-3">
                <label>Tag Name</label>
                <div className="d-flex align-items-center">
                  <input
                    type="text"
                    value={selectedTag.name}
                    onChange={(e) =>
                      setSelectedTag({ ...selectedTag, name: e.target.value })
                    }
                    className="form-control"
                  />
                  <Button
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                    variant="light"
                    className="ms-2"
                  >
                    😊
                  </Button>
                </div>
                {showEmojiPicker && <Picker onEmojiClick={handleEmojiClick} />}
              </div>
              <Button type="submit" variant="primary" className="w-100">
                Save
              </Button>
            </form>
          </Modal.Body>
        </Modal>

        {/* Confirmation Popup for Delete */}
        <ConfirmationPopup
          isOpen={showConfirmation}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          isLoading={false} // You can set this to true when deleting
        />
      </div>
    </div>
  );
};



export default ManageTags;
