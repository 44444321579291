import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { BiDotsHorizontal, BiTrash } from 'react-icons/bi';
import { FaEdit } from 'react-icons/fa';
// import { deleteColumn } from '../../features/workspaces/workspaceActions';
// import ConfirmationPopup from './ConfirmationPopup';
// import { useDispatch } from 'react-redux';
// import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const DraggableColumn = ({ header, columnWidth, setColumnWidths, fetchData,setShowColumnConfirmation,setDeletingColumn ,canDelete}) => {
    const ref = useRef(null);
    const columnMenuRef = useRef(null);
    const [isEditing, setIsEditing] = useState(false);
    const [newColumnName, setNewColumnName] = useState(header?.name);
    const [openMenuColumnId, setOpenMenuColumnId] = useState(null);
    const [isColumnMenuVisible, setIsColumnMenuVisible] = useState(false);
    const [GroupID, setGroupId] = useState(false);
    // const dispatch = useDispatch();
   
    const handleColumnResize = async (id, newWidth) => {
        setColumnWidths(prevWidths => ({
            ...prevWidths,
            [id]: newWidth,
        }));
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (columnMenuRef.current && !columnMenuRef.current.contains(event.target)) {
                setIsColumnMenuVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isColumnMenuVisible]);

    useEffect(() => {
        if (isEditing) {
            const input = document.querySelector('.header-cell input');
            if (input) {
                input.focus();
            }
        }
    }, [isEditing]);

    const handleResizeMouseDown = (e) => {
        const startX = e.clientX;
        const startWidth = ref.current.offsetWidth;

        const doDrag = (e) => {
            const newWidth = startWidth + (e.clientX - startX);
            handleColumnResize(header.id, newWidth); // Update local state for resizing
        };

        const stopDrag = async () => {
            document.removeEventListener('mousemove', doDrag);
            document.removeEventListener('mouseup', stopDrag);
            const newWidth = ref.current.offsetWidth; // Capture the final width after resizing
            try {
                await axios.post(`${process.env.REACT_APP_API_ROOT}/hotwash/update-column/`, {
                    id: header.id,
                    column_width: newWidth,
                });
                fetchData();
            } catch (error) {
                console.error('Error updating column width:', error);
            }
        };

        document.addEventListener('mousemove', doDrag);
        document.addEventListener('mouseup', stopDrag);
    };

    const handleRenameColumn = async (e) => {
        if (e.key === 'Enter' || e.type === 'blur') {
            try {
                await axios.post(`${process.env.REACT_APP_API_ROOT}/hotwash/update-column/`, {
                    id: header.id,
                    name: newColumnName,
                });
                 fetchData();
                setIsEditing(false);
                setIsColumnMenuVisible(false);
                fetchData(); // Ensure fetchData is defined and works correctly
            } catch (error) {
                console.error('Error renaming column:', error);
            }
        }
    };

    const handleAddColumnMenuClick = (event, header) => {
        event.stopPropagation();
        setOpenMenuColumnId(header?.id);
        setGroupId(header?.group_id);
        setIsColumnMenuVisible(!isColumnMenuVisible);
    };

    const handleHeaderClick = () => {
        if (!isEditing) {
            setIsEditing(true);
            setIsColumnMenuVisible(false);
        }
    };

   

    const handleEdit = () => {
        setIsEditing(true);
        console.log("kjhgfdfghjklkjhg")
    };

    const handleDeleteColumn = (id) => {
        setDeletingColumn(id); // Store the column ID to delete
        console.log("kjhgfdfghjklkjhg111")

        setShowColumnConfirmation(true); // Show confirmation dialog
    };

    const renderColumnName = () => {
        if (isEditing) {
            return (
                <input
                    type="text"
                    value={newColumnName}
                    onChange={(e) => setNewColumnName(e.target.value)}
                    onKeyPress={handleRenameColumn}
                    onBlur={handleRenameColumn}
                     maxLength="100"
                    autoFocus
                />
            );
        }
        const truncatedName = header?.name?.length > 20 ? `${header?.name?.substring(0, 20)}...` : header?.name;

        return (
            <>
                <span
                style={{
                    maxWidth: '150px',        // Optional: limit the max width for ellipsis
                    whiteSpace: 'nowrap',     // Prevent text from wrapping
                    overflow: 'hidden',       // Hide overflow
                    textOverflow: 'ellipsis'  // Add ellipsis for overflowing text
                }}
                title={header?.name}  // Show full name on hover
            >
                {truncatedName}
            </span>
                <BiDotsHorizontal
                    className="three-dots"
                    style={{ cursor: 'pointer', marginLeft: '10px' }}
                    onClick={(event) => handleAddColumnMenuClick(event, header)}
                />
            </>
        );
    };

    return (
        <>
            <th
                ref={ref}
                style={{
                    width: `${columnWidth}px`,
                    position: 'relative',
                }}
                onClick={handleHeaderClick}
                className={`header-cell ${header.isFixed ? 'fixed' : ''}`}
            >
                {renderColumnName()}
                <div
                    className="resizer"
                    onMouseDown={handleResizeMouseDown}
                />
                {openMenuColumnId === header?.id && GroupID === header?.group_id && isColumnMenuVisible && (
                    <div ref={columnMenuRef} className="add-column-groups frame-name">
                        <p className="menu-item" onClick={handleEdit}>
                            <span><FaEdit className="me-2" /> Rename</span>
                        </p>
                        {!(canDelete) ? (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-convert`}>You need admin access and Delete permissions</Tooltip>}
      >
        <span className="">
          <p className="menu-item" style={{ color: 'gray', cursor: 'not-allowed' }}>
          <BiTrash className="me-2" /> Delete     </p>
        </span>
      </OverlayTrigger>
    ) : (
        <p className="menu-item" onClick={() => handleDeleteColumn(header.id)}>
        <BiTrash className="me-2" /> Delete
    </p>
          
    )}  
                      
                    </div>
                )}
            </th>
            
        </>
    );
};

export default DraggableColumn;
