import React, { useState, useEffect, useRef } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { AiOutlinePhone, AiOutlineCloseCircle } from "react-icons/ai";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const PhoneNumberHandler = ({ ID, fetchData, initialPhone, cell_data }) => {
  const [phone, setPhone] = useState(cell_data?.phone || initialPhone || "");
  const [editing, setEditing] = useState(false);
  
  const [countryFlag, setCountryFlag] = useState("");  // Flag will be set based on phone input
  const wrapperRef = useRef(null);

  // Extract country flag from phone number
  const getCountryFlagFromPhone = (phone) => {
    if (!phone) return "";
    const phoneNumber = parsePhoneNumberFromString(phone);
    if (phoneNumber && phoneNumber.country) {
      return phoneNumber.country.toLowerCase();  // Extract country code (e.g., 'IN', 'US')
    }
    return "";  // Return empty if no valid country found
  };

  // Automatically set country flag when phone changes
  useEffect(() => {
    const flag = getCountryFlagFromPhone(phone);
    setCountryFlag(flag);
  }, [phone]);

  // Save the phone number when exiting edit mode
  const handleSave = async () => {
    try {
      const payload = { cell_data: { phone } };
      await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/cell/${ID}/`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      fetchData(); // Refresh data
    } catch (error) {
      console.error("Error saving phone number:", error);
    } finally {
      setEditing(false);
    }
  };

  // Close editing on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (editing) handleSave();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editing, phone]);

  return (
    <div
      ref={wrapperRef}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "50px",
        position: "relative",
      }}
    >
      {/* Show phone icon if not editing and phone is empty */}
      {!editing && !phone && (
        <div
          onClick={() => setEditing(true)}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AiOutlinePhone size={20} color="#007bff" />
        </div>
      )}

      {/* Show phone display with flag if not editing */}
      {!editing && phone && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {countryFlag && (
            <img
              src={`https://flagcdn.com/w320/${countryFlag}.png`}
              alt={`${countryFlag} flag`}
              style={{
                width: "20px",
                height: "15px",
                marginRight: "10px",
              }}
            />
          )}
          <span
            style={{
              fontSize: "16px",
              maxWidth: "150px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {phone}
          </span>
          <div className="open-button-containers">
          <AiOutlineCloseCircle
            size={20}
            color="red"
            style={{
              cursor: "pointer",
              marginLeft: "10px",
            }}
            onClick={() => setPhone("")}
          />
                  </div>

        </div>
      )}

      {/* Show PhoneInput when editing */}
      {editing && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <PhoneInput
            value={phone}
            onChange={setPhone}
            placeholder="Enter phone number"
            international={true}  // Enable international number detection
            defaultCountry="IN"  // Set default country to empty string for auto-detection
            disableCountryCode={false} // Enable the country code dropdown
            onFocus={() => {
              // No flag needed during input, only when showing non-edit mode
            }}
          
          />
        </div>
      )}
    </div>
  );
};

export default PhoneNumberHandler;
